<template>
  <div
    ref="wrapper"
    class="tw-relative tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full tw-overflow-hidden"
    :class="[
      mode,
      {
        'custom-cursor': !!imageUrl && activeLabelMark.name,
        'custom-purple-cursor': Object.keys(reLabeledManualMark).length || hadActiveManualMeasurement,
      },
    ]"
    @mousedown="onMousedown"
    @contextmenu.prevent
    @mousemove="onWrapperMousemove"
    @mouseup="onMouseup"
    @mouseleave="onMouseup"
  >
    <div v-show="imageUrl" class="align-center" :style="[imageStyle, ...imageRotation]">
      <xo-img ref="merchandiseImage" :src="imageUrl" @load="imgLoaded = true" />
    </div>
    <div v-if="showUnitCoordinates" class="grid-mask" :style="imageZoomRatio" alt="grid" />
    <div class="align-center" :style="[imageStyle, { width: `${maskSize.width}px`, height: `${maskSize.height}px` }]">
      <div
        v-for="item in labeledMarks"
        :key="item.id"
        class="mark"
        :class="{ 'tw-filter tw-grayscale': hadActiveManualMeasurement }"
        :style="{
          top: `${item.position.y}px`,
          left: `${item.position.x}px`,
          ...restoreScaling,
        }"
      >
        <div>{{ item.name }}</div>
      </div>
      <k-stage ref="preview" :config="{ width: maskSize.width, height: maskSize.height }">
        <k-layer>
          <k-line v-for="(item, index) in manualLines" :key="`line-${index}`" :config="item" />
        </k-layer>
      </k-stage>
      <template v-for="(landmarks, index) in manualLandmarks">
        <div
          v-for="(item, idx) in landmarks"
          :key="`${index}-mark-${idx}`"
          class="manual-mark"
          :class="{
            'tw-filter tw-grayscale':
              (hadActiveManualMeasurement && item.id !== activeManualMeasurement.id) ||
              (!hadActiveManualMeasurement && !measurementLandmarks.every(item => !item.labeled) && isEditing),
          }"
          :style="{
            top: `${item.y}px`,
            left: `${item.x}px`,
            ...restoreScaling,
          }"
        />
      </template>
      <template v-if="auxiliaryLinesData">
        <div
          ref="auxLine"
          class="tw-absolute tw-w-full"
          :key="`auxLine${activeLabelMark.name}`"
          :style="[{ top: `${auxiliaryLinesData.y}px` }, auxiliaryLinesData.rotationStyle]"
        >
          <div class="distance-line" :style="[auxiliaryLinesData.distanceLineStyle]" />
          <div class="dash-line" />
        </div>
        <div
          v-if="auxLineBase"
          ref="baseLine"
          class="tw-absolute tw-w-full base-line"
          :style="[{ top: `${auxLineBase.y}px`, left: `${auxLineBase.x}px` }]"
        >
          <div class="dash-line"></div>
        </div>
        <div v-if="customAuxLine" ref="customAuxLine" class="tw-absolute tw-w-full" :style="customAuxLine.style">
          <div class="dash-line"></div>
        </div>
      </template>
    </div>
    <div v-show="imageUrl" class="lens">
      <div class="tw-w-full tw-h-full tw-transform tw-scale-150">
        <div
          ref="imageZoomer"
          class="tw-absolute tw-overflow-hidden"
          :style="{
            width: `${wrapperSize.width}px`,
            height: `${wrapperSize.height}px`,
          }"
        >
          <xo-img :src="imageUrl" class="align-center" :style="[imageStyle, ...imageRotation]" />
          <div v-if="showUnitCoordinates" class="grid-mask" :style="imageZoomRatio" alt="grid" />
        </div>
      </div>
      <img
        v-if="!hadActiveManualMeasurement"
        src="../../../assets/home/measurement/ic-aim-point.svg"
        class="align-center"
      />
      <img v-else src="../../../assets/home/measurement/ic-aim-point-purple.svg" class="align-center" />
    </div>
    <div
      v-show="!!imageUrl"
      ref="mouseCursor"
      class="cursor"
      :class="{
        hover: isMarkHover,
        'purple-cursor': hadActiveManualMeasurement,
      }"
      @mousemove="moveLens"
    >
      <div class="cursor-icon">{{ !isMarkHover ? activeLabelMark.name || '' : '' }}</div>
    </div>
    <div
      class="align-center"
      :style="[imageStyle, { width: `${maskSize.width}px`, height: `${maskSize.height}px` }]"
      @click="labelMark"
      @mousedown="relabel"
      @mousemove="throttleHoverLandmark"
      @mouseup="offRelabel"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import throttle from 'lodash/throttle';
import { getBoxCenter, getDistance, getNearestPointToALine, getRotationDegree } from '@/modules/utils';
import XoImg from '../../common/XoImg';

export default {
  name: 'MeasurementViewer',
  props: {
    imageUrl: String,
    mode: String,
    rotation: Number,
    showUnitCoordinates: Boolean,
    ready: Boolean,
    isEditing: Boolean,
  },
  components: {
    XoImg,
  },
  data: () => ({
    imageZoomerUrl: '',
    wrapperSize: { width: 0, height: 0 },
    maskSize: { width: 0, height: 0 },
    zoomRatio: 0.7,
    mouseDown: false,
    startX: 0,
    startY: 0,
    position: { x: 0, y: 0 },
    mouseRight: false,
    currentMark: {},
    reLabeledMark: {},
    isReLabel: false,
    exportImageUrl: '',
    imgLoaded: false,
    isMarkHover: false,
    reLabeledManualMark: {},
  }),
  computed: {
    ...mapGetters('labelingTool/measurementTool', [
      'measurementLandmarks',
      'currentEditSize',
      'auxiliaryLines',
      'activeManualMeasurement',
      'hadActiveManualMeasurement',
      'customizedMeasurements',
    ]),
    isLabelMode() {
      return this.mode === 'select';
    },
    imageZoomRatio() {
      return {
        '--tw-scale-x': this.zoomRatio,
        '--tw-scale-y': this.zoomRatio,
      };
    },
    imagePosition() {
      return {
        top: `${this.position.y}px`,
        left: `${this.position.x}px`,
      };
    },
    imageRotation() {
      return {
        '--tw-rotate': `${this.rotation}deg`,
      };
    },
    restoreScaling() {
      const restoreScaling = 1 / this.zoomRatio;
      return {
        width: `${24 * restoreScaling}px`,
        height: `${30 * restoreScaling}px`,
        'font-size': `${12 * restoreScaling}px`,
      };
    },
    labeledMarks() {
      if (!this.currentEditSize) {
        return [];
      }
      return this.measurementLandmarks.filter(item => item.labeled);
    },
    activeLabelMark() {
      if (this.isReLabel) {
        return this.reLabeledMark;
      }
      if (Object.keys(this.reLabeledManualMark).length > 0) {
        return {};
      }
      if (this.isMarkHover) {
        return this.reLabeledMark;
      }
      if (this.hadActiveManualMeasurement) {
        return {};
      }
      return this.currentMark;
    },
    imageStyle() {
      return {
        ...this.imageZoomRatio,
        ...this.imagePosition,
      };
    },
    markingCompleted() {
      if (this.hadActiveManualMeasurement) {
        return false;
      }
      return this.measurementLandmarks.every(item => item.labeled);
    },
    viewerReady() {
      return this.ready && this.measurementLandmarks.length > 0 && this.imgLoaded;
    },
    auxiliaryLinesData() {
      const data = this.auxiliaryLines.find(line => line.auxiliaryPoints.includes(this.activeLabelMark.id));
      if (!this.imageUrl || !data) return null;

      const { from, to, base: baseIndex } = data.referencePoint;
      const fromLandmark = this.measurementLandmarks[from];
      const toLandmark = this.measurementLandmarks[to];
      let base = null;
      if (!fromLandmark.labeled || !toLandmark.labeled) return null;

      let value;
      let x;
      let y;
      const distanceLineStyle = {};
      const rotationStyle = {};
      let custom = null;
      if (from !== to) {
        const { x: x1, y: y1 } = fromLandmark.position;
        const { y: y2 } = toLandmark.position;
        value = (y2 - y1) / 2;
        x = x1;
        y = y1;
        distanceLineStyle.height = `${value}px`;
        distanceLineStyle['margin-left'] = `${x - 1}px`;
      } else {
        ({ x, y } = fromLandmark.position);
        ({ value } = data);
        if (value === 'width_of_hip') {
          // special cases for trousers and shorts
          const l2 = this.measurementLandmarks[1].position;
          const l4 = this.measurementLandmarks[3].position;
          const l5 = this.measurementLandmarks[4].position;
          const l2ToL5 = getDistance(l2.x, l2.y, l5.x, l5.y);
          value = (l2ToL5 + getDistance(l5.x, l5.y, l4.x, l4.y)) / 2;
          let pX = l5.x;
          let pY = l5.y;
          if (value < l2ToL5) {
            const m = value / l2ToL5;
            const n = (l2ToL5 - value) / l2ToL5;
            pX = Math.round(n * l2.x + (m * l5.x) / (m + n));
            pY = Math.round(n * l2.y + (m * l5.y) / (m + n));
          }
          distanceLineStyle.visibility = 'hidden';
          distanceLineStyle.height = '0px';
          rotationStyle.transform = `translate(${pX - l2.x}px, ${pY - l2.y}px)`;
          custom = {
            style: {
              top: `${pY}px`,
              transformOrigin: `${pX}px 0px`,
              transform: `rotate(${getRotationDegree(pX, pY, l2.x, l2.y)}deg)`,
            },
          };
        } else {
          distanceLineStyle.height = `${value}px`;
          distanceLineStyle['margin-left'] = `${x - 1}px`;
        }
      }
      if (Array.isArray(data.parallel)) {
        const [p1, p2] = data.parallel;
        const { x: parallelX1, y: parallelY1 } = this.measurementLandmarks[p1].position || {};
        const { x: parallelX2, y: parallelY2 } = this.measurementLandmarks[p2].position || {};
        let deg = getRotationDegree(parallelX1, parallelY1, parallelX2, parallelY2);
        if (deg > 90) {
          deg -= 180;
        } else if (deg < -90) {
          deg += 180;
        }
        rotationStyle.transformOrigin = `${x}px 0px`;
        rotationStyle.transform = `${rotationStyle.transform ? `${rotationStyle.transform} ` : ''}rotate(${deg}deg)`;
      }
      if (data.rotate) {
        rotationStyle.left = `calc(${x}px - 50%)`;
      }
      if (typeof baseIndex === 'number') {
        base = this.measurementLandmarks[baseIndex].position || {};
      }
      return { ...data, x, y, value, rotationStyle, distanceLineStyle, base, custom };
    },
    auxLineBase() {
      return (this.auxiliaryLinesData && this.auxiliaryLinesData.base) || null;
    },
    customAuxLine() {
      return (this.auxiliaryLinesData && this.auxiliaryLinesData.custom) || null;
    },
    shouldAuxLineRotate() {
      return this.auxiliaryLinesData && this.auxiliaryLinesData.rotate;
    },
    manualLandmarks() {
      if (this.customizedMeasurements.length === 0) return [];
      return this.customizedMeasurements.map(item => {
        const data = item.sizes.find(size => size.name === this.currentEditSize);
        return data ? data.landmarks.map(landmark => ({ ...landmark, id: item.id })) : '';
      });
    },
    manualLines() {
      const manualLines = [];
      this.manualLandmarks.forEach(item => {
        const config = {
          stroke:
            (this.hadActiveManualMeasurement && !item.every(data => data.id === this.activeManualMeasurement.id)) ||
            (!this.hadActiveManualMeasurement &&
              !this.measurementLandmarks.every(landmark => !landmark.labeled) &&
              this.isEditing)
              ? '#c4c4c4'
              : '#ff78e1',
          strokeWidth: 2,
        };
        const points = [];
        if (!item || item.some(data => data.x <= 0 && data.y <= 0)) {
          manualLines.push(config);
        } else {
          item.forEach(data => {
            points.push(data.x);
            points.push(data.y);
          });
          manualLines.push({ points, ...config });
        }
      });
      return manualLines;
    },
  },
  watch: {
    measurementLandmarks: {
      deep: true,
      handler(nv) {
        const landmarks = cloneDeep(nv);
        this.currentMark = landmarks.find(item => !item.labeled) || {};
      },
    },
    currentEditSize(nv) {
      if (nv) {
        this.imgLoaded = false;
      }
    },
    ready(nv) {
      if (!nv) {
        this.currentMark = {};
      }
    },
    viewerReady(nv) {
      if (nv) {
        this.$nextTick(() => {
          this.initializeImageArea();
        });
      }
    },
    rotation() {
      this.getMerchandiseImageSize();
    },
  },
  mounted() {
    this.throttleHoverLandmark = throttle(e => {
      this.setHoverLandmark(e);
    }, 250);
    window.addEventListener('resize', () => this.initializeImageArea);
  },
  beforeDestroy() {
    window.addEventListener('resize', () => this.initializeImageArea);
  },
  methods: {
    ...mapMutations('labelingTool/measurementTool', [
      'SET_MEASUREMENTS_LANDMARKS',
      'SET_CUSTOMIZED_MEASUREMENTS',
      'SET_ACTIVE_MANUAL_MEASUREMENTS',
    ]),
    initializeImageArea() {
      this.$nextTick(async () => {
        this.cameraReset();
        const landmarks = cloneDeep(this.measurementLandmarks);
        this.currentMark = landmarks.find(item => !item.labeled) || {};
        await this.getMerchandiseImageSize();
        await this.getWrapperSize();
        this.position.x = this.wrapperSize.width / 2;
        this.position.y = this.wrapperSize.height / 2;
      });
    },
    labelMark(e) {
      if (!this.imageUrl || !this.isLabelMode || this.isReLabel || this.mouseRight || this.markingCompleted) return;

      this.$emit('update:isEditing', true);
      const position = { x: e.offsetX, y: e.offsetY };
      if (this.hadActiveManualMeasurement) {
        const measurements = cloneDeep(this.customizedMeasurements);
        const measurementsIndex = measurements.findIndex(item => item.id === this.activeManualMeasurement.id);
        const activeMeasurements = measurements[measurementsIndex];
        const index = activeMeasurements.sizes.findIndex(size => size.name === this.currentEditSize);
        activeMeasurements.sizes[index].landmarks.push(position);
        measurements.splice(measurementsIndex, 1, activeMeasurements);
        this.SET_CUSTOMIZED_MEASUREMENTS(measurements);
        return;
      }
      const landmarks = cloneDeep(this.measurementLandmarks);
      this.currentMark.labeled = true;
      this.currentMark.position = position;
      const currentIndex = landmarks.findIndex(item => item.id === this.currentMark.id);
      landmarks[currentIndex] = this.currentMark;
      this.SET_MEASUREMENTS_LANDMARKS(landmarks);
    },
    relabel() {
      if (
        (Object.keys(this.reLabeledMark).length < 1 && Object.keys(this.reLabeledManualMark).length < 1) ||
        !this.isLabelMode ||
        this.mouseRight
      )
        return;
      this.$emit('update:isEditing', true);
      if (Object.keys(this.reLabeledManualMark).length) {
        const cloneCustomizedMeasurements = cloneDeep(this.customizedMeasurements);
        const index = cloneCustomizedMeasurements[this.reLabeledManualMark.manualMeasurementIndex].sizes.findIndex(
          size => size.name === this.currentEditSize
        );
        if (!this.hadActiveManualMeasurement) {
          const cloneData = cloneDeep(cloneCustomizedMeasurements[this.reLabeledManualMark.manualMeasurementIndex]);
          this.SET_ACTIVE_MANUAL_MEASUREMENTS(cloneData);
        }
        this.$set(
          cloneCustomizedMeasurements[this.reLabeledManualMark.manualMeasurementIndex].sizes[index].landmarks[
            this.reLabeledManualMark.landmarkIndex
          ],
          'x',
          -40
        );
        this.$set(
          cloneCustomizedMeasurements[this.reLabeledManualMark.manualMeasurementIndex].sizes[index].landmarks[
            this.reLabeledManualMark.landmarkIndex
          ],
          'y',
          0
        );
        this.SET_CUSTOMIZED_MEASUREMENTS(cloneCustomizedMeasurements);
      } else {
        const landmarks = cloneDeep(this.measurementLandmarks);
        const landmark = cloneDeep(this.reLabeledMark);
        landmark.labeled = false;
        const currentIndex = landmarks.findIndex(item => item.id === landmark.id);
        landmarks[currentIndex] = landmark;
        this.SET_MEASUREMENTS_LANDMARKS(landmarks);
      }
      this.$refs.mouseCursor.classList.remove('hover');
      this.isReLabel = true;
    },
    offRelabel(e) {
      if (
        (Object.keys(this.reLabeledMark).length < 1 && Object.keys(this.reLabeledManualMark).length < 1) ||
        !this.isReLabel
      )
        return;
      this.$emit('update:isEditing', true);
      if (Object.keys(this.reLabeledManualMark).length) {
        const cloneCustomizedMeasurements = cloneDeep(this.customizedMeasurements);
        const index = cloneCustomizedMeasurements[this.reLabeledManualMark.manualMeasurementIndex].sizes.findIndex(
          size => size.name === this.currentEditSize
        );
        this.$set(
          cloneCustomizedMeasurements[this.reLabeledManualMark.manualMeasurementIndex].sizes[index].landmarks[
            this.reLabeledManualMark.landmarkIndex
          ],
          'x',
          e.offsetX
        );
        this.$set(
          cloneCustomizedMeasurements[this.reLabeledManualMark.manualMeasurementIndex].sizes[index].landmarks[
            this.reLabeledManualMark.landmarkIndex
          ],
          'y',
          e.offsetY
        );
        this.SET_CUSTOMIZED_MEASUREMENTS(cloneCustomizedMeasurements);
      } else {
        const landmarks = cloneDeep(this.measurementLandmarks);
        const landmark = cloneDeep(this.reLabeledMark);
        landmark.labeled = true;
        landmark.position.x = e.offsetX;
        landmark.position.y = e.offsetY;
        const currentIndex = landmarks.findIndex(item => item.id === landmark.id);
        landmarks[currentIndex] = landmark;
        this.SET_MEASUREMENTS_LANDMARKS(landmarks);
        this.reLabeledMark = {};
      }
      setTimeout(() => {
        this.isReLabel = false;
      }, 100);
    },
    onWrapperMousemove(e) {
      this.moveLens(e);
      if (this.shouldAuxLineRotate) {
        this.rotateAuxLine(e);
      }
      if (!this.isLabelMode || this.mouseRight) {
        this.onMousemove(e);
      }
    },
    onMousedown(e) {
      if (this.isReLabel) return;
      if (e.button === 2) {
        this.mouseRight = true;
      }
      this.mouseDown = true;
      this.startX = e.pageX;
      this.startY = e.pageY;
    },
    onMousemove(e) {
      if (!this.mouseDown || this.isReLabel) return;

      const x = e.pageX;
      const y = e.pageY;
      const distanceX = Number((x - this.startX).toFixed());
      const distanceY = Number((y - this.startY).toFixed());
      this.position.x += distanceX;
      this.position.y += distanceY;
      this.startX = x;
      this.startY = y;
    },
    onMouseup() {
      if (this.isReLabel) return;
      this.mouseDown = false;
      this.mouseRight = false;
    },
    cameraZoomIn() {
      const num = 0.1;
      if (this.zoomRatio > 3) return;
      this.zoomRatio = Math.round((this.zoomRatio + num) * 10) / 10;
    },
    cameraZoomOut() {
      const num = -0.1;
      if (this.zoomRatio < 0.2) return;
      this.zoomRatio = Math.round((this.zoomRatio + num) * 10) / 10;
    },
    cameraReset() {
      this.zoomRatio = 0.7;
      this.startX = 0;
      this.startY = 0;
      this.position.x = this.wrapperSize.width / 2;
      this.position.y = this.wrapperSize.height / 2;
    },
    getWrapperSize() {
      return new Promise(resolve => {
        const { offsetWidth, offsetHeight } = this.$refs.wrapper;
        if (!offsetWidth || !offsetHeight) {
          setTimeout(() => {
            this.getWrapperSize();
          }, 500);
          return;
        }
        this.wrapperSize.width = offsetWidth;
        this.wrapperSize.height = offsetHeight;
        resolve();
      });
    },
    getMerchandiseImageSize() {
      return new Promise(resolve => {
        const { offsetWidth, offsetHeight } = this.$refs.merchandiseImage.$el;
        if (!offsetWidth || !offsetHeight) {
          setTimeout(() => {
            this.getMerchandiseImageSize();
          }, 500);
          return;
        }
        let width;
        let height;
        if ((this.rotation <= 95 && this.rotation >= 85) || (this.rotation <= 275 && this.rotation >= 265)) {
          width = offsetHeight;
          height = offsetWidth;
        } else {
          width = offsetWidth;
          height = offsetHeight;
        }
        this.maskSize.width = width;
        this.maskSize.height = height;
        resolve();
      });
    },
    moveLens(e) {
      const { mouseCursor } = this.$refs;
      const zoomerSize = 120;
      const cx = zoomerSize / mouseCursor.offsetWidth;
      const cy = zoomerSize / mouseCursor.offsetHeight;
      const img = this.$refs.wrapper.getBoundingClientRect();
      const pos = this.getCursorPos(e, img);
      let x = pos.x - mouseCursor.offsetWidth / 2;
      let y = pos.y - mouseCursor.offsetHeight / 2;
      if (x > img.width - mouseCursor.offsetWidth) {
        x = img.width - mouseCursor.offsetWidth;
      }
      if (x < 0) {
        x = 0;
      }
      if (y > img.height - mouseCursor.offsetHeight) {
        y = img.height - mouseCursor.offsetHeight;
      }
      if (y < 0) {
        y = 0;
      }
      if (this.isLabelMode) {
        mouseCursor.style.left = `${x}px`;
        mouseCursor.style.top = `${y}px`;
        this.$refs.imageZoomer.style.left = `-${x * cx + 1}px`;
        this.$refs.imageZoomer.style.top = `-${y * cy + 2}px`;
      } else {
        mouseCursor.style.left = '0px';
        mouseCursor.style.top = '0px';
      }
    },
    getCursorPos(e, img) {
      let x = 0;
      let y = 0;
      const event = e || window.event;
      const a = img;
      x = event.pageX - a.left - window.pageXOffset;
      y = event.pageY - a.top - window.pageYOffset;
      return { x, y };
    },
    setHoverLandmark(e) {
      const { offsetX, offsetY } = e;
      const hadReLabeledManualMark = Object.keys(this.reLabeledManualMark).length > 0;
      if (!this.hadActiveManualMeasurement) {
        if (this.labeledMarks.length > 0) {
          this.labeledMarks.forEach(item => {
            const hadReLabeledMark = Object.keys(this.reLabeledMark).length > 0;
            if (hadReLabeledMark && item.id !== this.reLabeledMark.id) return;
            const { x, y } = item.position;
            if (x - 15 < offsetX && offsetX < x + 15 && y - 30 < offsetY && offsetY < y) {
              this.$bus.$emit('landmark-hover', item);
              this.reLabeledMark = item;
            } else if (!this.isReLabel && this.isMarkHover && hadReLabeledMark && !hadReLabeledManualMark) {
              this.$bus.$emit('landmark-hover', {});
              this.reLabeledMark = {};
            }
          });
        }
      }
      if (
        !(
          !this.hadActiveManualMeasurement &&
          !this.measurementLandmarks.every(landmark => !landmark.labeled) &&
          this.isEditing
        )
      ) {
        this.manualLandmarks.forEach((landmarks, index) => {
          if (hadReLabeledManualMark && index !== this.reLabeledManualMark.manualMeasurementIndex) return;

          if (!Array.isArray(landmarks)) return;

          landmarks.forEach((landmark, idx) => {
            if (
              (hadReLabeledManualMark && idx !== this.reLabeledManualMark.landmarkIndex) ||
              (this.activeManualMeasurement.id && landmark.id !== this.activeManualMeasurement.id)
            )
              return;
            const { x, y } = landmark;
            if (x - 15 < offsetX && offsetX < x + 15 && y - 30 < offsetY && offsetY < y) {
              this.reLabeledManualMark = { manualMeasurementIndex: index, landmarkIndex: idx, landmark };
              this.$bus.$emit('setHoverManualMeasurement', landmark);
            } else if (!this.isReLabel && this.isMarkHover && hadReLabeledManualMark) {
              this.reLabeledManualMark = {};
              this.$bus.$emit('setHoverManualMeasurement', {});
            }
          });
        });
      }
      if (this.isReLabel) {
        this.isMarkHover = false;
      } else if (Object.keys(this.reLabeledManualMark).length > 0 || Object.keys(this.reLabeledMark).length > 0) {
        this.isMarkHover = true;
      } else {
        this.isMarkHover = false;
      }
    },
    throttleHoverLandmark() {},
    rotateAuxLine(e) {
      const $line = this.$refs.auxLine;
      if (!$line) return;

      const { x: $lineX, y: $lineY } = getBoxCenter($line);
      const { pageX: mouseX, pageY: mouseY } = e;
      const deg = getRotationDegree(mouseX, mouseY, $lineX, $lineY);
      $line.style.transform = `rotate(${deg}deg)`;

      if (!this.auxLineBase) return;

      const $base = this.$refs.baseLine;
      const { x: baseX, y: baseY } = this.auxLineBase;
      const targetPoint = getNearestPointToALine(baseX, baseY, mouseX, mouseY, $lineX, $lineY);
      let baseDeg = getRotationDegree(baseX, baseY, targetPoint.x, targetPoint.y);
      baseDeg = baseDeg < 7 ? baseDeg + 180 : baseDeg;
      $base.style.transform = `rotate(${baseDeg}deg)`;
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  &.custom-cursor {
    cursor: none;
  }
}
.move {
  cursor: move;
}

.mark {
  @apply tw-absolute tw-w-[24px] tw-h-[30px] tw-flex tw-justify-center tw-items-center tw-text-white;
  @apply tw-transform tw--translate-x-1/2 tw--translate-y-full tw-font-bold;
  background-image: url('../../../assets/home/measurement/ic-mark.svg');
  background-size: contain;
}

.manual-mark {
  @extend .mark;
  background-image: url('../../../assets/home/measurement/ic-mark-purple.svg');
}

.align-center {
  @apply tw-absolute tw-top-1/2 tw-left-1/2;
  @apply tw-transform tw--translate-x-1/2 tw--translate-y-1/2;
}
.lens {
  @apply tw-absolute tw-top-6 tw-right-6 tw-overflow-hidden tw-rounded-full tw-w-[120px] tw-h-[120px];
  border: 1px solid #000;
}

.cursor {
  @apply tw-absolute tw-opacity-0;
  &.hover {
    .cursor-icon {
      @apply tw-text-white;
      line-height: 95px;
      background-image: url('../../../assets/home/measurement/ic_cursor_h.svg');
    }
  }
}
.cursor-icon {
  @apply tw-w-[120px] tw-h-[120px] tw-text-center tw-font-bold;
  line-height: 78px;
  font-size: 12px;
  color: #06f2fc;
  background: url('../../../assets/home/measurement/ic_cursor_n.svg');
}

.purple-cursor {
  @apply tw-opacity-100;

  .cursor-icon {
    background: url('../../../assets/home/measurement/ic_purple_cursor_n.svg');
  }
  &.hover {
    .cursor-icon {
      background: url('../../../assets/home/measurement/ic_purple_cursor_h.svg');
    }
  }
}

.custom-cursor .cursor {
  @apply tw-opacity-100;
}

.custom-purple-cursor {
  cursor: none;
  .cursor {
    @apply tw-opacity-100;

    .cursor-icon {
      background: url('../../../assets/home/measurement/ic_purple_cursor_n.svg');
    }
  }
  .hover {
    @apply tw-opacity-100;
    .cursor-icon {
      background: url('../../../assets/home/measurement/ic_purple_cursor_h.svg');
    }
  }
  &.move {
    cursor: move;
    .cursor-icon {
      @apply tw-opacity-0;
    }
  }
}

.distance-line {
  width: 1px;
  height: 20px;
  background-color: #00ff19;
}

.base-line {
  transform-origin: 0px 0px;
}

.dash-line {
  @apply tw-w-full;
  border-top: 2px dashed #00ff19;
}
.grid-mask {
  @apply tw-absolute tw-top-1/2 tw-left-1/2;
  @apply tw-transform tw--translate-x-1/2 tw--translate-y-1/2;
  width: 300%;
  height: 300%;
  background: url('../../../assets/home/measurement/measurement-grid.svg') 0 0 repeat;
}
</style>
