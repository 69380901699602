<template>
  <div class="root" data-test-id="selection-window">
    <div v-if="!hidden" class="window">
      <!-- Head -->
      <v-layout class="head" align-center>
        <div class="minimize arrow" @click="minimize"></div>
        <div class="window-title">{{ title }}</div>
      </v-layout>
      <!-- Items -->
      <div class="selections">
        <slot :items="selections" :remove="remove"></slot>
      </div>
      <!-- Edit actions -->
      <action-buttons
        :saving="saving"
        :hide-btns-on-save="true"
        :btn-width="108"
        :absolute="true"
        @cancel="$emit('cancel')"
        @confirm="$emit('confirm')"
      />
    </div>
    <div v-else class="toggle" @click="maximize">
      <div class="arrow"></div>
    </div>
  </div>
</template>

<script>
import ActionButtons from '../../common/ActionButtons';

export default {
  name: 'SelectionWindow',
  props: {
    selections: Array,
    hidden: Boolean,
    saving: Boolean,
    title: String,
  },
  components: {
    ActionButtons,
  },
  methods: {
    minimize() {
      this.$emit('update:hidden', true);
    },
    maximize() {
      this.$emit('update:hidden', false);
    },
    remove($event) {
      this.$emit('remove', $event);
    },
  },
};
</script>

<style lang="scss" scoped>
$BLUE: #2dbdcb;
$WINDOW_OFFSET: 192px;
$HEAD_HEIGHT: 36px;
.shadow {
  box-shadow: 2px 1px 5px 0 rgba(0, 0, 0, 0.5);
}

.root {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.window {
  position: relative;
  width: 331px;
  height: calc(100vh - #{$WINDOW_OFFSET});
  min-height: 250px;
  background-color: white;
  border: solid 2px $BLUE;
  @extend .shadow;
}
.head {
  height: $HEAD_HEIGHT;
  background-color: $BLUE;
  color: white;
  vertical-align: middle;
}
.minimize {
  vertical-align: middle;
  display: inline-block;
  margin-left: 10px;
  margin-right: 5px;
  transform: rotateY(180deg);
  cursor: pointer;
}
.arrow {
  width: 20px;
  height: 17px;
  background: url('../../../assets/home/ic-back.svg') no-repeat center;
  background-size: contain;
}
.window-title {
  font-weight: 600;
}
.toggle {
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0 !important;
  background-color: $BLUE;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  @extend .shadow;
}
.selections {
  @apply tw-flex tw-flex-col tw-items-center;
  padding: 15px 0;
  height: calc(100% - #{$HEAD_HEIGHT});
  overflow: auto;
  & > * {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 12px;
  }
}
</style>
