export default {
  remember_account: 'Remember my Scanatic™ account',
  invalid: 'Invalid account or password.',
  suggest_browser:
    'Google Chrome browser with at least 1280 x 800 screen resolution is highly recommended for the best browsing experience',
  account: 'Scanatic™ Account',
  password: 'Enter The Password',
  login: 'Login',
  logout: 'Logout',
  actions__edit_view: 'Edit/View',
  actions__edit_export: 'Export',
  actions__remove: 'Delete',
  project_name: 'Project name',
  created_at: 'Create date',
  completion: 'Complete rate',
  add_project: 'New Project',
  item_count: '0 item | 1 item | {count} items',
  data_list: 'Data list',
  configuration: 'Configuration',
  classification_name: 'Classification Name',
  labeling_type: 'Labeling Type',
  new_project: 'New Project',
  cancel: 'Cancel',
  confirm: 'Confirm',
  skip: 'Skip',
  no_next: 'There is no image left to label',
  max_labels: 'Cannot add more than 10 labels',
  classification_updated: 'Classification updated',
  classification_added: 'Classification added',
  classification_removed: 'Classification and its associated labels are removed',
  imported: 'imported',
  labeled: 'Labeled',
  not_labeled: 'Not Labeled',
  all: 'All',
  classification__only: 'Classification Only',
  classification__bbox: 'Bbox & Classification',
  classification__px: 'Pixel-level & Classification',
  delete: 'Delete',
  check_delete: 'Are you sure to delete？',
  upload_title: 'Upload Image',
  upload_title__done: 'All Images Uploaded',
  upload_title__verifying: 'Verifying Uploaded Images...',
  upload_btn: 'Upload',
  upload_clear: 'Clear',
  upload_status__success: 'Success',
  upload_status__error: 'Error',
  upload_status__uploading: 'Uploading',
  upload_status__paused: 'Waiting',
  upload_status__waiting: 'Waiting',
  upload_project: 'Project',
  should_has_projectTitle: 'Project name cannot be empty',
  should_has_tag: 'Add at least one classification',
  should_has_labelingType: 'Select a labeling type',
  images_repeat: 'The image already exists',
  explorer_field_selection_count: '0 item | 1 item selected | {count} items selected',
  cm: 'cm',
  inch: 'inch',
  editorial_markers: 'Edit label',
  output_preview: 'Preview & Export',
  save: 'Save',
  no_data__single_file: 'Please select one excel file at a time',
  no_data__valid_file: '<strong>Unsupported file format</strong><br>Supported file format: XLSX',
  no_data__instruction: 'Please drag and drop the file here or click "Import"',
  output_text_1:
    'Please select the product image first then adjust the numbers\' positions and the image size at the following area',
  product_photos: 'Product image',
  reset: 'Reset',
  apply: 'Apply',
  select: 'Select',
  move: 'Move',
  zoom_out: 'Zoom out',
  zoom_in: 'Zoom in',
  viewer_text_1: 'No image detected',
  clear: 'Clear',
  complete: 'Complete',
  category: 'Category',
  sn: 'Product Code',
  product_name: 'Product Name',
  set_marker_image: 'Import labeling image',
  no_picture: 'No image',
  leave: 'Leave',
  current_balance: 'Remain credits',
  selected_pictures: 'Selected garments',
  terminate_upload: 'End uploading',
  terminate_upload_text: 'Upload unfinished; the upload process will be stopped once leave',
  projects: 'Project',
  folder: 'Folder',
  hide_column: 'Hidden row',
  delete_column: 'Delete row',
  rename: 'Rename',
  pictures: 'Image',
  show_column: 'Display row',
  no_license_available: 'You don\'t have access to this service. Please contact our sales window at sales@tg3ds.com',
  upload_timeout_title: 'Upload timeout',
  upload_timeout: 'Please re-upload the images.',
  measure_error__unknown_title: 'Encounter unexpected errors',
  measure_error__unknown:
    'Please confirm that the image and the checkerboard meet our specifications and re-upload. Contact us if it is still not working.',
  measure_error__chessboard_size_title: 'The size of the checkerboard doesn\'t meet our specifications',
  measure_error__chessboard_size:
    'Please confirm and use the checkerboard that meets our specifications, retake the images and re-upload. If you need to update the chessboard size, please contact TG3D Studio.',
  measure_error__clothing_type_title: 'Clothing category does not match',
  measure_error__clothing_type: 'Please upload qualify images.',
  measure_error__image_size_title: 'Image resolution does not match',
  measure_error__image_size:
    'Image resolution doesn\'t meet the size of 4032 X 3024. Please confirm that your capturing equipment meets the requirements (iPhone 8+，11, 11 pro, 12) and re-upload.',
  measure_error__chessboard_detection_title: 'Unable to detect the checkerboard',
  measure_error__chessboard_detection:
    'Please retake and re-upload the image after confirming that the uploaded image meets our specifications, the checkerboard is captured in full, the background is clear and the shooting distance is appropriate.',
  measure_error__point_detection_title: 'Unable to detect the feature points of the checkerboard',
  measure_error__point_detection:
    'Please retake and re-upload the image after confirming that the light source is evenly distributed with no exposure, no other objects are blocking the chessboard and the shooting distance is appropriate.',
  filename: 'Upload failed (File name: {filename})',
  dismiss: 'Close',
  measure_upload_error__unknown_title: 'Encounter unexpected errors',
  measure_upload_error__unknown:
    'Please confirm the network connectivity and re-upload. If still not working, please contact TG3D Studio.',
  vest_length_of_clothing: 'Body length',
  vest_width_of_chest: 'Chest width',
  vest_length_of_sleeve: 'Armhole',
  vest_width_of_hem: 'Hem width',
  vest_width_of_shoulder: 'Cross shoulder width',
  long_sleeve_top_length_of_clothing: 'Body length',
  long_sleeve_top_width_of_chest: 'Chest width',
  long_sleeve_top_width_of_cuff: 'Sleeve opening',
  long_sleeve_top_length_of_sleeve: 'Sleeve length',
  long_sleeve_top_width_of_hem: 'Hem width',
  long_sleeve_top_width_of_shoulder: 'Cross shoulder width',
  long_sleeve_top_width_of_sleeve: 'Armhole',
  short_sleeve_top_length_of_clothing: 'Body length',
  short_sleeve_top_width_of_chest: 'Chest width',
  short_sleeve_top_width_of_cuff: 'Sleeve opening',
  short_sleeve_top_length_of_sleeve: 'Sleeve length',
  short_sleeve_top_width_of_hem: 'Hem width',
  short_sleeve_top_width_of_shoulder: 'Cross shoulder width',
  short_sleeve_top_width_of_sleeve: 'Armhole',
  long_sleeve_outwear_length_of_clothing: 'Body length',
  long_sleeve_outwear_width_of_chest: 'Chest width',
  long_sleeve_outwear_width_of_cuff: 'Sleeve opening',
  long_sleeve_outwear_length_of_sleeve: 'Sleeve length',
  long_sleeve_outwear_width_of_hem: 'Hem width',
  long_sleeve_outwear_width_of_shoulder: 'Cross shoulder width',
  long_sleeve_outwear_width_of_sleeve: 'Armhole',
  long_sleeve_dress_length_of_clothing: 'Body length',
  long_sleeve_dress_width_of_chest: 'Chest width',
  long_sleeve_dress_width_of_cuff: 'Sleeve opening',
  long_sleeve_dress_length_of_sleeve: 'Sleeve length',
  long_sleeve_dress_width_of_hem: 'Hem width',
  long_sleeve_dress_width_of_shoulder: 'Cross shoulder width',
  long_sleeve_dress_width_of_sleeve: 'Armhole',
  sling_dress_length_of_clothing: 'Body length',
  sling_dress_width_of_chest: 'Chest width',
  sling_dress_width_of_hem: 'Hem width',
  skirts_width_of_waist: 'Waist',
  skirts_length_of_skirt: 'Skirt length',
  skirts_width_of_hip: 'Hip width',
  skirts_width_of_hem: 'Hem width',
  trouser_length_of_pants: 'Pants length',
  trouser_width_of_waist: 'Waist',
  trouser_width_of_hip: 'Hip width',
  trouser_length_of_pants_crotch: 'Crotch length',
  trouser_width_of_thighs: 'Thigh width',
  trouser_width_of_pants: 'Leg opening',
  shorts_length_of_pants: 'Pants length',
  shorts_width_of_waist: 'Waist',
  shorts_width_of_hip: 'Hip width',
  shorts_length_of_pants_crotch: 'Crotch length',
  shorts_width_of_thighs: 'Thigh width',
  shorts_width_of_pants: 'Leg opening',
  vest_length_of_clothing_definition: 'HPS to hem',
  vest_width_of_chest_definition: 'Width between two sides of the armpit',
  vest_length_of_sleeve_definition: 'Length between shoulder point and armpit',
  vest_width_of_hem_definition: 'Width between two sides of the hem',
  vest_width_of_shoulder_definition: 'Width between two sides of the shoulder point',
  long_sleeve_top_length_of_clothing_definition: 'HPS to hem',
  long_sleeve_top_width_of_chest_definition: 'Width between two sides of the armpit',
  long_sleeve_top_width_of_cuff_definition: 'Sleeve opening width',
  long_sleeve_top_length_of_sleeve_definition: 'Length from shoulder point to sleeve opening',
  long_sleeve_top_width_of_hem_definition: 'Width between two sides of the hem',
  long_sleeve_top_width_of_shoulder_definition: 'Width between two sides of the shoulder point',
  long_sleeve_top_width_of_sleeve_definition: 'Width between shoulder point and armpit',
  short_sleeve_top_length_of_clothing_definition: 'HPS to hem',
  short_sleeve_top_width_of_chest_definition: 'Width between two sides of the armpit',
  short_sleeve_top_width_of_cuff_definition: 'Sleeve opening width',
  short_sleeve_top_length_of_sleeve_definition: 'Length from shoulder point to sleeve opening',
  short_sleeve_top_width_of_hem_definition: 'Width between two sides of the hem',
  short_sleeve_top_width_of_shoulder_definition: 'Width between two sides of the shoulder point',
  short_sleeve_top_width_of_sleeve_definition: 'Width between shoulder point and armpit',
  long_sleeve_outwear_length_of_clothing_definition: 'HPS to hem',
  long_sleeve_outwear_width_of_chest_definition: 'Width between two sides of the armpit',
  long_sleeve_outwear_width_of_cuff_definition: 'Sleeve opening width',
  long_sleeve_outwear_length_of_sleeve_definition: 'Length from shoulder point to sleeve opening',
  long_sleeve_outwear_width_of_hem_definition: 'Width between two sides of the hem',
  long_sleeve_outwear_width_of_shoulder_definition: 'Width between two sides of the shoulder point',
  long_sleeve_outwear_width_of_sleeve_definition: 'Width between shoulder point and armpit',
  long_sleeve_dress_length_of_clothing_definition: 'HPS to hem',
  long_sleeve_dress_width_of_chest_definition: 'Width between two sides of the armpit',
  long_sleeve_dress_width_of_cuff_definition: 'Sleeve opening width',
  long_sleeve_dress_length_of_sleeve_definition: 'Length from shoulder point to sleeve opening',
  long_sleeve_dress_width_of_hem_definition: 'Width between two sides of the hem',
  long_sleeve_dress_width_of_shoulder_definition: 'Width between two sides of the shoulder point',
  long_sleeve_dress_width_of_sleeve_definition: 'Width between shoulder point and armpit',
  sling_dress_length_of_clothing_definition: 'Length from top to hem (exclude strap)',
  sling_dress_width_of_chest_definition: 'Width between two sides of the armpit',
  sling_dress_width_of_hem_definition: 'Width between two sides of the hem',
  skirts_width_of_waist_definition: 'Width between two sides of the waist',
  skirts_length_of_skirt_definition: 'Length from waist to skirt hem',
  skirts_width_of_hip_definition: 'Width at 15cm below the waist',
  skirts_width_of_hem_definition: 'Width between two skirt\'s sides',
  trouser_length_of_pants_definition: 'Length from waist to leg opening',
  trouser_width_of_waist_definition: 'Width between two sides of the waist',
  trouser_width_of_hip_definition: 'Width from waistband to 1/2 of crotch length',
  trouser_length_of_pants_crotch_definition: 'Length from waist to crotch',
  trouser_width_of_thighs_definition: 'Width under the crotch',
  trouser_width_of_pants_definition: 'Width of leg opening',
  shorts_length_of_pants_definition: 'Length from waist to leg opening',
  shorts_width_of_waist_definition: 'Width between two sides of the waist',
  shorts_width_of_hip_definition: 'Width from waistband to 1/2 of crotch length',
  shorts_length_of_pants_crotch_definition: 'Length from waist to crotch',
  shorts_width_of_thighs_definition: 'Width under the crotch',
  shorts_width_of_pants_definition: 'Width of leg opening',
  legging_waistband_height: 'Waistband height',
  legging_front_rise_including_wb: 'Front rise including WB',
  legging_back_rise_including_wb: 'Back rise including WB',
  legging_waist_width: 'Waist width',
  legging_hip_width: 'Hip width',
  legging_inseam: 'Inseam',
  legging_thigh_width: 'Thigh width (1" from crotch)',
  legging_knee_width: 'Knee width (13" from crotch)',
  legging_calf_width: 'Calf width (5" down from knee)',
  legging_leg_opening: 'Leg opening',
  legging_waistband_height_definition: 'Waistband height',
  legging_front_rise_including_wb_definition: 'Length from the top of the front waistband to the crotch line',
  legging_back_rise_including_wb_definition: 'Length from the top of the back waistband to the crotch line',
  legging_waist_width_definition: 'Width of top waistband',
  legging_hip_width_definition:
    'Length of 9 1/2 inches straight down from the top two sides of the waistband; connect throught hip point',
  legging_inseam_definition: 'Length from crotch junction to the inside leg opening',
  legging_thigh_width_definition: 'Width at 1 inch straight down from the crotch',
  legging_knee_width_definition: 'Width at 13 inches straight down from the crotch',
  legging_calf_width_definition: 'Width at 5 inches below the knee',
  legging_leg_opening_definition: 'Width of leg opening',
  garment_measure: {
    excel_invalid_title: 'Unsupported excel format',
    excel_invalid: 'Please use our sheet template and re-upload.',
    excel_unsupported_format_title: 'Unsupported file format',
    excel_unsupported_format: 'Supported file format: XLSX',
    excel_header_date: 'Date',
    excel_header_item: 'Product Code',
    excel_header_name: 'Product Name',
    excel_header_category: 'Category',
    excel_header_size: 'Size\n( Fill in from left to right; S to XL )',
    excel_header_product_features:
      'Product Features (Optional):\ne.g.\n- Design highlight/Product style\n- Pattern\n- Material\n- Size recommendation',
    excel_header_matching_suggestions:
      'Styling Recommendation (Optional):\ne.g.\n- Style\n- Recommendation\n- Similar products\n- More "brand" products',
    excel_header_product_specifications: 'Product Specification (Optional):\ne.g.\n- Origin\n- Materials',
    root_path: 'Home',
    import_excel: 'Import from sheet (.xlsx)',
    download_template: 'Download sheet template',
    excel_template: 'Scanatic For AI template.xlsx',
    excel_file_path: 'garment_measure_template_en.xlsx',
    product_list: 'Product list',
    explorer__field__name: 'Name',
    explorer__field__category: 'Category',
    explorer__field__total: 'Quantity',
    explorer__field__uploaded: 'Uploaded',
    explorer__field__uploadAt: 'Last edit',
    no_pictures_marked_yet: 'No images labeled yet',
    previous: 'Previous',
    next: 'Next',
    rename_manual_measurements: 'Re-name manual measurement',
    add_manual_measurements: 'Add manual measurement',
    manual_measurement_name: 'Manual measurement name',
    manual_measurement_definition: 'Manual measurement definition',
    manual_measurement: 'Manual measurement',
    rotation: 'Rotate',
    measure_tips_text_1: 'Reference the guidance on the left side corner to complete the labeling process',
    measure_tips_text_2: 'Set up labels on the below image to complete manual measurement',
    measure_tips_text_3:
      'Rotate the angles will affects the labeling position. The previous labeling data will be deleted after you click "confirm".',
    measure_tips_text_4: 'Hint',
    measure_tips_text_5: 'Confirm to delete all the labeling data?',
    measure_tips_text_6: 'Clear labeling data',
    measure_tips_text_7: 'Confirm to delete the current labeling data?',
    measure_tips_text_8: 'Clear manual labeling data',
    measure_tips_text_9: 'Labeling process is incomplete. Do you want to store the edited data?',
    measure_tips_text_10: 'Leave manual measurement',
    measure_tips_text_11: 'Labeling process is incomplete. Landmarks will be removed after leaving.',
    measure_tips_text_12: 'Leaving labeling',
    measure_tips_text_13: 'Labeling has not completed. Do you want to save this edit?',
    measure_tips_text_14: 'Edit label',
    set_product_screenshot: 'Please adjust the garment screenshot on the left side.',
    size: 'Size',
    select_image_tip_1: 'Please select one image at least',
    select_image_tip_2: 'Oops! Looks like you do not have enough credit. Please contact TG3D to store more value.',
    select_image_tip_3: 'Not enough credit',
    select_image_tip_4: 'Upload successfully',
    success_total: '0 pc | 1 pc | {count} pcs',
    select_image_tip_5: 'Remain credits',
    select_image_tip_6: 'Are you sure you want to delete {fileName}?',
    select_image_tip_7: 'Delete image',
    select_image_tip_8: 'Deselecting will erase the current labels. Are you sure you want to deselect?',
    select_image_tip_9: 'Deselect',
    image_posing_tips: 'Straighten the image before tagging',
    size_tagging_complete: 'Size {size} tagging completed',
  },
};
