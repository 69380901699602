import LoadingSpinner from './LoadingSpinner';

const Plugin = function Plugin(Vue) {
  this.Vue = Vue;
  this.mounted = false;
  this.$root = {};
};

Plugin.prototype.mountIfNotMounted = function mountIfNotMounted() {
  if (this.mounted === true) return;

  this.mounted = true;

  this.$root = (() => {
    const LoadingSpinnerConstructor = this.Vue.extend(LoadingSpinner);
    const node = document.createElement('div');
    document.querySelector('#app').appendChild(node);

    return new LoadingSpinnerConstructor().$mount(node);
  })();

  this.mounted = true;
};

Plugin.prototype.open = function open() {
  this.mountIfNotMounted();
  this.$root.setShow(true);
};

Plugin.prototype.close = function close() {
  this.mountIfNotMounted();
  this.$root.setShow(false);
};

Plugin.install = function install(vue) {
  const Vue = vue;
  Vue.loadingSpinner = new Plugin(vue);
  Vue.prototype.$loadingSpinner = Vue.loadingSpinner;
};

export default Plugin;
