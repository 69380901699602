import SparkMD5 from 'spark-md5';
import i18n from '@/i18n/i18n';

// eslint-disable-next-line import/prefer-default-export
export const DateTimeFormatter = new Intl.DateTimeFormat(i18n.locale || 'en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
});

export const wait = sec =>
  new Promise(resolve => {
    setTimeout(resolve, sec * 1000);
  });

export const getMD5FromBlob = file => {
  const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
  const chunkSize = 2097152; // Read in chunks of 2MB
  const chunks = Math.ceil(file.size / chunkSize);
  let currentChunk = 0;
  const spark = new SparkMD5.ArrayBuffer();
  const fileReader = new FileReader();

  function loadNext() {
    const start = currentChunk * chunkSize;
    const end = start + chunkSize >= file.size ? file.size : start + chunkSize;

    fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
  }

  return new Promise(resolve => {
    fileReader.onload = function onload(e) {
      spark.append(e.target.result);
      currentChunk += 1;

      if (currentChunk < chunks) {
        loadNext();
      } else {
        resolve(spark.end());
      }
    };
    fileReader.onerror = function onerror() {};

    loadNext();
  });
};

export const uploadToS3 = (url, file) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) {
        return;
      }
      if (xhr.status === 200) {
        resolve();
      } else {
        reject();
      }
    };
    xhr.send(file);
  });

export const base64ToBlob = dataUrl => {
  const bytesString = atob(dataUrl.substring(dataUrl.indexOf(',') + 1));
  const mimeType = dataUrl
    .substring(0, dataUrl.indexOf(','))
    .split(':')[1]
    .split(';')[0];
  const content = [];
  for (let i = 0; i < bytesString.length; i += 1) {
    content[i] = bytesString.charCodeAt(i);
  }
  return new Blob([new Uint8Array(content)], { type: mimeType });
};

export const getRotation = (rotation = 0) => {
  const remainder = rotation % 90;
  if (remainder < 0) return 0;

  return remainder > 10 ? rotation + 90 - remainder : rotation - remainder;
};

export const getDistance = (x1, y1, x2, y2) => {
  const distanceX = Math.abs(x2 - x1);
  const distanceY = Math.abs(y2 - y1);
  return Math.sqrt(distanceX * distanceX + distanceY * distanceY);
};

// given a point and a line, get the projected position of the point on the line extension
export const getNearestPointToALine = (pointX, pointY, x1, y1, x2, y2) => {
  const A = pointX - x1;
  const B = pointY - y1;
  const C = x2 - x1;
  const D = y2 - y1;
  const dot = A * C + B * D;
  const lengthSquare = C * C + D * D;
  const param = lengthSquare !== 0 ? dot / lengthSquare : -1;
  return { x: x1 + param * C, y: y1 + param * D };
};

// get the shortest distance from a point to a line
export const getPointLineDistance = (pointX, pointY, x1, y1, x2, y2) => {
  const nearest = getNearestPointToALine(pointX, pointY, x1, y1, x2, y2);
  return getDistance(pointX, pointY, nearest.x, nearest.y);
};

export const getRotationDegree = (x1, y1, x2, y2) => {
  const radians = Math.atan2(y1 - y2, x1 - x2);
  return radians * (180 / Math.PI);
};

export const getBoxCenter = $ele => {
  if (!$ele) return { x: 0, y: 0 };

  const box = $ele.getBoundingClientRect();
  return {
    x: box.left + box.width / 2,
    y: box.top + box.height / 2,
  };
};
