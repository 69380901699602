<template>
  <v-list class="Zindex10">
    <v-list-tile
      class="action-item"
      v-for="action in actions"
      :key="action.title"
      :data-test-id="`action-${action.event}`"
      :class="{ disable: action.disable, 'type-1': action.type === 1 }"
      @click.stop="takeAction(action)"
    >
      <v-list-tile-action>
        <img width="20" height="20" class="svg-icon" v-if="action.icon" :src="action.icon" />
      </v-list-tile-action>
      <v-list-tile-title>{{ action.title }}</v-list-tile-title>
    </v-list-tile>
  </v-list>
</template>

<script>
export default {
  name: 'ActionList',
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    takeAction(action) {
      if (action.disable) return;

      this.$emit('take-action', action);
    },
  },
};
</script>

<style lang="scss" scoped>
.action-item {
  text-align: center;
  min-width: 143px;
  ::v-deep .v-list__tile__action {
    min-width: 30px;
    height: initial;
  }
  ::v-deep .v-list__tile--link {
    padding: 0 10px;
    margin: 0;
    position: relative;
  }
  ::v-deep .v-list__tile__title {
    width: initial;
  }
  &:not(:last-child):after {
    content: '';
    border-bottom: 1px solid #f3f3f3;
    width: 90%;
    height: 1px;
    display: block;
    margin: 0 auto;
  }
  &.disable {
    @apply tw-opacity-50;
    ::v-deep .v-list__tile--link {
      @apply tw-cursor-not-allowed;
    }
  }
}
.type-1 {
  ::v-deep .v-list__tile {
    @apply tw-flex tw-justify-center tw-rounded-md;
    margin: 10px;
    padding: 0;
    background-color: #f3f3f3;
  }
}
.Zindex10 {
  z-index: 10;
  &.theme--dark {
    .action-item:not(:last-child):after {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }
}
</style>
