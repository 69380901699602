<template>
  <app :page-title="'Garment Measure'">
    <v-container fluid class="home--bg">
      <!-- Home Content -->
      <v-layout justify-center>
        <v-flex>
          <!-- Project Lists -->
          <explorer
            :items="items"
            :total-items="totalItems"
            :total-pages="totalPages"
            :current-page="currentPage"
            :paths="currentPath"
            :tags="tags"
            :actions="actions"
            :page-sizes="pageSizes"
            :current-page-size="pageSize"
            :sort="sortingOptions"
            :is-searching="isSearching"
            :filter="filter"
            :is-requesting="isRequesting"
            :can-select="true"
            :can-import="true"
            :can-select-all="true"
            :can-search="true"
            :select-all="selectAll"
            :fix-view-mode="'list'"
            :columns="columns"
            :no-data="noData"
            put-action-menu-to-end
            @select="selectItem"
            @delete="deleteData"
            @export="exportMerchandises"
            @start-searching="startSearching"
            @change-search-value="changeSearchValue"
            @cancel-search="cancelSearch"
            @change-sorting-options="changeSortingOptions"
            @change-page-size="changePageSize"
            @get-data="getData"
            @click-item="openMeasurementDialog"
            @click-dir="traverse"
            @go-back="goBack"
            @toggle-all="handleToggleAll"
            @import-file="importFile"
          >
            <v-menu v-if="isListingMerchandise" slot="top-button" class="tw-mr-5" bottom nudge-bottom="47">
              <div slot="activator" class="file-icon" />
              <action-list dark :actions="topButtonAction" @take-action="takeAction" />
            </v-menu>
            <div
              v-else
              slot="top-button"
              class="import-icon tw-mr-5 tw-cursor-pointer"
              @click="showCreateProjectDialog = true"
            />

            <div slot="top-right-button" class="tw-flex tw-items-center">
              <img src="../assets/home/ic-balance.svg" alt="balance" class="tw-mr-2" />
              <div>{{ $t('current_balance') }}：{{ balance.remaining }}</div>
            </div>
            <!-- Single List -->
            <template slot-scope="{ items, click, select, toggle, canSelect }">
              <measurement-item
                v-for="item in items"
                :key="item.id"
                :item="item"
                :can-select="canSelect"
                @click="click"
                @select="select"
                @menu="toggle"
              />
            </template>
          </explorer>
        </v-flex>
      </v-layout>
      <v-layout style="height: 50px"></v-layout>
      <input
        ref="excelFileInput"
        type="file"
        style="display: none"
        accept=".xlsx, .csv, .xls"
        multiple
        @change="importFile"
      />

      <measurement-dialog :show-dialog.sync="showMeasurementDialog" />
      <selection-image-dialog />
      <project-creation-dialog
        :show-dialog="showCreateProjectDialog"
        @save="createMeasurementProject"
        @cancel="showCreateProjectDialog = false"
      />
    </v-container>
  </app>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import axios from 'axios';
import XLSX from 'xlsx';
import JSZip from 'jszip';
import Compressor from 'compressorjs';
import { saveAs } from 'file-saver';
import format from 'date-fns/format';
import i18n from '@/i18n/i18n';
import hasDialog from '../mixins/has-dialog';
import { isValidExcel } from '@/components/home/explorer/utils';
import UnitConversion from '../modules/unit-conversion';

import App from '../components/common/App';
import Explorer from '@/components/home/explorer/Explorer';
import explorer from '@/components/home/mixin/explorer';
import MeasurementItem from '@/components/home/Measurement/MeasurementItem';
import ActionList from '../components/home/explorer/ActionList';
import MeasurementDialog from '../components/home/Measurement/MeasurementDialog';
import SelectionImageDialog from '../components/home/Measurement/SelectionImageDialog';
import ProjectCreationDialog from '@/components/home/ProjectCreationDialog';

import exportImg from '../assets/export_icon.svg';
import deleteImg from '../assets/ic-delete.svg';

// import deleteImg from '@/assets/ic-delete.svg';
import importExcelImg from '../assets/home/ic-import-excel.svg';
import downloadExcelImg from '../assets/home/ic-download-excel.svg';

const MEASUREMENT_COLUMNS = [
  { name: i18n.t('garment_measure.explorer__field__name'), width: 3, key: 'name', sortable: true },
  { name: i18n.t('garment_measure.explorer__field__category'), width: 3, key: 'category', sortable: true },
  { name: i18n.t('garment_measure.explorer__field__total'), width: 1, key: 'total', sortable: false },
  { name: i18n.t('garment_measure.explorer__field__uploaded'), width: 1, key: 'uploaded', sortable: false },
  { name: i18n.t('garment_measure.explorer__field__uploadAt'), width: 4, key: 'updatedAt', sortable: true },
];

const MEASUREMENT_PROJECT_COLUMNS = [
  { name: i18n.t('garment_measure.explorer__field__name'), width: 3, key: 'name', sortable: true },
  { name: i18n.t('garment_measure.explorer__field__category'), width: 5, key: 'category', sortable: true },
  { name: i18n.t('garment_measure.explorer__field__uploadAt'), width: 4, key: 'updatedAt', sortable: true },
];

const ITEMS_PER_PAGE = [40, 80];

const EXCEL_HEADER = [
  { name: i18n.t('garment_measure.excel_header_date'), width: 20 },
  { name: i18n.t('garment_measure.excel_header_item'), width: 20 },
  { name: i18n.t('garment_measure.excel_header_name'), width: 30 },
  { name: i18n.t('garment_measure.excel_header_category'), width: 20 },
  { name: i18n.t('garment_measure.excel_header_size'), width: 10 },
  { name: i18n.t('garment_measure.excel_header_product_features'), width: 60 },
  { name: i18n.t('garment_measure.excel_header_matching_suggestions'), width: 60 },
  { name: i18n.t('garment_measure.excel_header_product_specifications'), width: 60 },
];

const getZipFileName = items => {
  let name = items.sn;
  if (Array.isArray(items) && items.length === 1) {
    name = items[0].sn;
  } else if (!name) {
    name = `GM-${format(new Date(), 'yyyyMddHHmm')}`;
  }
  return name.replace(/\//g, '-');
};

export default {
  name: 'GarmentMeasure',
  components: {
    App,
    MeasurementItem,
    Explorer,
    ActionList,
    MeasurementDialog,
    SelectionImageDialog,
    ProjectCreationDialog,
  },
  mixins: [explorer, hasDialog],
  data() {
    return {
      selected: [],
      sortingOptions: {
        by: 'updatedAt',
        asc: false,
      },
      tabs: [
        {
          id: 'measurement',
          name: '',
          endpoint: 'projects',
          params: { type: 1 },
        },
      ],
      tags: [],
      totalItems: 0,
      paths: [[{ name: this.$t('garment_measure.root_path'), root: true, disabled: false }]],
      pageSizes: ITEMS_PER_PAGE,
      measurementActions: [
        {
          event: 'export',
          icon: exportImg,
          dir: true,
          title: this.$t('actions__edit_export'),
          multiple: true,
          disable: target => !target.canExport,
        },
        {
          event: 'delete',
          icon: deleteImg,
          dir: true,
          title: this.$t('actions__remove'),
          multiple: true,
        },
      ],
      measurementTopButtonAction: [
        {
          event: 'importExcel',
          icon: importExcelImg,
          title: this.$t('garment_measure.import_excel'),
          type: 0,
        },
        {
          event: 'downloadExcel',
          icon: downloadExcelImg,
          title: this.$t('garment_measure.download_template'),
          type: 1,
        },
      ],
      dialog: {
        checkDialog: {
          projectDialog: false,
          bBoxAndClassification: false,
        },
        singleProductDialog: {},
        singleBBoxDialog: {},
      },
      files: [],
      showMeasurementDialog: false,
      currentProject: null,
      showCreateProjectDialog: false,
    };
  },
  watch: {
    showMeasurementDialog(nv) {
      this.toggleRootOverflowHidden(nv);
      if (!nv) {
        this.refresh();
      }
    },
  },
  async mounted() {
    this.$bus.$on('export-merchandise', this.exportMerchandises);
    this.onTabChanged(this.tabs[0].id);
    this.getBalance();
  },
  beforeDestroy() {
    this.$bus.$off('export-merchandise', this.exportMerchandises);
  },
  methods: {
    ...mapMutations('labelingTool', ['SET_CURRENT_ITEM', 'SET_MERCHANDISES_LIST', 'SET_BALANCE']),
    // get All Products
    async getData(page, folder) {
      this.selectAll = false;
      const params = { ...this.pagination };
      const { by, asc } = this.sortingOptions;
      if (by) {
        params.sort = asc ? by : `-${by}`;
      }
      if (this.filter && !folder) {
        params.filter = this.filter;
      } else {
        const currentFolder = folder || this.currentFolder;
        const { id, isDir, isProject } = currentFolder;
        if (id && isDir && !isProject) {
          params.parentId = currentFolder.id;
        }
      }
      if (page) {
        params.offset = (page - 1) * this.pagination.limit;
        this.currentPage = page;
      }
      const { endPoint, realm } = this;
      try {
        this.scrollToTop();
        this.isRequesting = true;
        Object.assign(params, this.params);
        const { data } = await this.$http.get(endPoint, { params });
        const { total } = data;
        this.items = data[realm].map(item => this.makeExplorerItem(item));
        this.totalItems = total;
      } catch (e) {
        const { response } = e;
        const { status } = response || {};
        if (status === 401) {
          this.$router.push('/login');
        }
      } finally {
        this.isRequesting = false;
      }

      this.isRequesting = false;
    },
    async getBalance() {
      const { data } = await this.$http.get('/api/v1/balance');
      this.SET_BALANCE(data);
    },
    async traverse(folder) {
      if (!this.filter) {
        if (this.isSearching) {
          this.isSearching = false;
        }
        if (!this.currentPath.find(existing => existing.id === folder.id)) {
          this.currentPath.push(folder);
        }
      }
      if (folder.isProject) {
        this.currentProject = folder;
      }
      await this.getData(1, folder);
    },
    makeExplorerItem(source) {
      const isProject = !this.isListingMerchandise;
      return {
        ...source,
        selected: false,
        current: false,
        isDir: source.isDir || isProject,
        isProject,
      };
    },
    // refresh Projects to page1
    async refreshAllProjects() {
      this.items = [];
      await this.getData(1);
    },
    cancelSearch() {
      this.isSearching = false;
      this.filter = '';
      this.refreshAllProjects();
    },
    takeAction(action) {
      switch (action.event) {
        case 'importExcel':
          this.uploadExcel();
          break;
        case 'downloadExcel':
          this.downloadExcel();
          break;
        default:
      }
    },
    downloadExcel() {
      axios
        .get(`${window.location.origin}/${this.$t('garment_measure.excel_file_path')}`, {
          responseType: 'blob', // important template file
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const fname = this.$t('garment_measure.excel_template');
          link.href = url;
          link.setAttribute('download', fname);
          document.body.appendChild(link);
          link.click();
        });
    },
    uploadExcel() {
      this.$refs.excelFileInput.click();
    },
    fileChange() {
      const { files } = this.$refs.excelFileInput;
      if (files.length > 0) {
        Object.keys(files).forEach(i => this.files.push(files[i]));
      }
      this.$refs.excelFileInput.value = '';
    },
    openMeasurementDialog(item) {
      this.SET_CURRENT_ITEM(item);
      this.SET_MERCHANDISES_LIST(this.items);
      this.showMeasurementDialog = true;
    },
    async createMeasurementProject(name) {
      try {
        this.isRequesting = true;
        await this.$http.post(this.endPoint, { name, type: 1 });
        this.showCreateProjectDialog = false;
        await this.refreshAllProjects();
      } catch (e) {
        console.log(e.message);
        this.$toastMessage.info(e.message);
      } finally {
        this.isRequesting = false;
      }
    },
    async importFile(event) {
      if (!this.isListingMerchandise) return;
      const files = event.target.files || event.dataTransfer.files;
      if (files.length > 1) {
        this.$toastMessage.info(this.$t('no_data__single_file'));
      } else if (!isValidExcel(files[0])) {
        this.$toastMessage.info({
          title: this.$t('garment_measure.excel_unsupported_format_title'),
          text: this.$t('garment_measure.excel_unsupported_format'),
        });
      } else {
        const formData = new FormData();
        formData.append('file', files[0]);
        this.importing = true;
        try {
          await this.$http.post(this.endPoint, formData);
          await this.refresh();
        } catch (e) {
          const { response = {}, message } = e;
          let error = {
            title: this.$t('garment_measure.excel_invalid_title'),
            text: this.$t('garment_measure.excel_invalid'),
          };
          if (!response.data || !response.data.error) {
            error = message;
          }
          this.$toastMessage.info(error);
        } finally {
          this.importing = false;
        }
      }
      event.target.value = ''; // eslint-disable-line
    },
    async exportMerchandises(items) {
      let requests = [];
      const merchandises = [];
      try {
        if (Array.isArray(items)) {
          requests = items
            .filter(item => item.canExport)
            .map(item => this.$http.post(`/api/v1/merchandises/${item.id}/export`));
        } else {
          requests = [this.$http.post(`/api/v1/merchandises/${items.id}/export`)];
        }
        if (requests.length === 0) return; // nothing to export

        this.isRequesting = true;
        const dataList = await Promise.all(requests);
        dataList.forEach(item => {
          item.data.forEach(data => {
            merchandises.push(data);
          });
        });
        const carriage = /\r/g;
        const photosToDownload = {};
        const wb = XLSX.utils.book_new();

        // save one for displaying measurement keys
        const sizesMaxColumn = merchandises.reduce((acc, curr) => Math.max(acc, curr.sizes.length), 0) + 1;
        // add header back to the beginning
        const header = [...EXCEL_HEADER.map(h => h.name)];
        // pad the size related columns
        header.splice(5, 0, ...new Array(sizesMaxColumn - 1).fill(''));
        let wsData = [header];
        const sizeColSpec = EXCEL_HEADER.find(h => h.name === this.$t('garment_measure.excel_header_size'));
        const cols = [...EXCEL_HEADER.map(h => ({ width: h.width }))];
        cols.splice(5, 0, ...new Array(sizesMaxColumn - 1).fill({ width: sizeColSpec.width }));
        const merges = [{ s: { c: 4, r: 0 }, e: { c: 4 + (sizesMaxColumn - 1), r: 0 } }]; // header
        merchandises.forEach(merchandise => {
          const {
            date,
            sn,
            name,
            category,
            characteristics,
            recommendations,
            specifications,
            imageUrl,
            snapshotUrl,
            sizes,
          } = merchandise;
          if (imageUrl) {
            photosToDownload[sn] = photosToDownload[sn] || {};
            photosToDownload[sn].image = imageUrl;
          }
          if (snapshotUrl) {
            photosToDownload[sn] = photosToDownload[sn] || {};
            photosToDownload[sn].snapshot = snapshotUrl;
          }
          const sizesColumn = new Array(sizesMaxColumn).fill('');
          // sizesColumn is the first row in the table that holds the size names
          const allRowsForSizeTable = [sizesColumn];
          sizes.forEach((size, sizeIndex) => {
            const { name: sizeName, measurements = [], cms = [] } = size;
            // fill in size name in the first row
            const columnIndex = 1 + sizeIndex; // one for the left empty space
            sizesColumn[columnIndex] = sizeName.toUpperCase();

            // if haven't already, fill all the rows in addition to the first
            const filtered = measurements.filter(m => m.visible);
            const rowsNeeded = filtered.length; // this one should be fixed across sizes
            if (rowsNeeded === 0) return; // no measurements, skipping

            if (rowsNeeded + 1 > allRowsForSizeTable.length) {
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < rowsNeeded; i++) {
                const row = new Array(sizesMaxColumn).fill('');
                row[0] = this.$t(`${filtered[i].definition}`);
                allRowsForSizeTable.push(row);
              }
              // at this point we should have the table with header(all size name) and row name(measurement key)
            }
            // fill this measurement value of this size to each key
            filtered.forEach((m, mIndex) => {
              const row = allRowsForSizeTable[mIndex + 1];
              const key = row[0];
              if (key && key === this.$t(`${m.definition}`) && m.value) {
                row[sizeIndex + 1] = UnitConversion.getValueInText(m.value);
              }
            });
            // update customized measurements with filtered list
            sizes[sizeIndex].cms = (cms || []).filter(m => m.visible);
          });
          // determine how many rows needed for customized measurements
          const cmsMaxRows = sizes.reduce((acc, current) => {
            const { cms = [] } = current;
            return acc.length > cms.length ? acc : cms;
          }, []);
          // fill in the value of customized measurements of each size
          cmsMaxRows.forEach(m => {
            const values = sizes.map(size => {
              const target = size.cms.find(c => c && c.key === m.key);
              return target && target.value ? UnitConversion.getValueInText(target.value) : '';
            });
            allRowsForSizeTable.push([m.key, ...values]);
          });
          // fill all other info to the first row accordingly, and pad the rest of the cells with empty value
          const filled = allRowsForSizeTable.map((row, index) => {
            if (index === 0) {
              return [
                date,
                sn,
                name.replace(carriage, ''),
                category,
                ...row,
                characteristics.replace(carriage, ''),
                recommendations.replace(carriage, ''),
                specifications.replace(carriage, ''),
              ];
            }
            return ['', '', '', '', ...row, '', '', ''];
          });
          const rowStart = wsData.length;
          const rowEnd = rowStart + allRowsForSizeTable.length - 1;
          wsData = wsData.concat(filled);
          header.forEach((c, cIndex) => {
            if (cIndex === 4 || !c) return;

            merges.push({ s: { c: cIndex, r: rowStart }, e: { c: cIndex, r: rowEnd } });
          });
        });

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        ws['!cols'] = cols;
        ws['!merges'] = merges;
        ws['!rows'] = [{ hpx: 100 }];
        XLSX.utils.book_append_sheet(wb, ws, this.$t('garment_measure.product_list'));
        const excelFile = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const zipFile = new JSZip();
        const zipFileName = getZipFileName(items);
        zipFile.file(`${zipFileName}/sheet.xlsx`, excelFile);
        await Promise.all(
          Object.keys(photosToDownload).map(async sn => {
            const { image, snapshot } = photosToDownload[sn];
            if (image) {
              try {
                const { data } = await axios.get(image, { responseType: 'arraybuffer' });
                const jpgImage = await this.pngToJpg(data);
                zipFile.file(`${zipFileName}/${sn}/image.jpg`, jpgImage);
              } catch (e) {
                console.log(`${sn} image download error`);
                console.log(e.message);
              }
            }
            if (snapshot) {
              try {
                const { data } = await axios.get(snapshot, { responseType: 'arraybuffer' });
                const jpgImage = await this.pngToJpg(data);
                zipFile.file(`${zipFileName}/${sn}/snapshot.jpg`, jpgImage);
              } catch (e) {
                console.log(`${sn} snapshot download error`);
                console.log(e.message);
              }
            }
          })
        );
        const blob = await zipFile.generateAsync({ type: 'blob' });
        saveAs(blob, `${zipFileName}.zip`);
      } catch (e) {
        console.log(e.message);
        const { response = {}, message } = e;
        this.$toastMessage.info((response.data && response.data.error) || message);
      } finally {
        this.isRequesting = false;
      }
    },
    pngToJpg(file) {
      return new Promise((resolve, reject) => {
        const blob = new Blob([file], { type: 'image/png' });
        // eslint-disable-next-line no-new
        new Compressor(blob, {
          checkOrientation: false,
          convertTypes: ['image/jpg'],
          success: result => {
            resolve(result);
          },
          error: err => {
            reject(err);
          },
        });
      });
    },
  },
  computed: {
    ...mapGetters('labelingTool', ['balance']),
    isListingMerchandise() {
      return this.currentPath && this.currentPath.length > 1 && this.currentProject;
    },
    endPoint() {
      if (this.isListingMerchandise) {
        return `/api/v2/projects/${this.currentProject.id}/merchandises`;
      }
      return '/api/v2/projects';
    },
    realm() {
      return this.isListingMerchandise ? 'merchandises' : 'projects';
    },
    params() {
      if (!this.isListingMerchandise) return { type: 1 };

      return {};
    },
    columns() {
      if (this.items.length === 0) return [];
      if (!this.isListingMerchandise) {
        return MEASUREMENT_PROJECT_COLUMNS;
      }
      return MEASUREMENT_COLUMNS;
    },
    actions() {
      if (!this.isListingMerchandise) return this.measurementActions.filter(a => a.event !== 'export');

      return this.measurementActions;
    },
    topButtonAction() {
      return this.measurementTopButtonAction;
    },
  },
};
</script>
<style lang="scss" scoped>
.home--bg {
  background-color: #ededed;
  height: 100%;
  padding: 0;
}

.import-icon {
  width: 66px;
  height: 44px;
  background: url('../assets/home/btn_import_n.svg') 0 0 no-repeat;
  &:hover {
    background-image: url('../assets/home/btn_import_h.svg');
  }
  &:active {
    background-image: url('../assets/home/btn_import_p.svg');
  }
}
.file-icon {
  width: 44px;
  height: 44px;
  background: url('../assets/home/btn_file_n.svg') 0 0 no-repeat;
  &:hover {
    background-image: url('../assets/home/btn_file_h.svg');
  }
  &:active {
    background-image: url('../assets/home/btn_file_p.svg');
  }
}
</style>
