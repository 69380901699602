export default {
  remember_account: '請在這部電腦記住我的Scanatic™帳號',
  invalid: '帳號或密碼是無效的。',
  suggest_browser: '建議使用Google Chrome瀏覽器',
  account: 'Scanatic™帳號',
  password: '輸入密碼',
  login: '登入',
  logout: '登出',
  actions__edit_view: '編輯/查看',
  actions__edit_export: '輸出',
  actions__remove: '刪除',
  project_name: '專案名稱',
  created_at: '建立時間',
  completion: '完成度',
  add_project: '建立專案',
  item_count: '{count} 個項目',
  data_list: '圖片列表',
  configuration: '專案設定',
  classification_name: '分類名稱',
  labeling_type: '標記類型',
  new_project: '新專案',
  cancel: '取消',
  confirm: '確定',
  skip: '略過',
  no_next: '目前已無下一張圖片需要標記',
  max_labels: '最多10筆',
  classification_updated: '分類名稱已修改',
  classification_added: '已新增分類',
  classification_removed: '已移除分類及其所相關的標記',
  imported: '已匯入',
  labeled: '已標記',
  not_labeled: '尚未標記',
  all: '全部',
  classification__only: '分類',
  classification__bbox: '圈選 & 分類',
  classification__px: '像素選擇 & 分類',
  delete: '刪除',
  check_delete: '確定要刪除？',
  upload_title: '圖片上傳',
  upload_title__done: '圖片上傳完成',
  upload_title__verifying: '正在驗證圖片...',
  upload_btn: '上傳圖片',
  upload_clear: '清空已完成',
  upload_status__success: '上傳成功',
  upload_status__error: '上傳失敗',
  upload_status__uploading: '上傳中',
  upload_status__paused: '等待中',
  upload_status__waiting: '等待中',
  upload_project: '選擇專案',
  should_has_projectTitle: '專案名稱不能為空',
  should_has_tag: '分類不能為空',
  should_has_labelingType: '標記類型不能為空',
  images_repeat: '上傳重複的圖片',
  explorer_field_selection_count: '已選取{count} 個項目',
  cm: '公分',
  inch: '英寸',
  editorial_markers: '編輯標記',
  output_preview: '輸出預覽',
  save: '儲存',
  no_data__single_file: '請選擇僅一個Excel檔案',
  no_data__valid_file: '<strong>檔案格式不支援</strong><br>支援的檔案格式：XLSX',
  no_data__instruction: '請將檔案拖曳到這裡或點選匯入鍵',
  output_text_1: '請先選擇商品照，<br>並於下方區域調整編號位置以及縮圖大小',
  product_photos: '商品照',
  reset: '重置',
  apply: '套用',
  select: '選擇',
  move: '移動',
  zoom_out: '縮小',
  zoom_in: '放大',
  viewer_text_1: '尚未設定標記圖片',
  clear: '清除',
  complete: '完成',
  category: '類別',
  sn: '貨號',
  product_name: '商品名稱',
  set_marker_image: '設定標記圖片',
  no_picture: '尚無圖片',
  leave: '離開',
  current_balance: '剩餘張數',
  selected_pictures: '已選擇的圖片',
  terminate_upload: '終止上傳',
  terminate_upload_text: '照片尚未上傳完成<br>離開後將終止上傳照片',
  projects: '專案',
  folder: '資料夾',
  hide_column: '隱藏列',
  delete_column: '刪除列',
  rename: '重新命名',
  pictures: '圖片',
  show_column: '顯示列',
  no_license_available: '您尚無權限使用本服務，請聯絡本公司業務窗口',
  upload_timeout_title: '上傳逾時',
  upload_timeout: '請重新上傳照片',
  measure_error__unknown_title: '發生未預期錯誤',
  measure_error__unknown: '請確認上傳照片和棋盤格是否符合定義規格再重新上傳。如未獲得改善，請聯絡 TG3D。',
  measure_error__chessboard_size_title: '棋盤格尺寸不符',
  measure_error__chessboard_size:
    '請使用符合定義規格之棋盤格尺寸，確認後再重新拍攝及上傳；如需更新棋盤格尺寸，請聯絡 TG3D。',
  measure_error__clothing_type_title: '服裝類別不符',
  measure_error__clothing_type: '請使用符合定義之服裝類別照片。',
  measure_error__image_size_title: '照片解析度不符',
  measure_error__image_size:
    '解析度不符 4032 X 3024，請確認使用拍攝設備是否符合規格 （iPhone 8+, 11, 11 pro, 12），確認後再重新拍攝及上傳。',
  measure_error__chessboard_detection_title: '無法偵測到棋盤格',
  measure_error__chessboard_detection:
    '請確認上傳照片是否符合規格，棋盤格是否完整入鏡、背景乾淨及拍攝距離適中，並重新拍攝及上傳。',
  measure_error__point_detection_title: '無法偵測棋盤格特徵點',
  measure_error__point_detection:
    '請確認光源均勻無曝光、棋盤格無受其他物件遮擋及拍攝距離適中，確認後再重新拍攝及上傳。',
  filename: '上傳失敗（檔名: {filename}）',
  dismiss: '關閉',
  measure_upload_error__unknown_title: '發生未預期錯誤',
  measure_upload_error__unknown: '請確認網路連線狀態再重新上傳。如未獲得改善，請聯絡 TG3D。',
  vest_length_of_clothing: '衣長',
  vest_width_of_chest: '胸寬',
  vest_length_of_sleeve: '袖襱',
  vest_width_of_hem: '下擺寬',
  vest_width_of_shoulder: '肩寬',
  long_sleeve_top_length_of_clothing: '衣長',
  long_sleeve_top_width_of_chest: '胸寬',
  long_sleeve_top_width_of_cuff: '袖口',
  long_sleeve_top_length_of_sleeve: '袖長',
  long_sleeve_top_width_of_hem: '下擺寬',
  long_sleeve_top_width_of_shoulder: '肩寬',
  long_sleeve_top_width_of_sleeve: '袖襱',
  short_sleeve_top_length_of_clothing: '衣長',
  short_sleeve_top_width_of_chest: '胸寬',
  short_sleeve_top_width_of_cuff: '袖口',
  short_sleeve_top_length_of_sleeve: '袖長',
  short_sleeve_top_width_of_hem: '下擺寬',
  short_sleeve_top_width_of_shoulder: '肩寬',
  short_sleeve_top_width_of_sleeve: '袖襱',
  long_sleeve_outwear_length_of_clothing: '衣長',
  long_sleeve_outwear_width_of_chest: '胸寬',
  long_sleeve_outwear_width_of_cuff: '袖口',
  long_sleeve_outwear_length_of_sleeve: '袖長',
  long_sleeve_outwear_width_of_hem: '下擺寬',
  long_sleeve_outwear_width_of_shoulder: '肩寬',
  long_sleeve_outwear_width_of_sleeve: '袖襱',
  long_sleeve_dress_length_of_clothing: '衣長',
  long_sleeve_dress_width_of_chest: '胸寬',
  long_sleeve_dress_width_of_cuff: '袖口',
  long_sleeve_dress_length_of_sleeve: '袖長',
  long_sleeve_dress_width_of_hem: '下擺寬',
  long_sleeve_dress_width_of_shoulder: '肩寬',
  long_sleeve_dress_width_of_sleeve: '袖襱',
  sling_dress_length_of_clothing: '衣長',
  sling_dress_width_of_chest: '胸寬',
  sling_dress_width_of_hem: '下擺寬',
  skirts_width_of_waist: '腰圍',
  skirts_length_of_skirt: '裙長',
  skirts_width_of_hip: '臀寬',
  skirts_width_of_hem: '下擺寬',
  trouser_length_of_pants: '褲長',
  trouser_width_of_waist: '腰寬',
  trouser_width_of_hip: '臀寬',
  trouser_length_of_pants_crotch: '褲襠長',
  trouser_width_of_thighs: '大腿寬',
  trouser_width_of_pants: '褲口寬',
  shorts_length_of_pants: '褲長',
  shorts_width_of_waist: '腰寬',
  shorts_width_of_hip: '臀寬',
  shorts_length_of_pants_crotch: '褲襠長',
  shorts_width_of_thighs: '大腿寬',
  shorts_width_of_pants: '褲口寬',
  vest_length_of_clothing_definition: '頸點到下擺的長度',
  vest_width_of_chest_definition: '腋下兩側之間的寬度',
  vest_length_of_sleeve_definition: '肩點與腋下之間的長度',
  vest_width_of_hem_definition: '下擺兩側之間的寬度',
  vest_width_of_shoulder_definition: '肩點兩側之間的寬度',
  long_sleeve_top_length_of_clothing_definition: '頸點到下擺的長度',
  long_sleeve_top_width_of_chest_definition: '腋下兩側之間的寬度',
  long_sleeve_top_width_of_cuff_definition: '袖口的寬度',
  long_sleeve_top_length_of_sleeve_definition: '肩點至袖口的長度',
  long_sleeve_top_width_of_hem_definition: '下擺兩側之間的寬度',
  long_sleeve_top_width_of_shoulder_definition: '肩點兩側之間的寬度',
  long_sleeve_top_width_of_sleeve_definition: '肩點與腋下之間的寬度',
  short_sleeve_top_length_of_clothing_definition: '頸點到下擺的長度',
  short_sleeve_top_width_of_chest_definition: '腋下兩側之間的寬度',
  short_sleeve_top_width_of_cuff_definition: '袖口的寬度',
  short_sleeve_top_length_of_sleeve_definition: '肩點至袖口的長度',
  short_sleeve_top_width_of_hem_definition: '下擺兩側之間的寬度',
  short_sleeve_top_width_of_shoulder_definition: '肩點兩側之間的寬度',
  short_sleeve_top_width_of_sleeve_definition: '肩點與腋下之間的寬度',
  long_sleeve_outwear_length_of_clothing_definition: '頸點到下擺的長度',
  long_sleeve_outwear_width_of_chest_definition: '腋下兩側之間的寬度',
  long_sleeve_outwear_width_of_cuff_definition: '袖口的寬度',
  long_sleeve_outwear_length_of_sleeve_definition: '肩點至袖口的長度',
  long_sleeve_outwear_width_of_hem_definition: '下擺兩側之間的寬度',
  long_sleeve_outwear_width_of_shoulder_definition: '肩點兩側之間的寬度',
  long_sleeve_outwear_width_of_sleeve_definition: '肩點與腋下之間的寬度',
  long_sleeve_dress_length_of_clothing_definition: '頸點到下擺的長度',
  long_sleeve_dress_width_of_chest_definition: '腋下兩側之間的寬度',
  long_sleeve_dress_width_of_cuff_definition: '袖口的寬度',
  long_sleeve_dress_length_of_sleeve_definition: '肩點至袖口的長度',
  long_sleeve_dress_width_of_hem_definition: '下擺兩側之間的寬度',
  long_sleeve_dress_width_of_shoulder_definition: '肩點兩側之間的寬度',
  long_sleeve_dress_width_of_sleeve_definition: '肩點與腋下之間的寬度',
  sling_dress_length_of_clothing_definition: '衣服最頂點至下擺的長度',
  sling_dress_width_of_chest_definition: '腋下兩側之間的寬度',
  sling_dress_width_of_hem_definition: '下擺兩側之間的寬度',
  skirts_width_of_waist_definition: '腰頭兩側的寬度',
  skirts_length_of_skirt_definition: '腰頭至裙擺的長度',
  skirts_width_of_hip_definition: '腰頭以下約 15cm 處的平量寬度',
  skirts_width_of_hem_definition: '裙擺兩側的寬度',
  trouser_length_of_pants_definition: '腰頭至褲口的長度',
  trouser_width_of_waist_definition: '腰頭兩側的寬度',
  trouser_width_of_hip_definition: '褲頭至褲襠二分之一處的平量寬度',
  trouser_length_of_pants_crotch_definition: '腰頭至褲襠接線處的長度',
  trouser_width_of_thighs_definition: '褲襠下的寬度',
  trouser_width_of_pants_definition: '褲口的寬度',
  shorts_length_of_pants_definition: '腰頭至褲口的長度',
  shorts_width_of_waist_definition: '腰頭兩側的寬度',
  shorts_width_of_hip_definition: '褲頭至褲襠二分之一處的平量寬度',
  shorts_length_of_pants_crotch_definition: '腰頭至褲襠接線處的長度',
  shorts_width_of_thighs_definition: '褲襠下的寬度',
  shorts_width_of_pants_definition: '褲口的寬度',
  legging_waistband_height: '腰帶高度',
  legging_front_rise_including_wb: '前襠長(含腰帶)',
  legging_back_rise_including_wb: '後襠長(含腰帶)',
  legging_waist_width: '腰寬',
  legging_hip_width: '臀寬',
  legging_inseam: '內腿長',
  legging_thigh_width: '大腿寬 (襠下 1 英吋)',
  legging_knee_width: '膝寬 (襠下 13 英吋',
  legging_calf_width: '小腿寬 (從膝蓋向下 5 英吋)',
  legging_leg_opening: '褲口寬',
  legging_waistband_height_definition: '腰帶的高度',
  legging_front_rise_including_wb_definition: '前腰頭頂端到褲襠接線處的長度',
  legging_back_rise_including_wb_definition: '後腰頭頂端到褲襠接線處的長度',
  legging_waist_width_definition: '腰頂的寬度',
  legging_hip_width_definition: '從腰帶兩側向下量測 9 1/2 英吋，連接臀部點的長度 ',
  legging_inseam_definition: '從褲襠接線處到內側褲口的直線長度',
  legging_thigh_width_definition: '褲襠向下 1 英吋處的平量寬度',
  legging_knee_width_definition: '褲襠向下 13 英吋處的平量寬度',
  legging_calf_width_definition: '膝下 5 英吋處的平量寬度',
  legging_leg_opening_definition: '褲口的寬度',
  garment_measure: {
    excel_invalid_title: 'Excel 內容格式不支援',
    excel_invalid: '請使用範例表格編輯後再重新上傳',
    excel_unsupported_format_title: '檔案格式不支援',
    excel_unsupported_format: '支援的檔案格式: XLSX',
    excel_header_date: '日期',
    excel_header_item: '貨號',
    excel_header_name: '商品名稱',
    excel_header_category: '類別',
    excel_header_size: '尺寸',
    excel_header_product_features: '商品特色：\n1.設計重點/商品風格\n2.版型\n3.材質\n4.尺寸建議',
    excel_header_matching_suggestions: '搭配建議：\n1.風格\n2.推薦品\n3.相似品\n4.更多“品牌”商品',
    excel_header_product_specifications: '商品規格：\n1.產地\n2.材質\n',
    root_path: '首頁',
    import_excel: '從 Excel 匯入(.xlsx)',
    download_template: '下載範例表格',
    excel_template: 'Scanatic For AI template.xlsx',
    excel_file_path: 'garment_measure_template_zh.xlsx',
    product_list: '商品列表',
    explorer__field__name: '名稱',
    explorer__field__category: '類別',
    explorer__field__total: '件數',
    explorer__field__uploaded: '已上傳',
    explorer__field__uploadAt: '最後編輯',
    no_pictures_marked_yet: '尚未標記任何圖片',
    previous: '上一件',
    next: '下一件',
    rename_manual_measurements: '重新命名手工量測',
    add_manual_measurements: '新增手工量測',
    manual_measurement_name: '手工量測名稱',
    manual_measurement_definition: '手工量測定義',
    manual_measurement: '手工量測',
    rotation: '旋轉',
    measure_tips_text_1: '參考左上角提示依序完成標記',
    measure_tips_text_2: '在圖上標記完成手工量測',
    measure_tips_text_3: '旋轉角度會影響標記位置，按下確認後將會清除先前所有標記的資料',
    measure_tips_text_4: '提示',
    measure_tips_text_5: '確定清除目前所有標記？',
    measure_tips_text_6: '清除標記',
    measure_tips_text_7: '確定清除目前手工標記？',
    measure_tips_text_8: '清除手工標記',
    measure_tips_text_9: '有標記尚未編輯完成<br/>是否要儲存此次編輯？',
    measure_tips_text_10: '離開手工量測',
    measure_tips_text_11: '標記尚未完成<br/>離開後將清除目前所有標記',
    measure_tips_text_12: '離開標記',
    measure_tips_text_13: '有標記尚未編輯完成<br/>是否要儲存此次編輯？',
    measure_tips_text_14: '編輯標記',
    set_product_screenshot: '請先至左側設定商品截圖',
    size: '尺寸',
    select_image_tip_1: '請選擇至少一張圖片',
    select_image_tip_2: '糟糕！您的剩餘張數不足請聯絡您的業務窗口以完成加值程序',
    select_image_tip_3: '剩餘張數不足',
    select_image_tip_4: '成功上傳',
    success_total: '{count} 張',
    select_image_tip_5: '剩餘張數',
    select_image_tip_6: '你確定要將<br>{fileName}刪除嗎？',
    select_image_tip_7: '刪除照片',
    select_image_tip_8: '取消選取將會刪除此照片標記資料<br/>是否取消選取？',
    select_image_tip_9: '取消選取',
    image_posing_tips: '開始標記前，請先確認圖片是否擺正',
    size_tagging_complete: '尺寸 {size} 標記完成',
  },
};
