<template>
  <v-snackbar
    v-model="show"
    class="wrapper"
    :class="{ 'tw-fixed !tw-transform tw--translate-x-1/2 !tw-m-0': left, 'has-title': title }"
    bottom
    :left="direction === 'left'"
    :right="direction === 'right'"
    :timeout="timeout"
    :style="{ bottom: `${bottom}px`, left: `${left}px` }"
  >
    <span v-if="title" class="message-title tw-font-bold" v-html="title"></span>
    <span v-html="text"></span>
  </v-snackbar>
</template>

<script>
const TIMEOUT = 5000;

export default {
  name: 'Snackbar',
  data: () => ({
    show: false,
    timeout: TIMEOUT,
    title: '',
    text: '',
    bottom: 56,
    left: null,
    direction: null,
  }),
  watch: {
    show(nv) {
      if (!nv) {
        this.title = '';
        this.text = '';
        this.bottom = 56;
        this.left = null;
        this.direction = null;
      }
    },
  },
  methods: {
    open(message, bottom = 56, direction, timeout) {
      const displaying = this.show;
      this.close();
      this.timeout = timeout || TIMEOUT;
      this.bottom = bottom;
      if (typeof message === 'string') {
        this.text = message;
      } else if (message.title) {
        this.title = message.title;
        this.text = message.text;
      }
      if (direction) {
        const position = window.innerWidth / 4;
        this.direction = direction;
        if (direction === 'left') {
          this.left = position;
        }
        if (direction === 'right') {
          this.left = position * 3;
        }
      }
      if (displaying) {
        setTimeout(() => {
          this.show = true;
        }, 800);
      } else {
        this.show = true;
      }
    },
    close() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  transform: var(--tw-transform) !important;
  margin: 0 auto !important;
  transition: none !important;
  z-index: 202;

  &.has-title {
    ::v-deep.v-snack__content {
      height: auto;
      padding: 9px 12px;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  ::v-deep.v-snack__wrapper {
    width: auto;
    max-width: initial;
    min-width: initial;
    border: 1px solid rgb(160, 160, 160);
    border-radius: 5px;
    background-color: rgb(64, 64, 64);
    box-shadow: 7px 7px 14px -4px rgba(0, 0, 0, 0.7);
    margin: 0 auto !important;
  }
  ::v-deep.v-snack__content {
    height: auto;
    padding: 9px 12px;
  }
}
</style>
