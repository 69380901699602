<template>
  <v-dialog
    :value="showDialog"
    persistent
    no-click-animation
    hide-overlay
    fullscreen
    content-class="tw-bg-white tw-relative"
  >
    <div class="root tw-relative tw-w-full tw-h-full tw-p-0 tw-bg-white tw-overflow-hidden">
      <!-- HEAD -->
      <v-layout class="dialog-head tw-w-full tw-pt-5 tw-bg-black tw-text-white">
        <v-layout class="head-info" align-baseline>
          <slot name="header-title"></slot>
        </v-layout>
        <div class="close tw-fixed tw-cursor-pointer" @click="$emit('close')" :title="$t('cancel')"></div>
      </v-layout>
      <!-- Body -->
      <v-layout class="content">
        <!-- Preview -->
        <v-flex class="preview tw-relative xs6">
          <div ref="previewArea" class="portal tw-w-full tw-h-full">
            <v-tabs-items :value="tab" class="tabs-items tw-h-full">
              <v-tab-item class="tw-h-full">
                <slot name="viewer-controller" :tab="tab" />
              </v-tab-item>
              <v-tab-item class="tw-h-full">
                <slot name="output-preview" :tab="tab" />
              </v-tab-item>
            </v-tabs-items>
          </div>
          <v-tabs :value="tabController" class="tab-container tw-absolute tw-h-full tw-top-0 tw-right-0" hide-slider>
            <v-tab
              v-for="(item, i) in tabs"
              class="tab"
              :class="[`${item.key}-tab`, { 'active-tab': tab === i }]"
              :key="i"
              @click="changeTab(i)"
            >
              <div class="btn-wrap tw-mx-auto tw-my-0" :style="getTabBackgroundImage(item.imagePath)" />
              <div class="tab-text tw-relative tw-mx-auto tw-my-0 tw-text-center tw-opacity-0">
                {{ item.name || '' }}
              </div>
            </v-tab>
          </v-tabs>
        </v-flex>
        <!-- Configuration -->
        <v-flex class="xs6 tw-h-full">
          <v-tabs-items :value="tab" class="tabs-items tw-h-full">
            <v-tab-item class="tw-h-full">
              <slot name="fisrt-item" :tab="tab" />
            </v-tab-item>
            <v-tab-item class="tw-h-full">
              <slot name="second-item" :tab="tab" />
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
      <!-- Edit actions -->
      <action-buttons
        hide-btns-on-save
        :disable-cancel="disableCancel"
        :disable-confirm="disableConfirm"
        :cancel-title="cancelTitle"
        :confirm-title="confirmTitle"
        @cancel="$emit('cancel')"
        @confirm="$emit('save')"
      />
      <slot />
    </div>
  </v-dialog>
</template>

<script>
import ActionButtons from './ActionButtons';

export default {
  name: '',
  props: {
    tabs: Array,
    showDialog: Boolean,
    isEdited: Boolean,
    cancelTitle: String,
    confirmTitle: String,
    disableCancel: Boolean,
    disableConfirm: Boolean,
  },
  components: {
    ActionButtons,
  },
  data: () => ({
    tab: 0,
    tabController: 0,
  }),
  watch: {
    showDialog(nv) {
      if (nv) {
        this.tab = 0;
      }
    },
    tab(nv) {
      this.tabController = nv;
    },
  },
  methods: {
    getTabBackgroundImage(path) {
      return { 'background-image': `url(${path})` };
    },
    async changeTab(index) {
      if (index === 1) {
        const result = new Promise(res => {
          this.$emit('change-event', res);
        });
        await result.then(res => {
          if (typeof res === 'boolean' && !res) {
            this.$nextTick(() => {
              this.tab = 0;
            });
          } else {
            this.tab = index;
          }
        });
      } else {
        this.tab = index;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.root {
  color: #4a4a4a;
}
.close {
  left: 20px;
  top: 20px;
  width: 18px;
  height: 18px;
  background: url('../../assets/home/ic-close-n.svg') no-repeat center;
  background-size: contain;
  &:hover {
    background-image: url('../../assets/home/ic-close-p.svg');
  }
}
.dialog-head {
  height: 72px;
}
.head-info {
  margin-left: 200px;
  max-width: 1250px;
}
.content {
  height: calc(100% - 72px);
}

.preview {
  border-right: 1px solid rgb(196, 196, 196);
}

.tab-container {
  z-index: 1;
}
.tab {
  height: 100px;
  .btn-wrap:hover {
    transform: scale(1.5);
  }
}
.btn-wrap {
  width: 72px;
  height: 72px;
  background-size: cover;
  transition: transform 0.3s;
}
.tab-text {
  transition: opacity 0.3s;
  width: max-content;
  &:after {
    position: absolute;
    transition: width 0.3s, left 0.3s;
    content: '';
    width: 0;
    left: 50%;
    bottom: -3px;
    height: 3px;
    background: #2dbdcb;
  }
}

::v-deep.tab-container {
  .v-tabs__container {
    @apply tw-h-1/5;
    margin: auto 0;
    justify-content: space-between;
    flex-direction: column;
  }
  .v-tabs__bar {
    height: 100%;
    background: none;
  }
  .v-tabs__wrapper {
    height: 100%;
  }
  .active-tab {
    .v-tabs__item {
      opacity: 1;
    }
    .btn-wrap {
      transform: scale(1.5);
    }
    .tab-text {
      opacity: 1;
      &:after {
        width: 100%;
        left: 0;
      }
    }
  }
  .v-tabs__item {
    display: block;
    font-size: 14px;
    color: #4a4a4a;
  }
  .v-ripple__container {
    display: none !important;
  }
}
.tabs-items {
  ::v-deep {
    .v-window__container {
      height: 100%;
    }
  }
}
</style>
