const MENU = [
  { kind: 1000, name: 'ML Label', url: '/ml_label', icon: 'ml-tool' },
  { kind: 9000, name: 'Garment Measure', url: '/garment_measure', icon: 'garment-measure' },
];

export const MenuLicenses = () => {
  const licenses = JSON.parse(localStorage.getItem('licenses'));
  if (!licenses) return [];
  return MENU.filter(item => {
    return licenses.some(data => data.kind === item.kind);
  });
};

export const CheckHasLicenses = route => {
  const menuLicenses = MenuLicenses();

  if (menuLicenses.length === 0) {
    localStorage.removeItem('id_token');
    localStorage.removeItem('licenses');
    localStorage.removeItem('account');
    return { path: '/login' };
  }
  const hasLicenses = menuLicenses.some(item => item.url === route.path);
  if (hasLicenses) {
    return { path: route.path };
  }
  return { path: menuLicenses[0].url };
};
