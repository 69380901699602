const getUseInch = () => JSON.parse(localStorage.getItem('useInch'));

const unitConversion = {
  useInch: getUseInch() || false,
  updateUseInch() {
    this.useInch = getUseInch();
  },
  setUseInch(value) {
    if (value === this.useInch) return;
    localStorage.setItem('useInch', value);
    this.updateUseInch();
  },
  toInch(value) {
    return Number(value) * 0.393700787;
  },
  toFixed(value) {
    return parseFloat(Number(value).toFixed(2));
  },
  switchUnits(value) {
    if (this.useInch) {
      return this.toFixed(this.toInch(value));
    }
    return this.toFixed(value);
  },
  getUnit() {
    return this.useInch ? 'inch' : 'cm';
  },
  getValueInText(value) {
    return `${this.switchUnits(value)} ${this.getUnit()}`;
  },
};

export default unitConversion;
