<template>
  <div class="product-landing-container signin-page">
    <div class="logo">
      <img class="icon-logo" src="../assets/logo.svg" alt="TG3D Studio" />
    </div>
    <div class="content pb-5 mb-5">
      <div class="form-head">
        <div class="product-name"><img class="icon-scanatic" src="../assets/scanatic.svg" alt="Scanatic" />for AI</div>
        <div class="tagline">Digitally Empowering Fashion</div>
      </div>
      <form class="form">
        <div v-if="loginError" class="field alert-warning">{{ $t('invalid') }}</div>
        <div class="field">
          <v-text-field
            type="text"
            v-model="user.account"
            autofocus
            color="#fff"
            class="text-field"
            :placeholder="$t('account')"
            id="account"
            @keyup.enter="submitFrom"
          />
        </div>
        <div class="field">
          <v-text-field
            color="white"
            autocomplete="off"
            class="text-field"
            :placeholder="$t('password')"
            :type="passwordRevealed ? 'text' : 'password'"
            v-model="user.password"
            id="password"
            @keyup.enter="submitFrom"
          />
          <span class="icon-reveal" :class="{ active: passwordRevealed }" @click="revealPassword"></span>
        </div>
        <div class="field">
          <t-check-box v-model="rememberMe">
            <div class="tw-text-white tw-text-[14px]" slot="label">{{ $t('remember_account') }}</div>
          </t-check-box>
        </div>
        <div class="actions signin-btn-container">
          <v-btn :disabled="isRequesting" class="white--text" light color="#00BCD4" @click="submitFrom">{{
            $t('login')
          }}</v-btn>
        </div>
      </form>
    </div>

    <div class="footer">{{ $t('suggest_browser') }}</div>
  </div>
</template>

<script>
import { MenuLicenses } from '../modules/MenuLicenses';
import TCheckBox from '../components/common/TCheckBox';

export default {
  components: {
    TCheckBox,
  },
  created() {
    this.$http.defaults.headers.common.Authorization = null;
    localStorage.removeItem('id_token');
  },
  mounted() {
    this.rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
    const account = localStorage.getItem('rememberedAccount');
    if (account) {
      this.$set(this.user, 'account', account);
    }
  },
  data() {
    return {
      user: {
        account: '',
        password: '',
      },
      passwordRevealed: false,
      loginError: false,
      isRequesting: false,
      rememberMe: false,
    };
  },
  watch: {
    rememberMe(nv) {
      if (nv) {
        localStorage.setItem('rememberedAccount', this.user.account);
      } else {
        localStorage.removeItem('rememberedAccount');
      }
      localStorage.setItem('rememberMe', nv);
    },
    'user.account'(nv) { //eslint-disable-line
      if (this.rememberMe) {
        localStorage.setItem('rememberedAccount', nv);
      }
    },
  },
  methods: {
    revealPassword() {
      this.passwordRevealed = !this.passwordRevealed;
    },
    async submitFrom() {
      if (this.isRequesting) return;

      this.loginError = false;
      this.isRequesting = true;
      try {
        const { data } = await this.$http.post('/api/v1/login', this.user);
        localStorage.setItem('id_token', data.token);
        localStorage.setItem('licenses', JSON.stringify(data.licenses));
        localStorage.setItem('account', this.user.account);
        this.$http.defaults.headers.common.Authorization = `Bearer ${data.token}`;
        const menuLicenses = MenuLicenses();
        if (!Array.isArray(menuLicenses) || menuLicenses.length === 0) {
          this.$snackbar.alert(this.$t('no_license_available'));
        } else {
          this.$router.push({ path: menuLicenses[0].url });
        }
      } catch (e) {
        const { response } = e;
        const { status } = response || {};
        if (status >= 400 && status < 500) {
          this.loginError = true;
        } else {
          this.$snackbar.alert(e.message);
        }
      } finally {
        this.isRequesting = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.product-landing-container {
  height: 100vh;
  min-height: 750px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: url('../assets/landing-bg.jpg') black no-repeat;
  position: relative;
  background-size: cover;
  font-family: 'Roboto', serif;
  .icon-logo {
    width: 74.8px;
    height: 110px;
    outline: none;
    cursor: initial;
  }

  .content {
    color: white;
    max-width: 558px;
    width: 90%;
    .primary-color-link {
      text-decoration: underline;
    }
  }

  .text-field {
    padding-top: 0;
  }

  .form-head {
    padding: 0 20px;
  }

  .product-name {
    font-size: 36px;
    font-weight: 300;
    display: flex;
  }

  .icon-scanatic {
    max-width: 201px;
    max-height: 39px;
    width: 100%;
    margin-right: 10px;
  }

  .tagline {
    text-align: right;
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
  }

  .alert-warning {
    margin: 0 20px;
  }

  .form {
    margin: 20px auto;
    padding: 20px 0;
    border-radius: 14px;
    background-color: rgba(255, 255, 255, 0.11);
    .tg3d-input > input {
      border-bottom-color: white;
      color: white;
      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }
    .md-button {
      text-transform: none;
    }
  }

  .alert-warning {
    padding: 15px;
    font-size: 16px;
    background-color: #fcf8e3;
    color: #8a6d3b;
  }
  .field {
    margin: 20px;
    position: relative;
    ::v-deep .v-input {
      &.error--text {
        .v-input__slot {
          &::before {
            border-color: #ff5252;
          }
          &:hover {
            &::before {
              border-color: #ff5252;
            }
          }
        }
      }
      .v-input__slot {
        &::before {
          border-color: white;
        }
        &:hover {
          &::before {
            border-color: white;
          }
        }
      }
      input {
        color: white;
        /*border-bottom-color: white;*/
        &::placeholder {
          color: white;
          opacity: 0.5;
        }
      }
    }
  }

  .icon-reveal {
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 16px;
    top: 9px;
    right: 5px;
    cursor: pointer;
    outline: none;
    background: url('../assets/ic-reveal.svg') no-repeat center;
    &:hover,
    &:active {
      background-image: url('../assets/ic-reveal-h.svg');
    }
    &.active {
      background-image: url('../assets/ic-reveal-off.svg');
      &:hover,
      &:active {
        background-image: url('../assets/ic-reveal-off-h.svg');
      }
    }
  }

  .activation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #1ac5d4;
    .or {
      text-transform: uppercase;
      margin-bottom: 15px;
    }
  }

  .footer {
    font-size: 12px;
    font-weight: 300;
    color: #fff;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
}

@media screen and (max-width: 400px) {
  .product-landing-container {
    .product-name {
      font-size: 28px;
    }

    .icon-scanatic {
      width: 161px;
      height: 31px;
    }
    md-checkbox {
      .md-label {
        line-height: 18px;
      }
    }
  }
}

@media screen and (min-width: 800px) {
  .product-landing-container {
    .form {
      padding: 20px;
    }
    .field,
    .alert-warning {
      margin: 20px 50px;
    }
    .activation {
      .or {
        margin-bottom: 30px;
      }
    }
    .alert-warning {
      margin-top: 10px;
    }
  }
}

.form-container {
  width: 100%;
}

.signin-msg-title {
  max-width: 640px;
  margin: 0 auto 47px auto;
  text-align: center;
  font-size: 1.375em;
  font-weight: 300;
  color: 000;
}

.signin-msg {
  max-width: 640px;
  margin: 20px auto 47px auto;
  text-align: center;
  font-size: 1em;
  font-weight: 300;
  color: #4cb6c0;
}

.signin-form-container {
  max-width: 494px;
  margin: 0 auto;
}

.signin-btn-container {
  margin-top: 60px;
  width: 100%;
  text-align: center;
  .v-btn {
    font-size: 1em;
    min-width: 142px;
    min-height: 32px;
    border-radius: 0;
    height: 32px;
    line-height: 32px;
  }
}

.activate-barcode-img {
  margin: 0px auto;
  text-align: center;
}

.have-account-link > a {
  text-decoration: none;
  border-bottom: 2px solid #000;
  color: #000 !important;
}

.activate-subtitle {
  color: #767676;
  margin-bottom: 15px;
  font-size: 1em;
  font-weight: 300;
}

.activate-preset-value {
  color: #000;
  font-size: 1em;
  font-weight: 300;
}

.store-setting-shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0px 6px;
}

.high-light-border {
  border-radius: 8px;
  border: 8px solid #1ac5d4;
}

.store-setting-hint {
  margin: 0px auto;
  text-align: center;
  color: #1ac5d4;
  font-size: 1.25em;
  font-weight: 300;
}

.privacy-msg-container {
  max-width: 460px;
  margin: 40px auto;
  font-size: 12px;
  color: #b3b3b1;
  line-height: 1.4;
}

.reset-password-hint {
  color: #767676;
  font-size: 1em;
  font-weight: 300;
}

/* override margin settings in sign-in page */
.signin-page {
}
.signin-page .signin-msg-title {
  margin: 0px auto;
}
.signin-page .logo-container {
  margin: 0px;
}
.signin-page .signin-form-container {
}
.signin-page .signin-btn-container {
  margin-top: 0px;
}

/* override margin settings in prepare.html.erb */
.prepare-page .signin-msg-title {
  margin: 0px auto;
}
.prepare-page .logo-container {
  margin: 0px;
}
@media screen and (min-width: 330px) and (max-width: 649px) {
  .prepare-page .msg-1,
  .prepare-page .msg-2 {
    font-size: 12px;
  }
}
@media screen and (max-width: 329px) {
  .prepare-page .msg-1,
  .prepare-page .msg-2 {
    font-size: 12px;
  }
}
</style>
