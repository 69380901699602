<template>
  <div v-if="tab === 1" ref="wrapper" class="export-mode">
    <template v-if="selectSizesList.length > 0">
      <k-stage ref="preview" :config="stageConfig">
        <k-layer>
          <k-group ref="previewGroup" :config="{ ...previewLayerConfig, scaleX: imageScale, scaleY: imageScale }">
            <k-image :config="{ image: this.imageEl, ...imageConfig }" />
          </k-group>
        </k-layer>
      </k-stage>
      <div class="image-filter" />
      <k-stage
        ref="stage"
        class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full"
        :class="{ 'tw-cursor-move': focusBtn === 'move' }"
        :config="stageConfig"
      >
        <k-layer ref="layer" :config="layerConfig">
          <k-rect :config="{ ...stageConfig, fill: 'white' }" />
          <k-group
            ref="editorImage"
            :config="{ ...groupClipConfig, scaleX: imageScale, scaleY: imageScale }"
            @dragmove="onDragmove"
          >
            <k-image :config="{ image: grayscaleImageEl, ...imageConfig }" />
            <k-image ref="image" :config="{ image: imageEl, ...imageConfig }" />
            <k-group ref="measurementsGroup" :config="measurementsGroupConfig">
              <k-group
                v-for="(item, index) in measurementsGroupList"
                :key="`circle-${index}`"
                :config="item.groupConfig"
              >
                <k-line :config="item.lineConfig" />
                <k-circle v-for="(circle, index) in item.circleAnchor" :config="circle" :key="`${index}-${circle.x}`" />
                <k-group
                  :ref="`hintPoint${index + 1}`"
                  :config="{ ...item.hintGroup, scaleX: restoreScaling, scaleY: restoreScaling }"
                  @mouseenter="onMouseenter(`hintCircle${index + 1}`)"
                  @mouseleave="onMouseleave(`hintCircle${index + 1}`)"
                  @mouseout="updateAnchorToPreview"
                >
                  <k-circle :ref="`hintCircle${index + 1}`" :config="item.controlAnchor" />
                  <k-text :config="item.measurementIndex" />
                </k-group>
              </k-group>
              <template v-for="(item, index) in customizedMeasurementsGroupList">
                <k-group :key="`customized-circle-${index}`" :config="item.groupConfig">
                  <k-line :config="item.lineConfig" />
                  <k-circle v-for="circle in item.circleAnchor" :config="circle" :key="circle.x" />
                  <k-group
                    :ref="`customizedHintPoint${index + 1}`"
                    :config="{ ...item.hintGroup, scaleX: restoreScaling, scaleY: restoreScaling }"
                    @mouseenter="onMouseenter(`customizedHintCircle${index + 1}`)"
                    @mouseleave="onMouseleave(`customizedHintCircle${index + 1}`)"
                    @mouseout="updateAnchorToPreview"
                  >
                    <k-circle :ref="`customizedHintCircle${index + 1}`" :config="item.controlAnchor" />
                    <k-text :config="item.measurementIndex" />
                  </k-group>
                </k-group>
              </template>
            </k-group>
          </k-group>
        </k-layer>
      </k-stage>
      <div class="tw-absolute tw-top-52 2xl:tw-top-72 tw-left-0 tw-pl-[24px] tw-flex tw-flex-col">
        <v-tooltip v-for="item in toolBtnList" :key="item.key" right class="tw-mb-4 tw-cursor-pointer">
          <div
            slot="activator"
            class="tool-icon"
            :class="[`${item.key}-icon`, { focus: focusBtn === item.key }]"
            v-longpress="item.method"
          />
          <span>{{ item.name }}</span>
        </v-tooltip>
      </div>
      <div class="tw-flex tw-flex-col tw-items-center tw-absolute tw-top-0 tw-left-0 tw-w-full tw-pt-6">
        <div class="tw-text-black tw-text-center" v-html="$t('output_text_1')" />
        <div class="tw-flex tw-justify-center tw-items-center tw-text-black">
          <v-select
            class="sizes-select"
            v-model="activeSize"
            :items="selectSizesList"
            outline
            hide-details
            single-line
          />
        </div>
      </div>
      <action-buttons
        absolute
        :no-padding="true"
        :no-shadow="true"
        :no-background-color="true"
        :btn-width="80"
        :cancel-title="$t('reset')"
        :confirm-title="$t('apply')"
        @cancel="reset"
        @confirm="complete"
      />
    </template>
    <template v-else>
      <div class="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center tw-bg-[#C4C4C4]">
        <span class="tw-text-[24px]">{{ $t('garment_measure.no_pictures_marked_yet') }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { getNearestPointToALine } from '@/modules/utils';
import ActionButtons from '../../common/ActionButtons';

export default {
  name: 'EditOutputPreview',
  components: {
    ActionButtons,
  },
  props: {
    merchandiseId: String,
    tab: [String, Number],
  },
  data: vm => ({
    ready: false,
    position: { x: 0, y: 0 },
    activeSize: '',
    rotation: 0,
    imageEl: null,
    grayscaleImageEl: null,
    imageScale: 0.7,
    imageSize: { width: 0, height: 0 },
    stageConfig: { width: 0, height: 0 },
    visualEditingAreaSize: { width: 486, height: 423 },
    measurementsGroupList: [],
    customizedMeasurementsGroupList: [],
    groupClipConfig: {},
    layerConfig: {},
    previewLayerConfig: {},
    imageConfig: {},
    focusBtn: 'move',
    isEditing: false,
    measurementsGroupConfig: {},
    toolBtnList: [
      { key: 'move', name: vm.$t('move'), method: vm.toolMove },
      { key: 'zoomIn', name: vm.$t('zoom_in'), method: vm.toolZoomIn },
      { key: 'zoomOut', name: vm.$t('zoom_out'), method: vm.toolZoomOut },
    ],
  }),
  computed: {
    ...mapGetters('labelingTool/measurementTool', ['sizesList', 'categoriesMeasurements', 'customizedMeasurements']),
    visibleMeasurements() {
      return this.categoriesMeasurements.filter(item => item.visible);
    },
    restoreScaling() {
      return 1 / this.imageScale;
    },
    visibleSizesList() {
      const cloneSizesList = cloneDeep(this.sizesList);
      const filterSizes = cloneSizesList.filter(item => {
        return item.measurements.length > 0 && item.measurements.every(data => data.value > 0);
      });
      this.customizedMeasurements.forEach(item => {
        item.sizes.forEach(data => {
          const index = cloneSizesList.findIndex(s => s.name === data.name);
          if (data.value > 0 && !filterSizes.some(s => s.name === data.name)) {
            filterSizes.push(cloneSizesList[index]);
          }
        });
      });
      filterSizes.forEach(item => {
        const visibleMeasurements = item.measurements.filter(data => data.visible);
        this.$set(item, 'measurements', visibleMeasurements);
      });
      return filterSizes;
    },
    selectSizesList() {
      const sizeList = this.visibleSizesList.map(item => item.name);
      return sizeList;
    },
    activeSizeData() {
      return this.visibleSizesList.filter(item => item.name === this.activeSize)[0];
    },
  },
  watch: {
    activeSize(nv) {
      if (nv) {
        this.$nextTick(async () => {
          this.$loadingSpinner.open();
          this.isEditing = false;
          const activeData = this.activeSizeData;
          const children = this.$refs.previewGroup.getNode().getChildren();
          if (children[1]) {
            children[1].remove();
          }
          this.imageScale = activeData.image && activeData.image.zoom > 0 ? Number(activeData.image.zoom) : 0.7;
          this.rotation = activeData.image ? activeData.image.rotation : 0;
          await this.loadImageToKonva(activeData.image);
          this.getWrapperSize();
          await this.initializekonvaConfig();
          await this.setAnchor(activeData.landmarks);
          await this.updateAnchorToPreview();
          this.$refs.stage.getNode().batchDraw();
          this.$loadingSpinner.close();
        });
      }
    },
    tab(nv) {
      if (nv === 1) {
        const defaultSize = this.visibleSizesList.find(s => s.isDefault);
        if (defaultSize) {
          this.activeSize = defaultSize.name;
        } else {
          this.activeSize = this.selectSizesList[0] || '';
        }
      } else {
        this.activeSize = '';
        this.measurementsGroupList = [];
        this.customizedMeasurementsGroupList = [];
        this.imageEl = null;
        this.grayscaleImageEl = null;
      }
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.getWrapperSize();
      this.initializekonvaConfig();
    });
  },
  beforeDestroy() {
    window.addEventListener('resize', () => {
      this.getWrapperSize();
      this.initializekonvaConfig();
    });
  },
  methods: {
    ...mapMutations('labelingTool/measurementTool', ['SET_SIZES_LIST', 'SET_CUSTOMIZED_MEASUREMENTS']),
    getWrapperSize() {
      const dom = this.$refs.wrapper || { offsetWidth: 0, offsetHeight: 0 };
      const offsetWidth = dom.offsetWidth || 0;
      const offsetHeight = dom.offsetHeight || 0;
      this.stageConfig.width = offsetWidth;
      this.stageConfig.height = offsetHeight;
      this.visualEditingAreaSize.width = offsetWidth * 0.68;
      this.visualEditingAreaSize.height = this.visualEditingAreaSize.width * 0.888;
    },
    initializekonvaConfig() {
      const { width: areaWidth, height: areaHeight } = this.visualEditingAreaSize;
      let containerWidth;
      let containerHeight;
      if ((this.rotation <= 95 && this.rotation >= 85) || (this.rotation <= 275 && this.rotation >= 265)) {
        containerWidth = this.imageSize.height;
        containerHeight = this.imageSize.width;
      } else {
        containerWidth = this.imageSize.width;
        containerHeight = this.imageSize.height;
      }
      this.layerConfig = {
        clipX: (this.stageConfig.width - areaWidth) / 2,
        clipY: (this.stageConfig.height - areaHeight) / 2,
        clipWidth: areaWidth,
        clipHeight: areaHeight,
      };
      this.groupClipConfig = {
        width: this.imageSize.width,
        height: this.imageSize.height,
        x: this.stageConfig.width / 2,
        y: this.stageConfig.height / 2,
        offsetX: this.imageSize.width / 2,
        offsetY: this.imageSize.height / 2,
        draggable: true,
      };
      this.imageConfig = {
        width: this.imageSize.width,
        height: this.imageSize.height,
        x: this.imageSize.width / 2,
        y: this.imageSize.height / 2,
        offsetX: this.imageSize.width / 2,
        offsetY: this.imageSize.height / 2,
        rotation: this.rotation,
      };
      this.previewLayerConfig = {
        width: this.imageSize.width,
        height: this.imageSize.height,
        x: this.stageConfig.width / 2,
        y: this.stageConfig.height / 2,
        offsetX: this.imageSize.width / 2,
        offsetY: this.imageSize.height / 2,
      };
      this.measurementsGroupConfig = {
        width: containerWidth,
        height: containerHeight,
        x: this.imageSize.width / 2,
        y: this.imageSize.height / 2,
        offsetX: containerWidth / 2,
        offsetY: containerHeight / 2,
      };

      return new Promise(resolve => {
        setTimeout(() => {
          if (this.tab !== 1) return;
          this.$refs.editorImage.getNode().x(this.previewLayerConfig.x);
          this.$refs.editorImage.getNode().y(this.previewLayerConfig.y);
          const previewMeasurementsGroup = this.$refs.measurementsGroup.getNode().clone({ listening: false });
          this.$refs.previewGroup.getNode().add(previewMeasurementsGroup);
          this.$refs.previewGroup.getNode().draw();
          resolve();
        }, 500);
      });
    },
    loadImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
          resolve(img);
        };
        img.onerror = reject;
        img.src = url;
      });
    },
    async loadImageToKonva(image, reSize = true) {
      const sizeImage = await this.loadImage(image.url);
      const grayscaleImage = await this.loadImage(image.grayscaleUrl);
      return new Promise(resolve => {
        this.imageEl = sizeImage;
        this.grayscaleImageEl = grayscaleImage;
        if (reSize) {
          this.imageSize.width = sizeImage.naturalWidth;
          this.imageSize.height = sizeImage.naturalHeight;
        }
        resolve();
      });
    },
    setAnchor(landmarks, isReset = false) {
      return new Promise(resolve => {
        const blueGreenColor = '#06f2fc';
        const anchorConfig = {
          radius: 5,
          stroke: blueGreenColor,
          fill: 'white',
          strokeWidth: 1,
        };
        const controlAnchorConfig = {
          radius: 12,
          fill: 'white',
          stroke: blueGreenColor,
          strokeWidth: 0,
          shadowColor: '#242323',
          shadowBlur: 8,
          shadowOffsetX: 0,
          shadowOffsetY: 5,
        };
        const measurementIndexStyle = {
          fill: 'black',
          width: 12,
          height: 12,
          fontSize: 18,
          offsetX: 6,
          offsetY: 7,
          align: 'center',
        };
        const measurementsGroupList = [];
        let visibleMeasurementsLength = 0;
        this.categoriesMeasurements.forEach((data, index) => {
          if (data.visible) {
            visibleMeasurementsLength += 1;
          }
          const measurement = this.prepareMeasurement(landmarks, data, visibleMeasurementsLength, index, isReset);
          measurementsGroupList.push(measurement);
        });
        this.measurementsGroupList = measurementsGroupList;

        const customizedMeasurementsGroupList = [];
        let visibleCustomizedMeasurementsLength = visibleMeasurementsLength;
        this.customizedMeasurements.forEach(measurement => {
          if (measurement.visible) {
            visibleCustomizedMeasurementsLength += 1;
          }
          const size = measurement.sizes.find(item => item.name === this.activeSize);
          if (size.value <= 0) return;
          const circleAnchor = size.landmarks.map(landmark => ({
            ...anchorConfig,
            x: landmark.x,
            y: landmark.y,
          }));
          const measurementIndex = {
            text: visibleCustomizedMeasurementsLength,
            ...measurementIndexStyle,
          };
          if (visibleCustomizedMeasurementsLength > 9) {
            measurementIndex.width = 21;
            measurementIndex.offsetX = 11;
          }
          const lineStrokeConfig = { stroke: blueGreenColor, strokeWidth: 2 };
          let linePointsPosition = [];
          if (size.value) {
            linePointsPosition = size.landmarks.reduce((acc, cur) => {
              return [...acc, cur.x, cur.y];
            }, []);
          }
          const controlAnchor = { ...controlAnchorConfig };
          const hintXPosition = size.hintPosition ? size.hintPosition.x : 0;
          const hintYPosition = size.hintPosition ? size.hintPosition.y : 0;
          let initialX = 0;
          let initialY = 0;
          if (size.landmarks.length === 2) {
            const { x: x1, y: y1 } = size.landmarks[0];
            const { x: x2, y: y2 } = size.landmarks[1];
            initialX = (x2 + x1) / 2;
            initialY = (y2 + y1) / 2;
          }
          if (size.landmarks.length > 2) {
            if (size.landmarks.length % 2 === 0) {
              const medianIndex = size.landmarks.length / 2 - 1;
              const { x: x1, y: y1 } = size.landmarks[medianIndex];
              const { x: x2, y: y2 } = size.landmarks[medianIndex + 1];
              initialX = (x2 + x1) / 2;
              initialY = (y2 + y1) / 2;
            } else {
              const idx = Math.round(size.landmarks.length / 2) - 1;
              initialX = size.landmarks[idx].x;
              initialY = size.landmarks[idx].y;
            }
          }
          const hintGroup = {
            draggable: true,
            dragBoundFunc: () => {
              this.isEditing = true;
            },
            x: !isReset && hintXPosition > 0 ? hintXPosition : initialX,
            y: !isReset && hintYPosition > 0 ? hintYPosition : initialY,
          };
          const lineConfig = { points: linePointsPosition, ...lineStrokeConfig };
          const groupConfig = { visible: !!measurement.visible && (hintGroup.x > 0 || hintGroup.y > 0) };
          customizedMeasurementsGroupList.push({
            lineConfig,
            circleAnchor,
            measurementIndex,
            controlAnchor,
            hintGroup,
            groupConfig,
          });
        });
        this.customizedMeasurementsGroupList = customizedMeasurementsGroupList;
        resolve();
      });
    },
    prepareMeasurement(landmarks, measurement, visibleMeasurementsLength, index, isReset) {
      const blueGreenColor = '#06f2fc';
      const anchorConfig = {
        radius: 5,
        stroke: blueGreenColor,
        fill: 'white',
        strokeWidth: 1,
      };
      const controlAnchorConfig = {
        radius: 12,
        fill: 'white',
        stroke: blueGreenColor,
        strokeWidth: 0,
        shadowColor: '#242323',
        shadowBlur: 8,
        shadowOffsetX: 0,
        shadowOffsetY: 5,
      };
      const measurementIndexStyle = {
        fill: 'black',
        width: 12,
        height: 12,
        fontSize: 18,
        offsetX: 6,
        offsetY: 7,
        align: 'center',
      };
      const lineStrokeConfig = { stroke: blueGreenColor, strokeWidth: 2 };

      let definitionList = [];
      const circleAnchor = [];
      const linePointsPosition = [];
      if (Array.isArray(measurement.distanceDefinition)) {
        definitionList = measurement.distanceDefinition.map(data => {
          const { from, to, distance } = data;
          return { from, to, distance };
        });
      } else {
        definitionList.push(measurement.distanceDefinition);
      }
      definitionList.forEach((item, idx) => {
        const { from, to, distance } = item;
        const { x: x1, y: y1 } = landmarks[from].position;
        let x2;
        let y2;
        if (Array.isArray(to)) {
          const { x: toX1, y: toY1 } = landmarks[to[0]].position;
          const { x: toX2, y: toY2 } = landmarks[to[1]].position;
          ({ x: x2, y: y2 } = getNearestPointToALine(x1, y1, toX1, toY1, toX2, toY2));
        } else {
          ({ x: x2, y: y2 } = landmarks[to].position);
        }
        if (distance === 'y') {
          x2 = x1;
        }
        if (distance === 'x') {
          y2 = y1;
        }
        if (!circleAnchor.find(data => data.index === from)) {
          circleAnchor.push({ ...anchorConfig, x: x1, y: y1, index: from });
        }
        if (!circleAnchor.find(data => data.index === to)) {
          circleAnchor.push({ ...anchorConfig, x: x2, y: y2, index: to });
        }
        if (idx + 1 === definitionList.length) {
          linePointsPosition.push(...[x1, y1, x2, y2]);
        } else {
          linePointsPosition.push(...[x1, y1]);
        }
      });

      const controlAnchor = { ...controlAnchorConfig };
      const measurementIndex = {
        text: visibleMeasurementsLength,
        ...measurementIndexStyle,
      };
      if (visibleMeasurementsLength > 9) {
        measurementIndex.width = 21;
        measurementIndex.offsetX = 11;
      }
      const hintPosition = this.activeSizeData.measurements[index];
      let initialX = 0;
      let initialY = 0;
      if (circleAnchor.length === 2) {
        const { x: x1, y: y1 } = circleAnchor[0];
        const { x: x2, y: y2 } = circleAnchor[1];
        initialX = (x2 + x1) / 2;
        initialY = (y2 + y1) / 2;
      }
      if (circleAnchor.length > 2) {
        const idx = Math.floor(circleAnchor.length / 2);
        initialX = circleAnchor[idx].x;
        initialY = circleAnchor[idx].y;
      }
      const hintGroup = {
        draggable: true,
        dragBoundFunc: () => {
          this.isEditing = true;
        },
        x: hintPosition && hintPosition.x > 0 && !isReset ? hintPosition.x : initialX,
        y: hintPosition && hintPosition.y > 0 && !isReset ? hintPosition.y : initialY,
      };
      const lineConfig = { points: linePointsPosition, ...lineStrokeConfig };

      const groupConfig = { visible: !!measurement.visible && (hintGroup.x > 0 || hintGroup.y > 0) };
      return {
        circleAnchor,
        controlAnchor,
        lineConfig,
        measurementIndex,
        hintGroup,
        groupConfig,
      };
    },
    onMouseenter(el) {
      this.$refs[el][0].getNode().strokeWidth(2);
      document.body.style.cursor = 'pointer';
    },
    onMouseleave(el) {
      this.$refs[el][0].getNode().strokeWidth(0);
      document.body.style.cursor = 'default';
    },
    onDragmove() {
      const x = this.$refs.editorImage.getNode().x();
      const y = this.$refs.editorImage.getNode().y();
      this.previewLayerConfig.x = x;
      this.previewLayerConfig.y = y;
    },
    toolMove() {
      this.focusBtn = 'move';
      this.groupClipConfig.draggable = true;
    },
    toolZoomIn() {
      if (this.imageScale >= 2) return;
      this.imageScale = Number((this.imageScale + 0.01).toFixed(2));
      this.updateAnchorToPreview();
    },
    toolZoomOut() {
      if (this.imageScale <= 0.1) return;
      this.imageScale = Number((this.imageScale - 0.01).toFixed(2));
      this.updateAnchorToPreview();
    },
    updateAnchorToPreview() {
      return new Promise(resolve => {
        this.$nextTick(() => {
          setTimeout(() => {
            const previewMeasurementsGroup = this.$refs.measurementsGroup.getNode().clone({ listening: false });
            const children = this.$refs.previewGroup.getNode().getChildren();
            children[1].remove();
            this.$refs.previewGroup.getNode().add(previewMeasurementsGroup);
            this.$refs.previewGroup.getNode().draw();
            resolve();
          }, 500);
        });
      });
    },
    async complete() {
      const requests = [];
      const cloneSizesList = cloneDeep(this.sizesList);
      const cloneCustomizedMeasurements = cloneDeep(this.customizedMeasurements);
      cloneSizesList.forEach(size => {
        if (size.name !== this.activeSize) return;

        size.measurements.forEach((measurement, index) => {
          const refName = `hintPoint${index + 1}`;
          const x = this.$refs[refName][0].getNode().x();
          const y = this.$refs[refName][0].getNode().y();
          if (!measurement.hintPosition) {
            this.$set(measurement, 'hintPosition', { x: 0, y: 0 });
          }
          this.$set(measurement.hintPosition, 'x', x);
          this.$set(measurement.hintPosition, 'y', y);
        });
        const payload = { measurements: size.measurements };
        const request = this.$http.post(`/api/v1/merchandises/${this.merchandiseId}/sizes/${this.activeSize}`, payload);
        requests.push(request);
      });
      cloneCustomizedMeasurements.forEach((measurement, index) => {
        const idx = measurement.sizes.findIndex(size => size.name === this.activeSize);
        if (measurement.sizes[idx].value <= 0) return;

        const refName = `customizedHintPoint${index + 1}`;
        if (this.$refs[refName]) {
          const hintPosition = {
            x: this.$refs[refName][0].getNode().x(),
            y: this.$refs[refName][0].getNode().y(),
          };
          this.$set(measurement.sizes[idx], 'hintPosition', hintPosition);
          const request = this.$http.put(`/api/v1/customized_measurements/${measurement.id}/${this.activeSize}`, {
            hintPosition,
          });
          requests.push(request);
        }
      });
      try {
        this.$loadingSpinner.open();

        await Promise.all(requests);
        this.SET_SIZES_LIST(cloneSizesList);
        this.SET_CUSTOMIZED_MEASUREMENTS(cloneCustomizedMeasurements);
        this.$refs.image.getNode().visible(false);
        const { width, height } = this.visualEditingAreaSize;
        const configs = {
          x: (this.stageConfig.width - width) / 2,
          y: (this.stageConfig.height - height) / 2,
          width,
          height,
          pixelRatio: 2,
        };
        const dataUrl = this.$refs.layer.getNode().toDataURL(configs);
        this.$refs.image.getNode().visible(true);
        const data = {
          zoom: this.imageScale,
          size: this.activeSizeData.name,
          dataUrl,
        };
        this.$bus.$emit('merchandise-info', data);
      } catch (e) {
        console.log(e.message);
        this.$toastMessage.info(e.message, 124, 'left');
      } finally {
        this.$loadingSpinner.close();
      }
    },
    async reset() {
      this.imageScale = 0.7;
      await this.setAnchor(this.activeSizeData.landmarks, true);
      await this.initializekonvaConfig();
      await this.updateAnchorToPreview();
      this.$refs.editorImage.getNode().x(this.previewLayerConfig.x);
      this.$refs.editorImage.getNode().y(this.previewLayerConfig.y);
    },
  },
};
</script>

<style lang="scss" scoped>
.export-mode {
  @apply tw-w-full tw-h-full tw-relative tw-overflow-hidden;
  background-color: rgb(221, 221, 221);
}
.image-filter {
  @apply tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full;
  background-color: rgba(255, 255, 255, 0.5);
}
.tool-icon {
  width: 42px;
  height: 42px;
  transition: opacity 0.3s, filter 0.3s;
  background-size: contain;
}
.select-icon {
  background-image: url('../../../assets/home/measurement/select-n.svg');
  &:hover {
    background-image: url('../../../assets/home/measurement/select-h.svg');
  }
  &:active {
    background-image: url('../../../assets/home/measurement/select-p.svg');
  }
  &.focus {
    background-image: url('../../../assets/home/measurement/select-f.svg');
  }
}
.move-icon {
  background-image: url('../../../assets/home/measurement/move-n.svg');
  &:hover {
    background-image: url('../../../assets/home/measurement/move-h.svg');
  }
  &:active {
    background-image: url('../../../assets/home/measurement/move-p.svg');
  }
  &.focus {
    background-image: url('../../../assets/home/measurement/move-f.svg');
  }
}
.zoomIn-icon {
  background-image: url('../../../assets/home/measurement/zoomIn-n.svg');
  &:hover {
    background-image: url('../../../assets/home/measurement/zoomIn-h.svg');
  }
  &:active {
    background-image: url('../../../assets/home/measurement/zoomIn-p.svg');
  }
}
.zoomOut-icon {
  background-image: url('../../../assets/home/measurement/zoomOut-n.svg');
  &:hover {
    background-image: url('../../../assets/home/measurement/zoomOut-h.svg');
  }
  &:active {
    background-image: url('../../../assets/home/measurement/zoomOut-p.svg');
  }
}
.sizes-select {
  @apply tw-w-24 tw-mt-2;
  text-transform: uppercase;
  ::v-deep {
    .v-input__slot {
      border-width: 1px !important;
      background-color: #fff !important;
      min-height: 24px;
    }
    .v-input__append-inner {
      margin-top: 4px;
    }
  }
}
</style>

<style lang="scss">
.v-menu__content {
  text-transform: uppercase;
}
</style>
