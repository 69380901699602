import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import labelingTool from './modules/labelingTool';

const plugins = process.env.NODE_ENV !== 'production' ? [createLogger({ collapsed: false })] : [];

function loadModules() {
  const context = require.context('./modules', false, /([a-z_]+)\.js$/i);

  const modules = context
    .keys()
    .map(key => ({ key, name: key.match(/([a-z_]+)\.js$/i)[1] }))
    .reduce(
      (module, { key, name }) => ({
        ...module,
        [name]: context(key).default,
      }),
      {}
    );

  return { context, modules };
}

Vue.use(Vuex);

const { context } = loadModules();

const store = new Vuex.Store({
  modules: {
    labelingTool,
  },
  plugins,
  strict: process.env.NODE_ENV !== 'production',
});

if (module.hot) {
  module.hot.accept(context.id, () => {
    const { modules } = loadModules();
    store.hotUpdate({
      modules,
    });
  });
}

export default store;
