<template>
  <div class="checkbox-wrapper">
    <input
      :id="id"
      class="t-checkbox"
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      @change="$emit('change', $event.target.checked)"
    />
    <label :for="id"></label>
    <slot name="label"></slot>
  </div>
</template>

<script>
export default {
  name: 'TCheckbox',
  props: {
    checked: Boolean,
    disabled: Boolean,
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  data: () => ({
    id: null,
  }),
  mounted() {
    this.id = this._uid; // eslint-disable-line
  },
};
</script>

<style lang="scss" scoped>
$height: 24px;
.checkbox-wrapper {
  display: flex;
  height: $height;
}
.t-checkbox {
  display: none;
}
.t-checkbox {
  & + label {
    cursor: pointer;
    display: inline-block;
    padding: 0;
    margin-right: 10px;
    background: url('../../assets/uncheckbox.svg') no-repeat center;
    background-size: contain;
    width: $height;
    height: $height;
  }

  &:disabled + label {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &:checked + label {
    background-image: url('../../assets/checkbox-on.svg');
  }
}
</style>
