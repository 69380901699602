<template>
  <v-dialog
    :value="showUploadImageDialog"
    persistent
    no-click-animation
    hide-overlay
    fullscreen
    content-class="tw-bg-white tw-relative"
  >
    <app v-if="showUploadImageDialog" :hide-menu="true">
      <v-layout justify-center>
        <v-flex>
          <!-- Project Lists -->
          <explorer
            :items="items"
            :total-items="totalItems"
            :total-pages="totalPages"
            :actions="actions"
            :current-page="currentPage"
            :paths="currentPath"
            :page-sizes="pageSizes"
            :current-page-size="pageSize"
            :sort="sortingOptions"
            :is-searching="false"
            :is-requesting="isRequesting"
            :can-select="true"
            :can-import="true"
            :can-select-all="false"
            :can-search="false"
            :select-all="selectAll"
            :fix-view-mode="'grid'"
            :no-data="noData"
            hideSortingConditions
            put-action-menu-to-end
            @change-page-size="changePageSize"
            @get-data="getData"
            @import-file="importFile"
            @delete="deleteImage"
          >
            <div slot="top-button" class="import-icon" @click="onUploadClick" />
            <div slot="top-right-button" class="tw-flex tw-items-center">
              <img src="../../../assets/home/ic-balance.svg" alt="balance" class="tw-mr-2" />
              <div>{{ $t('current_balance') }}：{{ balance.remaining }}</div>
            </div>
            <!-- Single List -->
            <template slot-scope="{ items, toggle }">
              <image-item
                v-for="item in items"
                :key="item.id"
                :item="item"
                :current-size="currentSize"
                @click.native.stop="clickItem(item)"
                @menu="toggle"
              />
            </template>
          </explorer>
        </v-flex>
      </v-layout>
      <input
        ref="imageInput"
        type="file"
        style="display: none"
        accept=".jpeg, .jpg, .png"
        multiple
        @change="inputChange"
      />
      <selection-window
        :selections="sizes"
        :hidden.sync="hidden"
        :saving="saving"
        :title="$t('selected_pictures')"
        data-test-id="image-selection-window"
        @cancel="cancel"
        @confirm="save"
      >
        <template slot-scope="{ items }">
          <mini-image-item
            v-for="item in items"
            :key="item.id"
            :item="item"
            :current-size="currentSize"
            @click.native.stop="currentSize = item"
            @cancel="cancelTargetImage(item)"
          />
        </template>
      </selection-window>
      <popup-dialog
        :show-dialog="showPopup"
        :confirm-title="$t('leave')"
        @cancel="showPopup = false"
        @confirm="confirm"
      >
        <div slot="header-title" class="tw-w-full tw-text-center">{{ $t('terminate_upload') }}</div>
        <div slot="content" class="tw-text-base" v-html="$t('terminate_upload_text')" />
      </popup-dialog>
    </app>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { cloneDeep } from 'lodash';
import parallelLimit from 'async/parallelLimit';
import reflectAll from 'async/reflectAll';
import { getMD5FromBlob, uploadToS3 } from '@/modules/utils';
import App from '../../common/App';
import Explorer from '../explorer/Explorer';
import explorer from '../mixin/explorer';
import ImageItem from './ImageItem';
import SelectionWindow from './SelectionWindow';
import MiniImageItem from './MiniImageItem';
import PopupDialog from '../../common/PopupDialog';

import deleteImg from '../../../assets/ic-delete.svg';

const IMAGE_UPLOAD_ERRORS = {
  [-1000]: 'measure_error__unknown',
  [-1001]: 'measure_error__chessboard_size',
  [-1002]: 'measure_error__clothing_type',
  [-1003]: 'measure_error__image_size',
  [-1004]: 'measure_error__chessboard_detection',
  [-1005]: 'measure_error__point_detection',
};

export default {
  name: 'SelectionImageDialog',
  components: {
    App,
    Explorer,
    ImageItem,
    SelectionWindow,
    MiniImageItem,
    PopupDialog,
  },
  mixins: [explorer],
  data: vm => ({
    tabs: [
      {
        id: 'measurement',
        name: '',
        endpoint: 'projects',
        params: { type: 1 },
      },
    ],
    paths: [[{ name: '', root: true, disabled: false }]],
    uploadingImages: [],
    hidden: false,
    saving: false,
    showPopup: false,
    sizes: [],
    customizedSizeMeasurements: [],
    removedImageData: [],
    currentSize: {},
    successQuantity: 0,
    actions: [
      {
        event: 'delete',
        icon: deleteImg,
        dir: true,
        title: vm.$t('actions__remove'),
        multiple: true,
      },
    ],
  }),
  computed: {
    ...mapGetters('labelingTool', {
      merchandise: 'currentItem',
      balance: 'balance',
    }),
    ...mapGetters('labelingTool/measurementTool', ['showUploadImageDialog', 'sizesList', 'customizedMeasurements']),
    merchandiseId() {
      const { id } = this.merchandise || {};
      return id;
    },
  },
  watch: {
    async showUploadImageDialog(nv) {
      if (nv) {
        await this.init();
        this.sizes = cloneDeep(this.sizesList);
        if (this.sizes.every(size => !size.image)) {
          this.currentSize = this.sizes[0];
          return;
        }
        window.onpopstate = () => {
          this.SET_SHOW_UPLOAD_IMAGE_DIALOG(false);
        };
        return;
      }
      window.onpopstate = '';
    },
    sizes: {
      deep: true,
      handler(nv) {
        this.items.forEach(item => {
          this.$set(item, 'size', '');
        });
        nv.forEach(size => {
          if (!size.image) return;
          const index = this.items.findIndex(image => image.id === size.image.id);
          if (index >= 0) {
            this.items[index].size = size.name;
          }
        });
      },
    },
  },
  methods: {
    ...mapMutations('labelingTool/measurementTool', ['SET_SHOW_UPLOAD_IMAGE_DIALOG']),
    ...mapMutations('labelingTool', ['SET_BALANCE']),
    async init() {
      if (!this.merchandiseId) return;

      this.currentTab = this.tabs[0].id;
      await this.getData(1);
      this.customizedSizeMeasurements = cloneDeep(this.customizedMeasurements);
      this.setEmptySizeImage();
      Promise.resolve();
    },
    async getData(page) {
      const params = { ...this.pagination };
      const { by, asc } = this.sortingOptions;
      if (by) {
        params.sort = asc ? by : `-${by}`;
      }
      if (this.filter) {
        params.filter = this.filter;
      }
      if (page) {
        params.offset = (page - 1) * this.pagination.limit;
        this.currentPage = page;
      }
      try {
        this.scrollToTop();
        this.isRequesting = true;
        const { data } = await this.$http.get(`/api/v1/merchandises/${this.merchandiseId}/images`, { params });
        const { total, images } = data;
        images.forEach(image => {
          if (image.error || (Object.hasOwnProperty.call(image, 'error') && !image.thumbnailUrl)) {
            this.$set(image, 'errorMsg', IMAGE_UPLOAD_ERRORS[image.error] || 'measure_upload_error__unknown');
          }
        });
        this.items = cloneDeep(images);
        this.totalItems = total;
        this.sizes.forEach(size => {
          if (!size.image) return;
          const index = this.items.findIndex(image => image.id === size.image.id);
          if (index >= 0) {
            this.$set(this.items[index], 'size', size.name);
          }
        });
      } catch (e) {
        this.$toastMessage.info(e.message);
      } finally {
        this.isRequesting = false;
      }
    },
    onUploadClick() {
      this.$refs.imageInput.click();
    },
    async inputChange() {
      const { files } = this.$refs.imageInput;
      await this.handleFileSelections(files);
      this.$refs.imageInput.value = '';
    },
    async importFile(event) {
      const files = event.target.files || event.dataTransfer.files;
      await this.handleFileSelections(files);
      this.$set(event.target, 'value', '');
    },
    async handleFileSelections(files) {
      if (files.length === 0) {
        this.$toastMessage.info(this.$t('garment_measure.select_image_tip_1'));
        return;
      }
      if (this.balance.remaining <= 0 || files.length > this.balance.remaining) {
        this.$confirm.open(
          this.$t('garment_measure.select_image_tip_2'),
          this.$t('garment_measure.select_image_tip_3'),
          true
        );
        return;
      }
      await this.batchUploadImages([...files]);
    },
    makeUploadRequest(file) {
      return async callback => {
        const selfIndex = this.items.findIndex(item => item.id === file.id && item.uploading);
        const self = selfIndex > -1 ? this.items[selfIndex] : null;
        let uploadFailed = false;
        try {
          // 1. ask for the url to upload
          const startUploadUrl = `/api/v1/merchandises/${this.merchandiseId}/images/start_upload`;
          const resp = await this.$http.post(startUploadUrl, { fileName: file.name });
          if (!resp) {
            throw new Error('connection error');
          }
          const { url, filePath } = resp.data;
          if (!url || !filePath) {
            throw new Error('failed to start upload');
          }
          // 2. upload the actual file to s3
          await uploadToS3(url, file);
          // 3. get the md5 hash of the file
          const md5 = await getMD5FromBlob(file);
          // 4. finish upload and wait for image processing
          const finishUploadUrl = `/api/v1/merchandises/${this.merchandiseId}/images/finish_upload`;
          const { data } = await this.$http.post(finishUploadUrl, { filePath, md5 });
          const { thumbnailUrl, id } = data;
          // 5. set the thumbnail of the image now the upload completes
          if (self) {
            this.$set(self, 'thumbnailUrl', thumbnailUrl);
            this.$set(self, 'id', id);
          }
          callback(null);
        } catch (e) {
          uploadFailed = true;
          const { status, data = {} } = e.response || {};
          let message = '';
          if (status === 500 || status === 400) {
            message = this.$t('measure_upload_error__unknown');
          } else if (status === 503 || status === 504) {
            message = this.$t('upload_timeout');
          } else {
            message = data?.error || e.message;
          }
          console.log(`${file.name}: ${message}`);
          callback(true);
        } finally {
          if (self) {
            this.$set(self, 'uploading', false);
            this.$set(self, 'error', uploadFailed);
          }
        }
      };
    },
    async batchUploadImages(files) {
      if (!this.merchandiseId) return;

      if (!Array.isArray(files)) return;

      this.isRequesting = true;
      const date = new Date();
      files.forEach((file, index) => this.$set(file, 'id', `${date.getTime()}-${index}`));
      this.items = [...this.items, ...files.map(file => ({ fileName: file.name, uploading: true, id: file.id }))];
      const requests = files.map(this.makeUploadRequest);
      let result = [];
      try {
        result = await parallelLimit(reflectAll(requests), 3);
      } catch (e) {
        console.log(e);
      } finally {
        const isUploading = this.items.some(item => {
          return item.uploading;
        });
        this.successQuantity += result.filter(item => !item.error).length;
        if (!isUploading) {
          await this.getBalance();
          await this.init();
          this.$toastMessage.info(
            `${this.$t('garment_measure.select_image_tip_4')}： ${this.$tc(
              'garment_measure.success_total',
              this.successQuantity,
              {
                count: this.successQuantity,
              }
            )}&nbsp&nbsp&nbsp&nbsp${this.$t('garment_measure.select_image_tip_5')}：${this.balance.remaining}`
          );
          this.isRequesting = false;
          this.successQuantity = 0;
        }
      }
    },
    async save() {
      const requests = this.sizes.map(size => {
        const { image, name } = size;
        const { id } = image || {};
        const data = { imageId: id || null };
        return this.$http.post(`/api/v1/merchandises/${this.merchandiseId}/sizes/${name}`, data);
      });
      try {
        await Promise.all(requests);
        this.SET_SHOW_UPLOAD_IMAGE_DIALOG(false);
      } catch (e) {
        this.$toastMessage.info(e.message);
      }
    },
    cancel() {
      if (this.isRequesting) {
        this.showPopup = true;
        return;
      }
      this.SET_SHOW_UPLOAD_IMAGE_DIALOG(false);
    },
    setEmptySizeImage() {
      const index = this.sizes.findIndex(size => !size.image);
      this.currentSize = index >= 0 ? this.sizes[index] : {};
    },
    canClick(item) {
      return (
        (!item.uploading && !item.errorMsg && item.id && !item.size && Object.keys(this.currentSize).length > 0) ||
        this.currentSize.name === item.size
      );
    },
    clickItem(item) {
      if (!this.canClick(item)) return;

      const index = this.sizes.findIndex(size => size.id === this.currentSize.id);
      if (index >= 0) {
        if (this.sizes[index].image && this.sizes[index].image.id === item.id) {
          this.sizes[index].image = null;
        } else {
          this.sizes[index].image = { ...item };
          this.setEmptySizeImage();
        }
      }
    },
    async getBalance() {
      const { data } = await this.$http.get('/api/v1/balance');
      this.SET_BALANCE(data);
    },
    confirm() {
      this.SET_SHOW_UPLOAD_IMAGE_DIALOG(false);
      this.showPopup = false;
      this.isRequesting = false;
    },
    async deleteImage(item) {
      try {
        const confirm = await this.$confirm.open(
          `${this.$tc('garment_measure.select_image_tip_6', item.fileName, { fileName: item.fileName })}`,
          this.$t('garment_measure.select_image_tip_7')
        );
        if (!confirm) {
          return;
        }
        this.$loadingSpinner.open();
        await this.$http.delete(`/api/v1/merchandises/${this.merchandiseId}/images/${item.id}`);
        const index = this.items.findIndex(data => data.id === item.id);
        this.items.splice(index, 1);
      } catch (e) {
        console.log(e.message);
        this.$toastMessage.info(e.message);
      } finally {
        this.$loadingSpinner.close();
      }
    },
    async cancelTargetImage(item) {
      const requests = [];
      const filterCustomizedMeasurements = [];
      this.customizedSizeMeasurements.forEach(measurement => {
        const data = measurement.sizes.find(
          size => size.name === item.name && (size.landmarks.length > 0 || size.value > 0)
        );
        if (data) {
          filterCustomizedMeasurements.push(measurement.id);
        }
      });
      if (item.landmarks.length > 0 || item.measurements.length > 0 || filterCustomizedMeasurements.length > 0) {
        const confirm = await this.$confirm.open(
          this.$t('garment_measure.select_image_tip_8'),
          this.$t('garment_measure.select_image_tip_9')
        );
        if (!confirm) return;
        if (item.landmarks.length > 0 || item.measurements.length > 0) {
          requests.push(
            this.$http.post(`/api/v1/merchandises/${this.merchandiseId}/sizes/${item.name}`, {
              imageId: '',
              isDefault: false,
              rotation: 0,
              zoom: 0.7,
              landmarks: [],
              measurements: [],
            })
          );
        }
        if (item.isDefault) {
          const formData = new FormData();
          formData.append('snapshot', '');
          formData.append('size', item.name);
          requests.push(this.$http.post(`/api/v1/merchandises/${this.merchandiseId}`, formData));
        }
        if (filterCustomizedMeasurements.length > 0) {
          filterCustomizedMeasurements.forEach(id => {
            const request = this.$http.put(`/api/v1/customized_measurements/${id}/${item.name}`, {
              landmarks: [],
              value: 0,
            });
            requests.push(request);
          });
        }
      }
      try {
        if (requests.length > 0) {
          this.$loadingSpinner.open();
          await Promise.all(requests);
        }
      } catch (e) {
        console.log(e.message);
        this.$toastMessage.info(e.message);
      } finally {
        const index = this.sizes.findIndex(size => size.id === item.id);
        this.sizes[index].image = null;
        this.sizes[index].landmarks = [];
        this.sizes[index].measurements = [];
        this.customizedSizeMeasurements.forEach(measurement => {
          const idx = measurement.sizes.findIndex(
            size => size.name === item.name && (size.landmarks.length > 0 || size.value > 0)
          );
          if (idx >= 0) {
            this.$set(measurement.sizes[idx], 'landmarks', []);
            this.$set(measurement.sizes[idx], 'value', 0);
          }
        });
        this.$loadingSpinner.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.import-icon {
  @apply tw-mr-5 tw-cursor-pointer;
  width: 66px;
  height: 44px;
  background: url('../../../assets/home/btn_import_n.svg') 0 0 no-repeat;
  &:hover {
    background-image: url('../../../assets/home/btn_import_h.svg');
  }
  &:active {
    background-image: url('../../../assets/home/btn_import_p.svg');
  }
}
</style>
