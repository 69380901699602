import { render, staticRenderFns } from "./LabelingItem.vue?vue&type=template&id=26f075ba&scoped=true&"
import script from "./LabelingItem.vue?vue&type=script&lang=js&"
export * from "./LabelingItem.vue?vue&type=script&lang=js&"
import style0 from "./LabelingItem.vue?vue&type=style&index=0&id=26f075ba&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26f075ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCheckbox,VFlex,VIcon,VLayout,VSpacer})
