<template>
  <app :page-title="'ML Label'">
    <v-container fluid class="home--bg">
      <!-- Image Uploader -->
      <upload :items="items"></upload>
      <!-- Home Content -->
      <v-layout justify-center>
        <v-flex>
          <!-- Project Lists -->
          <explorer
            :items="items"
            :total-items="totalItems"
            :total-pages="totalPages"
            :current-page="currentPage"
            :paths="currentPath"
            :tags="tags"
            :actions="actions"
            :page-sizes="pageSizes"
            :current-page-size="pageSize"
            :sort="sortingOptions"
            :is-searching="isSearching"
            :filter="filter"
            :is-requesting="isRequesting"
            :can-select="false"
            :can-import="true"
            :can-select-all="false"
            :can-search="true"
            :select-all="selectAll"
            :fix-view-mode="'list'"
            :columns="columns"
            :no-data="noData"
            put-action-menu-to-end
            @editView="openCUprojectDialog"
            @select="selectItem"
            @delete="deleteData"
            @start-searching="startSearching"
            @change-search-value="changeSearchValue"
            @cancel-search="cancelSearch"
            @change-sorting-options="changeSortingOptions"
            @change-page-size="changePageSize"
            @get-data="getData"
            @click-dir="traverse"
            @go-back="goBack"
            @toggle-all="handleToggleAll"
          >
            <div slot="top-button" class="import-icon tw-mr-5" @click="openCUprojectDialog(false)" />
            <!-- Single List -->
            <template slot-scope="{ items, mode, clean, select, toggle, canSelect }">
              <labeling-item
                v-for="item in items"
                :key="item.id"
                :view-type="mode"
                :item="item"
                :clean="clean"
                :can-select="canSelect"
                @click="openCUBBoxDialog"
                @select="select"
                @menu="toggle"
              />
            </template>
          </explorer>
        </v-flex>
      </v-layout>
      <!-- Project Dialog -->
      <project-dialog
        ref="projectDialog"
        :showDialog="dialog.checkDialog"
        :singleProductDialog="dialog.singleProductDialog"
        @closeDialog="closeDialog"
        @CUproject="CUproject"
        @openCUBBoxDialog="openCUBBoxDialog"
      />
      <!-- Bbox Dialog -->
      <bbox-dialog
        ref="boxDialog"
        :showDialog="dialog.checkDialog"
        :singleProductDialog="dialog.singleBBoxDialog"
        @openCUBBoxDialog="openCUBBoxDialog"
        @closeDialog="closeDialog"
      />
    </v-container>
  </app>
</template>

<script>
import i18n from '../i18n/i18n';
import App from '../components/common/App';
import Upload from '../components/common/Upload';
import ProjectDialog from '../components/home/DialogModel';
import BboxDialog from '../components/bbox&classification/BboxAndClassification';
import Explorer from '../components/home/explorer/Explorer';
import explorer from '../components/home/mixin/explorer';
import LabelingItem from '../components/home/Labeling/LabelingItem';

import addProjectImg from '../assets/home/ic-add-project.svg';
import editImg from '../assets/ic-edit.svg';
// import deleteImg from '../assets/ic-delete.svg';

const LABELINGCOLUMNS = [
  { name: i18n.t('project_name'), width: 5, key: 'name' },
  { name: i18n.t('created_at'), width: 5, key: 'created_at' },
  { name: i18n.t('completion'), width: 2, key: 'completion' },
];

const ITEMS_PER_PAGE = [40, 80];

export default {
  name: 'MlLabel',
  components: {
    App,
    Upload,
    ProjectDialog,
    BboxDialog,
    Explorer,
    LabelingItem,
  },
  mixins: [explorer],
  data() {
    return {
      selected: [],
      tabs: [
        {
          id: 'labeling',
          name: '',
          endpoint: 'projects',
          params: { type: 0 },
        },
      ],
      tags: [],
      totalItems: 0,
      paths: [[{ name: '首頁', root: true, disabled: false }], [{ name: '首頁', root: true, disabled: false }]],
      pageSizes: ITEMS_PER_PAGE,
      labelingActions: [
        {
          event: 'editView',
          icon: editImg,
          title: this.$t('actions__edit_view'),
          multiple: true,
        },
        // {
        //   event: 'deleteView',
        //   icon: deleteImg,
        //   title: i18n.t('actions__remove')
        // }
      ],
      labelingTopButtonAction: [
        {
          event: 'addNewProject',
          icon: addProjectImg,
          title: this.$t('add_project'),
        },
      ],
      dialog: {
        checkDialog: {
          projectDialog: false,
          bBoxAndClassification: false,
        },
        singleProductDialog: {},
        singleBBoxDialog: {},
      },
    };
  },
  mounted() {
    this.onTabChanged(this.tabs[0].id);
  },
  methods: {
    // get All Products
    async getData(page, folder) {
      this.selectAll = false;
      const params = { ...this.pagination };
      const { by, asc } = this.sortingOptions;
      if (by) {
        params.sort = asc ? by : `-${by}`;
      }
      if (this.filter && !folder) {
        params.filter = this.filter;
      } else {
        const currentFolder = folder || this.currentFolder;
        const { id, isDir, isProject } = currentFolder;
        if (id && isDir && !isProject) {
          params.parentId = currentFolder.id;
        }
      }
      if (page) {
        params.offset = (page - 1) * this.pagination.limit;
        this.currentPage = page;
      }
      const { endPoint, realm } = this;
      try {
        this.scrollToTop();
        this.isRequesting = true;
        Object.assign(params, this.params);
        const { data } = await this.$http.get(endPoint, { params });
        const { total } = data;
        this.items = data[realm].map(item => this.makeExplorerItem(item));
        this.totalItems = total;
      } catch (e) {
        const { response } = e;
        const { status } = response || {};
        if (status === 401) {
          this.$router.push('/login');
        }
      } finally {
        this.isRequesting = false;
      }

      this.isRequesting = false;
    },
    async traverse(folder) {
      if (!this.filter) {
        if (this.isSearching) {
          this.isSearching = false;
        }
        if (!this.currentPath.find(existing => existing.id === folder.id)) {
          this.currentPath.push(folder);
        }
      }
      if (folder.isProject) {
        this.currentProject = folder;
      }
      await this.getData(1, folder);
    },
    // will open Project Dialog Toggle Create/Update
    async openCUprojectDialog(item) {
      this.dialog.checkDialog.projectDialog = true;
      if (item) {
        await this.openUpdateProjectDialog(item);
      } else {
        this.openCreateProjectDialog();
      }
    },
    // open Null Project Data Project Dialog
    openCreateProjectDialog() {
      this.dialog.singleProductDialog = {
        name: '',
        labelingType: -1,
        classes: [],
      };
    },
    // open has Single Project Data Prjoct Dialog
    async openUpdateProjectDialog(item) {
      this.$refs.projectDialog.loading(true);
      try {
        const response = await this.$http.get(`/api/v1/projects/${item.id}`);
        if (response.data) {
          this.dialog.singleProductDialog = { ...response.data };
        }
      } catch (e) {
        const { response } = e;
        const { status } = response || {};
        if (status === 401) {
          this.$router.push('/login');
        }
      }
    },
    // will call Project Dialog Create/Update API
    CUproject(item) {
      if (item.id) {
        this.updateProject(item);
      } else {
        this.createProject(item);
      }
    },
    // call Create Project API
    async createProject(item) {
      try {
        const { classes, ...payload } = item;
        if (Array.isArray(classes) && classes.filter(c => c).length > 0) {
          payload.classes = classes;
        }
        await this.$http.post('/api/v1/projects', payload);
      } catch (e) {
        const { response } = e;
        const { status } = response || {};
        if (status === 401) {
          this.$router.push('/login');
        }
      }
    },
    // call Update Project API
    async updateProject(item) {
      const data = {
        name: item.name,
        labelingType: item.labelingType,
        classes: item.classes,
      };
      try {
        await this.$http.post(`/api/v1/projects/${item.id}`, data);
      } catch (e) {
        const { response } = e;
        const { status } = response || {};
        if (status === 401) {
          this.$router.push('/login');
        }
      }
    },
    // will open Bbox Dialog Toggle Create/Update
    async openCUBBoxDialog(item, image) {
      this.$refs.boxDialog.moveImgToCenter();
      this.$refs.boxDialog.loadingImg(true);
      this.dialog.checkDialog.bBoxAndClassification = true;
      if (image) {
        await this.openUpdateBBoxDialog(item, image);
      } else {
        await this.openCreateBBoxDialog(item);
      }

      if (this.dialog.singleBBoxDialog.next || this.dialog.singleBBoxDialog.labels) {
        this.dialog.checkDialog.bBoxAndClassification = true;
      } else {
        this.dialog.checkDialog.bBoxAndClassification = false;
        this.$refs.boxDialog.loadingImg(false);
        setTimeout(() => {
          this.$refs.boxDialog.singleProduct = {};
          this.$snackbar.info(this.$t('no_next'));
        }, 300);
      }
    },
    //  open Null Image Data BBox Dialog
    async openCreateBBoxDialog(item) {
      const data = { imageId: '', labels: [] };

      const { data: respData } = await this.$http.post(`/api/v1/projects/${item.id}/labels`, data);
      this.dialog.singleBBoxDialog = { ...item, ...respData };
    },
    //  open has Single Image Data BBox Dialog
    async openUpdateBBoxDialog(item, image) {
      const next = { ...image };
      const { data } = await this.$http.get(`/api/v1/projects/${item.id}/images/${image.id}/labels`);
      this.dialog.singleBBoxDialog = {
        ...item,
        ...data,
        next,
        fromSingleImage: true,
      };
    },
    // closoe Project/Bbox Dialog
    closeDialog(type, status) {
      const { checkDialog } = this.dialog;
      this.refreshAllProjects();
      switch (type) {
        case 'projectDialog':
          this.dialog.singleProductDialog = {};
          checkDialog.projectDialog = status;
          break;
        case 'bBoxAndClassification':
          this.dialog.singleBBoxDialog = {};
          checkDialog.bBoxAndClassification = status;
          if (checkDialog.projectDialog) {
            this.$refs.projectDialog.refreshImages();
          }
          break;
        default:
      }
    },
    makeExplorerItem(source) {
      const isProject = true;
      const { metadata = {} } = source;
      return {
        ...source,
        ...metadata,
        selected: false,
        current: false,
        isDir: source.isDir || isProject,
        isProject,
      };
    },
    // refresh Projects to page1
    async refreshAllProjects() {
      this.items = [];
      await this.getData(1);
    },
    cancelSearch() {
      this.isSearching = false;
      this.filter = '';
      this.refreshAllProjects();
    },
  },
  computed: {
    endPoint() {
      return '/api/v2/projects';
    },
    realm() {
      return 'projects';
    },
    params() {
      return { type: 0 };
    },
    columns() {
      return LABELINGCOLUMNS;
    },
    actions() {
      return this.labelingActions;
    },
    topButtonAction() {
      return this.labelingTopButtonAction;
    },
  },
};
</script>

<style lang="scss" scoped>
.home--bg {
  background-color: #ededed;
  height: 100%;
  padding: 0;
}

.import-icon {
  @apply tw-cursor-pointer;
  width: 66px;
  height: 44px;
  background: url('../assets/home/btn_import_n.svg') 0 0 no-repeat;
  &:hover {
    background-image: url('../assets/home/btn_import_h.svg');
  }
  &:active {
    background-image: url('../assets/home/btn_import_p.svg');
  }
}
</style>
