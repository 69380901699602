import { render, staticRenderFns } from "./MerchandisesTable.vue?vue&type=template&id=21e72006&scoped=true&"
import script from "./MerchandisesTable.vue?vue&type=script&lang=js&"
export * from "./MerchandisesTable.vue?vue&type=script&lang=js&"
import style0 from "./MerchandisesTable.vue?vue&type=style&index=0&id=21e72006&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e72006",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VMenu})
