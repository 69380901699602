var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrapper",staticClass:"tw-relative tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full tw-overflow-hidden",class:[
    _vm.mode,
    {
      'custom-cursor': !!_vm.imageUrl && _vm.activeLabelMark.name,
      'custom-purple-cursor': Object.keys(_vm.reLabeledManualMark).length || _vm.hadActiveManualMeasurement,
    } ],on:{"mousedown":_vm.onMousedown,"contextmenu":function($event){$event.preventDefault();},"mousemove":_vm.onWrapperMousemove,"mouseup":_vm.onMouseup,"mouseleave":_vm.onMouseup}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.imageUrl),expression:"imageUrl"}],staticClass:"align-center",style:([_vm.imageStyle ].concat( _vm.imageRotation))},[_c('xo-img',{ref:"merchandiseImage",attrs:{"src":_vm.imageUrl},on:{"load":function($event){_vm.imgLoaded = true}}})],1),(_vm.showUnitCoordinates)?_c('div',{staticClass:"grid-mask",style:(_vm.imageZoomRatio),attrs:{"alt":"grid"}}):_vm._e(),_c('div',{staticClass:"align-center",style:([_vm.imageStyle, { width: ((_vm.maskSize.width) + "px"), height: ((_vm.maskSize.height) + "px") }])},[_vm._l((_vm.labeledMarks),function(item){return _c('div',{key:item.id,staticClass:"mark",class:{ 'tw-filter tw-grayscale': _vm.hadActiveManualMeasurement },style:(Object.assign({}, {top: ((item.position.y) + "px"),
        left: ((item.position.x) + "px")},
        _vm.restoreScaling))},[_c('div',[_vm._v(_vm._s(item.name))])])}),_c('k-stage',{ref:"preview",attrs:{"config":{ width: _vm.maskSize.width, height: _vm.maskSize.height }}},[_c('k-layer',_vm._l((_vm.manualLines),function(item,index){return _c('k-line',{key:("line-" + index),attrs:{"config":item}})}),1)],1),_vm._l((_vm.manualLandmarks),function(landmarks,index){return _vm._l((landmarks),function(item,idx){return _c('div',{key:(index + "-mark-" + idx),staticClass:"manual-mark",class:{
          'tw-filter tw-grayscale':
            (_vm.hadActiveManualMeasurement && item.id !== _vm.activeManualMeasurement.id) ||
            (!_vm.hadActiveManualMeasurement && !_vm.measurementLandmarks.every(function (item) { return !item.labeled; }) && _vm.isEditing),
        },style:(Object.assign({}, {top: ((item.y) + "px"),
          left: ((item.x) + "px")},
          _vm.restoreScaling))})})}),(_vm.auxiliaryLinesData)?[_c('div',{key:("auxLine" + (_vm.activeLabelMark.name)),ref:"auxLine",staticClass:"tw-absolute tw-w-full",style:([{ top: ((_vm.auxiliaryLinesData.y) + "px") }, _vm.auxiliaryLinesData.rotationStyle])},[_c('div',{staticClass:"distance-line",style:([_vm.auxiliaryLinesData.distanceLineStyle])}),_c('div',{staticClass:"dash-line"})]),(_vm.auxLineBase)?_c('div',{ref:"baseLine",staticClass:"tw-absolute tw-w-full base-line",style:([{ top: ((_vm.auxLineBase.y) + "px"), left: ((_vm.auxLineBase.x) + "px") }])},[_c('div',{staticClass:"dash-line"})]):_vm._e(),(_vm.customAuxLine)?_c('div',{ref:"customAuxLine",staticClass:"tw-absolute tw-w-full",style:(_vm.customAuxLine.style)},[_c('div',{staticClass:"dash-line"})]):_vm._e()]:_vm._e()],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.imageUrl),expression:"imageUrl"}],staticClass:"lens"},[_c('div',{staticClass:"tw-w-full tw-h-full tw-transform tw-scale-150"},[_c('div',{ref:"imageZoomer",staticClass:"tw-absolute tw-overflow-hidden",style:({
          width: ((_vm.wrapperSize.width) + "px"),
          height: ((_vm.wrapperSize.height) + "px"),
        })},[_c('xo-img',{staticClass:"align-center",style:([_vm.imageStyle ].concat( _vm.imageRotation)),attrs:{"src":_vm.imageUrl}}),(_vm.showUnitCoordinates)?_c('div',{staticClass:"grid-mask",style:(_vm.imageZoomRatio),attrs:{"alt":"grid"}}):_vm._e()],1)]),(!_vm.hadActiveManualMeasurement)?_c('img',{staticClass:"align-center",attrs:{"src":require("../../../assets/home/measurement/ic-aim-point.svg")}}):_c('img',{staticClass:"align-center",attrs:{"src":require("../../../assets/home/measurement/ic-aim-point-purple.svg")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.imageUrl),expression:"!!imageUrl"}],ref:"mouseCursor",staticClass:"cursor",class:{
      hover: _vm.isMarkHover,
      'purple-cursor': _vm.hadActiveManualMeasurement,
    },on:{"mousemove":_vm.moveLens}},[_c('div',{staticClass:"cursor-icon"},[_vm._v(_vm._s(!_vm.isMarkHover ? _vm.activeLabelMark.name || '' : ''))])]),_c('div',{staticClass:"align-center",style:([_vm.imageStyle, { width: ((_vm.maskSize.width) + "px"), height: ((_vm.maskSize.height) + "px") }]),on:{"click":_vm.labelMark,"mousedown":_vm.relabel,"mousemove":_vm.throttleHoverLandmark,"mouseup":_vm.offRelabel}})])}
var staticRenderFns = []

export { render, staticRenderFns }