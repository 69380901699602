<template>
  <div class="root tw-cursor-not-allowed" :class="{ '!tw-cursor-pointer': canClick }">
    <div class="grid-thumbnail">
      <loading-spinner v-if="item.uploading" class="loading-spinner" />
      <img
        v-else-if="(item.hasOwnProperty('uploading') && !item.uploading && !item.thumbnailUrl) || item.errorMsg"
        class="failed"
        src="../../../assets/ic-failed.svg"
        alt="fail"
      />
      <xo-img v-else-if="item.thumbnailUrl" :src="item.thumbnailUrl" class="merchandises-image" />
      <template v-if="!item.uploading">
        <img
          v-if="item.size"
          class="tw-w-[24px] tw-h-[24px] tw-absolute tw-top-1 tw-left-1"
          :class="{ 'tw-opacity-40': !canClick }"
          src="../../../assets/checkbox-on.svg"
          alt="on"
        />
        <img
          v-else
          class="checkbox tw-w-[24px] tw-h-[24px] tw-absolute tw-top-1 tw-left-1 tw-opacity-0"
          :class="{ disable: !canClick }"
          src="../../../assets/checkbox-off.svg"
          alt="off"
        />
      </template>
      <div v-if="item.size" class="size-box" :class="{ disable: !canClick }">{{ item.size }}</div>
      <v-btn v-if="!item.size && !item.uploading" icon class="btn" light @click.stop.prevent="toggleMenu">
        <v-icon :data-id="item.id">more_vert</v-icon>
      </v-btn>
    </div>
    <div class="tw-max-w-[138px] tw-text-xl tw-text-center tw-truncate" :title="item.fileName">{{ item.fileName }}</div>
    <div v-if="errorMsg" class="error-message">
      <span v-if="errorTitle" class="error-title">{{ $t(errorTitle) }}</span>
      <span class="error-content">{{ $t(errorMsg) }}</span>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '../../common/LoadingSpinner';
import XoImg from '../../common/XoImg';

export default {
  name: 'ImageItem',
  props: {
    item: Object,
    currentSize: Object,
  },
  components: {
    LoadingSpinner,
    XoImg,
  },
  computed: {
    canClick() {
      return (
        (!this.item.uploading &&
          !this.item.errorMsg &&
          this.item.id &&
          !this.item.size &&
          Object.keys(this.currentSize).length > 0) ||
        this.currentSize.name === this.item.size
      );
    },
    errorTitle() {
      return this.errorMsg ? `${this.errorMsg}_title` : '';
    },
    errorMsg() {
      return this.item.errorMsg || '';
    },
  },
  methods: {
    toggleMenu($event) {
      this.$emit('menu', $event, this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
.root {
  @apply tw-inline-block tw-mx-3 tw-my-4 tw-relative;
  &:hover {
    .btn,
    .checkbox {
      @apply tw-opacity-100;
    }
    .checkbox.disable {
      @apply tw-opacity-0;
    }
    .error-message {
      display: flex;
    }
  }
}
.grid-thumbnail {
  @apply tw-w-[138px] tw-h-[138px] tw-overflow-hidden;
  @apply tw-relative tw-inline-flex tw-justify-center tw-items-center;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
}

.loading-spinner {
  width: calc(100% - 54px);
}
.merchandises-image {
  @apply tw-absolute tw-w-full tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2;
}
.size-box {
  @apply tw-w-[26px] tw-h-[24px];
  @apply tw-absolute tw-bottom-1 tw-right-1 tw-bg-black tw-text-white tw-font-bold tw-text-center tw-uppercase;
  line-height: 24px;
  border-radius: 4px;
  &.disable {
    background-color: rgb(179, 179, 179);
  }
}
.btn {
  @apply tw-opacity-0 tw-absolute tw-rounded-t-full;
  right: -10px;
  top: -10px;
}

.failed {
  width: 46px;
  @extend .merchandises-image;
}
.error-message {
  @apply tw-hidden tw-flex-col tw-w-full tw-min-w-[200px];
  @apply tw-max-w-[340px] tw-absolute tw-p-2.5 tw-text-white tw-text-sm tw-z-10;
  top: 60%;
  left: 50%;
  background: rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
  .error-title {
    @apply tw-font-bold tw-mb-2;
  }
}
</style>
