<template>
  <div class="mini-image-root" :class="{ active: isActiveSize, open: sizeImage }">
    <div class="size-name">{{ item.name }}</div>
    <div class="image-wrapper">
      <div v-if="isActiveSize && !sizeImage" class="tw-text-black tw-text-opacity-50 tw-font-bold">
        {{ $t('no_picture') }}
      </div>
      <template v-else>
        <div class="selected-image">
          <xo-img :src="sizeImage" />
        </div>
        <div class="tw-absolute tw-bottom-0 tw-w-full tw-truncate tw-text-center" :title="fileName">
          {{ fileName }}
        </div>
        <img
          v-if="sizeImage"
          class="close-btn"
          src="../../../assets/circle-close-btn.svg"
          width="24"
          height="24"
          @click.self.stop="$emit('cancel')"
        />
      </template>
    </div>
  </div>
</template>

<script>
import XoImg from '../../common/XoImg';

export default {
  name: 'MiniImageItem',
  components: {
    XoImg,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    currentSize: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isActiveSize() {
      return this.item.id === this.currentSize.id;
    },
    sizeImage() {
      const { image } = this.item;
      return (image && image.thumbnailUrl) || '';
    },
    fileName() {
      return (this.item.image && this.item.image.fileName) || '';
    },
  },
};
</script>

<style lang="scss" scoped>
.mini-image-root {
  @apply tw-w-[240px] tw-cursor-pointer;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  &:hover {
    .close-btn {
      opacity: 1;
    }
  }
}
.size-name {
  @apply tw-h-[34px] tw-leading-8 tw-text-center tw-text-black tw-uppercase;
  background-color: rgb(244, 244, 244);
}
.image-wrapper {
  @apply tw-h-0 tw-relative tw-flex tw-justify-center tw-items-center;
  transition: height 0.2s 0.1s;
}
.selected-image {
  @apply tw-w-[175px] tw-h-[175px] tw-absolute tw-top-5 tw-left-1/2;
  @apply tw-transform tw--translate-x-1/2;
  @apply tw-flex tw-justify-center tw-overflow-hidden;
  > img {
    @apply tw-h-full;
  }
}
.close-btn {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 12px;
  top: 12px;
  opacity: 0;
  transition: opacity 0.3s;
}
.active {
  &.mini-image-root {
    border-color: #000;
  }
  .size-name {
    @apply tw-bg-black tw-text-white;
  }
  .image-wrapper {
    @apply tw-h-[244px];
    border-radius: 0 0 4px 4px;
  }
}
.open {
  .image-wrapper {
    @apply tw-h-[244px];
    border: 1px solid #c4c4c4;
    border-radius: 0 0 4px 4px;
  }
}
</style>
