<template>
  <v-app class="white">
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  created() {
    const token = localStorage.getItem('id_token');
    if (typeof token === 'string') {
      this.$http.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  },
};
</script>

<style lang="scss">
html,
body {
  overflow-y: auto;
}
* {
  font-family: 'Roboto', sans-serif;
}
.lt-toasted {
  max-width: 400px;
  .toasted {
    font-size: 14px;
    &.error {
      .action {
        color: white;
        min-width: 50px;
        text-align: center;
      }
    }
  }
}
</style>
