<template>
  <v-dialog v-model="show" persistent no-click-animation max-width="450">
    <v-card class="root">
      <!-- Dialog head -->
      <div class="head mx-auto">{{ title }}</div>
      <div class="body" v-html="content"></div>
      <!-- Edit actions -->
      <v-layout class="btn-container" justify-center align-center>
        <toggle-btn
          v-if="!disableCancel"
          :btn-title="btnCancel.btnTitle"
          :btn-color="btnCancel.btnColor"
          :btn-outline="btnCancel.btnOutline"
          :btn-size-small="true"
          @getBtn="cancel"
        />
        <toggle-btn
          :btn-title="btnConfirm.btnTitle"
          :btn-color="btnConfirm.btnColor"
          :btn-outline="btnCancel.btnOutline"
          :btn-size-small="true"
          @getBtn="confirm"
        />
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import ToggleBtn from '../../components/common/ToggleBtn';
import i18n from '../../i18n/i18n';
import hasDialog from '../../mixins/has-dialog';

export default {
  name: 'Confirm',
  mixins: [hasDialog],
  components: {
    ToggleBtn,
  },
  data: () => ({
    show: false,
    content: '',
    title: '',
    disableCancel: false,
    resolve: null,
    confirmText: i18n.t('cancel'),
    cancelText: i18n.t('confirm'),
    btnCancel: {
      btnTitle: i18n.t('cancel'),
      btnColor: '#000',
      btnOutline: true,
    },
    btnConfirm: {
      btnTitle: i18n.t('confirm'),
      btnColor: '#000',
      btnOutline: true,
    },
  }),
  methods: {
    open({ content = '', title = '', disableCancel = false, resolve }) {
      const displaying = this.show;
      if (displaying) {
        return;
      }
      this.show = true;
      this.content = content;
      this.title = title || '';
      this.disableCancel = disableCancel;
      this.resolve = resolve || null;
    },
    confirm() {
      this.show = false;
      if (typeof this.resolve === 'function') {
        this.resolve(true);
      }
    },
    cancel() {
      this.show = false;
      if (typeof this.resolve === 'function') {
        this.resolve(false);
      }
    },
  },
  watch: {
    show(nv) {
      this.toggleRootOverflowHidden(nv);
    },
  },
};
</script>

<style lang="scss" scoped>
.root {
  overflow: hidden;
  position: relative;
}
.head {
  margin: 0 auto;
  padding: 8px 0 9px 0;
  text-align: center;
  font-size: 16px;
  color: #fff;
  background-color: #000;
}
.body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  text-align: center;
  position: relative;
  padding: 20px;
}
.btn-container {
  width: 100%;
  height: 60px;
  background-color: white;
}
</style>
