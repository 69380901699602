<template>
  <div class="garment-item" @click="click" data-test-id="grid-item">
    <!-- TODO: make what to display as props -->
    <!-- List Item -->
    <v-flex v-if="canSelect" class="checkbox tw-opacity-0" :class="{ 'tw-opacity-100': item.selected }">
      <v-checkbox hide-details v-model="item.selected" color="cyan light-1" @click.stop="select"></v-checkbox>
    </v-flex>
    <!-- Thumbnail -->
    <v-flex xs3 class="tw-flex tw-items-center">
      <img class="tw-mr-[60px]" :src="itemImg" width="50" :alt="item.name" :style="thumbnailStyle" />
      {{ item.sn || item.name }}
    </v-flex>
    <!-- File Type -->
    <v-flex xs3 class="">
      {{ itemCategory }}
    </v-flex>
    <v-flex xs1>
      {{ total }}
    </v-flex>
    <v-flex xs1>
      {{ uploaded }}
    </v-flex>
    <v-flex xs4>
      {{ item.updatedAt | toDateYMD }}
    </v-flex>
    <v-spacer></v-spacer>
    <v-btn icon class="btn tw-opacity-0 !tw-absolute tw-right-0" @click.stop.prevent="toggleMenu">
      <v-icon :data-id="item.id">more_vert</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { DateTimeFormatter, getRotation } from '@/modules/utils';
import folderIcon from '../../../assets/folder-icon.svg';
import emptyImage from '../../../assets/home/empty-image.svg';

export default {
  name: 'measurementItem',
  props: {
    canSelect: Boolean,
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  filters: {
    toDateYMD(value) {
      if (value) {
        try {
          return DateTimeFormatter.format(new Date(value));
        } catch (e) {
          return '';
        }
      }
      return '';
    },
  },
  data: () => ({}),
  methods: {
    select() {
      this.$emit('select', this.item);
    },
    click() {
      this.$emit('click', this.item);
    },
    toggleMenu($event) {
      this.$emit('menu', $event, this.item);
    },
    // onImageLoad($event) {
    //   $event.target.style.visibility = 'visible'; // eslint-disable-line
    // },
  },
  computed: {
    complete() {
      return `${this.item.labeled ? Math.round((this.item.labeled / this.item.total) * 100) : 0}%`;
    },
    itemImg() {
      const { isDir, thumbnailUrl } = this.item || {};
      if (thumbnailUrl) return thumbnailUrl;

      return isDir ? folderIcon : emptyImage;
    },
    itemCategory() {
      // TODO: translate category or item type here
      const { category, isProject } = this.item || {};
      if (category) return category;

      return isProject ? this.$t('projects') : this.$t('folder');
    },
    total() {
      const { isProject, total } = this.item || {};
      return total || (isProject ? '' : 0);
    },
    uploaded() {
      const { isProject, uploaded } = this.item || {};
      return uploaded || (isProject ? '' : 0);
    },
    thumbnailStyle() {
      const rotation = getRotation(this.item.rotation);
      if (!rotation) return {};

      return { transform: `rotate(${rotation}deg)` };
    },
  },
};
</script>

<style scoped lang="scss">
.garment-item {
  @apply tw-pr-[38px] tw-min-h-[78px] tw-relative tw-flex tw-justify-between tw-items-center tw-cursor-pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  &:hover {
    .checkbox,
    .btn {
      @apply tw-opacity-100;
    }
  }
}
.checkbox {
  width: 40px;
  ::v-deep {
    .v-input--checkbox {
      padding-top: 0;
    }
  }
}
</style>
