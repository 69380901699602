<template>
  <v-app>
    <v-navigation-drawer v-if="canShowMenu" :mobile-break-point="0" fixed temporary v-model="drawer">
      <side-menu @close="closeDrawer" />
    </v-navigation-drawer>
    <headroom
      :zIndex="zIndex"
      :class="{
        'drawer-shown': drawer,
        'headroom--not-top': shortHeadroom,
      }"
      :speed="100"
      :down-tolerance="10000"
    >
      <img v-if="banner" class="banner" :src="bannerSrc" alt="Banner" />
      <div v-if="canShowMenu" class="menu" @click.stop="drawer = !drawer"></div>
      <div class="page-title">{{ pageTitle }}</div>
      <div class="placeholder"></div>
    </headroom>
    <v-content :class="{ short: shortHeadroom }">
      <slot />
    </v-content>
  </v-app>
</template>

<script>
import { headroom } from 'vue-headroom';
import SideMenu from './SideMenu';

import LabelingTool from '@/assets/banner.svg';

const BANNERS = {
  LabelingTool,
};

export default {
  name: 'App',
  components: {
    headroom,
    SideMenu,
  },
  props: {
    zIndex: {
      type: Number,
      default: 5,
    },
    hideMenu: Boolean,
    pageTitle: String,
    shortHeadroom: {
      type: Boolean,
      default: false,
    },
    banner: {
      type: String,
      default: 'LabelingTool',
    },
  },
  data: () => ({
    drawer: false,
  }),
  computed: {
    canShowMenu() {
      return !this.hideMenu;
    },
    bannerSrc() {
      return BANNERS[this.banner] || '';
    },
  },
  methods: {
    closeDrawer() {
      this.drawer = false;
    },
  },
};
</script>

<style scoped lang="scss">
$shortHeadroomHeight: 67px;
$tallHeadroomHeight: 132px;

.app-container {
  height: 100%;
}
button.toolbar__side-icon {
  align-self: start;
}
.toolbar__content {
  justify-content: space-between;
  .placeholder {
    width: 36px;
    display: block;
  }
}
.toolbar__title {
  font-size: 36px;
  font-weight: bold;
  padding: 20px 0;
  margin: 0;
}
.headroom {
  position: relative;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
  background-color: #2a2a2a;
  background-size: cover;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: $tallHeadroomHeight;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  z-index: 10 !important;
  overflow: hidden;
  ::v-deep {
    .v-content__wrap {
      background-color: #ededed;
      padding-top: $tallHeadroomHeight;
    }
    // overriding tabs styles
    .v-tabs {
      flex-basis: 100%;
      align-self: flex-end;
    }
    .v-tabs__bar {
      background: none;
    }
    .tab-text {
      font-size: 18px;
      font-weight: normal;
      line-height: normal;
      color: white;
      text-transform: none;
      white-space: pre;
    }
    .active-tab {
      .tab-text {
        color: #2dbdcb;
      }
    }
    .v-tabs__container {
      justify-content: center;
      max-height: 57px;
    }
    .v-tabs__div {
      margin: 0 70px;
      min-width: 120px;
      max-width: none;
    }
    .v-tabs__item {
      padding: 0;
      opacity: 1;
      border-bottom: 5px solid transparent;
      overflow: auto;
      &.active-tab {
        border-bottom-color: #2dbdcb;
      }
    }
  }
}
.menu,
.placeholder {
  width: 25px;
  height: 25px;
  margin: 22px 21px;
}
.menu {
  z-index: 1;
  background-image: url('../../assets/ic-menu-n.svg');
  background-size: contain;
  background-position: center;
  cursor: pointer;
  &:hover {
    background-image: url('../../assets/ic-menu-h.svg');
  }
}
.page-title {
  margin-top: 6px;
  font-size: 36px;
  font-weight: bold;
}
.headroom--not-top {
  height: $shortHeadroomHeight;
  .banner {
    visibility: hidden;
  }
  .placeholder {
    display: none;
  }
  .menu {
    position: absolute;
    top: -6px;
    left: 0;
  }
  .page-title {
    position: absolute;
    font-size: 24px;
    left: 91px;
    top: 6px;
  }
}
.banner {
  position: absolute;
  min-height: $tallHeadroomHeight;
  z-index: -1;
}
@media screen and (min-width: 1921px) {
  .banner {
    left: 50%;
    transform: translateX(-50%) scale(1.5);
  }
}
@media screen and (min-width: 2800px) {
  .banner {
    transform: translateX(-50%) scale(2);
  }
}
@media screen and (min-width: 3800px) {
  .banner {
    transform: translateX(-50%) scale(2.5);
  }
}
.short {
  ::v-deep .v-content__wrap {
    padding-top: $shortHeadroomHeight;
  }
}
</style>

<style lang="scss">
html,
body,
.v-content__wrap {
  background-color: #ededed;
}
.v-content__wrap {
  padding-top: 132px;
  .v-tabs__items {
    height: 100%;
  }
  .v-tabs__content {
    min-height: 500px;
    overflow: auto;
    height: 100%;
  }
}
.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--temporary {
  z-index: 20;
}
</style>
