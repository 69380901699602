<template>
  <button
    @click="!disabled && $emit('click', $event)"
    class="t-btn"
    type="button"
    :class="{
      'box-shadow': shadow,
      't-primary': primary,
      disabled: disabled,
      blueOnHover: blueOnHover,
      round: round,
      'text-black': black,
      'text-white': white,
    }"
    :style="{ 'min-width': `${minWidth}px` }"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'TButton',
  props: {
    primary: {
      type: Boolean,
      default: false,
    },
    black: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: [Number, String],
      default: 142,
    },
    blueOnHover: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
$BLUE: #2dbdcb;
$HEIGHT: 28px;
.t-btn {
  height: $HEIGHT;
  font-weight: 700;
  border: 1px solid $BLUE;
  color: $BLUE;
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &.box-shadow {
    box-shadow: 1px 1px 1px 0 rgba(140, 140, 140, 0.2);
  }
  &.round {
    border-radius: calc(#{$HEIGHT} / 2);
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
  &.text-black {
    border-color: black;
    color: black;
    font-weight: 400;
    &:not(.disabled) {
      &:hover,
      &:focus {
        border-color: $BLUE;
      }
      &:active {
        color: white;
        background-color: $BLUE;
      }
    }
  }
  &.text-white {
    border-color: black;
    background-color: black;
    color: white;
    font-weight: 400;
    &:not(.disabled) {
      &:hover,
      &:focus {
        border-color: $BLUE;
      }
      &:active {
        border-color: $BLUE;
        background-color: $BLUE;
      }
    }
  }
  &.t-primary {
    border: none;
    color: white;
    background-color: $BLUE;
    &:not(.disabled) {
      &:hover,
      &:focus {
        background-color: rgb(112, 211, 225);
      }
      &:active {
        background-color: rgb(73, 149, 160);
      }
    }
  }
  &.blueOnHover {
    &:hover {
      border-color: $BLUE;
      color: $BLUE;
    }
  }
}
</style>
