import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Loading from 'vue-loading-overlay';
import uploader from 'vue-simple-uploader';
import VueTruncate from 'vue-truncate-filter';
import VueEasytable from 'vue-easytable';
import VueKonva from 'vue-konva';
import Toasted from 'vue-toasted';

import longPress from './modules/longPress';
import i18n from './i18n/i18n';
import './plugins/vuetify';
import Confirm from './plugins/Confirm';
import Snackbar from './plugins/Snackbar';
import LoadingSpinner from './plugins/LoadingSpinner';
import ToastMessage from './plugins/ToastMessage';
import App from './App';
import router from './router/index';
import store from './store/index';

import 'vue-easytable/libs/theme-default/index.css';
import './tailwind.css';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.config.productionTip = false;

Vue.use(
  VueAxios,
  axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
  })
);
Vue.use(Toasted, {
  position: 'bottom-left',
  duration: 0,
  keepOnHover: true,
  containerClass: 'lt-toasted',
  action: {
    text: i18n.t('dismiss'),
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
});
Vue.use(VueTruncate);
Vue.use(VueEasytable);
Vue.use(Snackbar);
Vue.use(Confirm);
Vue.use(LoadingSpinner);
Vue.use(ToastMessage);
Vue.use(uploader);
Vue.use(VueKonva, { prefix: 'k' });
Vue.use(Loading, {
  canCancel: false,
  color: '#2dbdcb',
  loader: 'bars', // spinner/dots/bars
  width: 50,
  height: 50,
  backgroundColor: 'transparent',
  isFullPage: true,
  opacity: 0.8,
});

/**
 * Custom directives
 */
Vue.directive('longpress', longPress);
/**
 * Setup global event bus
 */
const EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
  $bus: {
    get() {
      return EventBus;
    },
  },
});
document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
});
