<template>
  <div class="tw-h-full tw-pt-6 2xl:tw-pt-16" @click="$bus.$emit('cancel-measurement-mode')">
    <div class="tw-w-11/12 tw-h-full tw-mx-auto tw-my-0 2xl:tw-w-9/12">
      <div class="info-wrapper">
        <div class="tw-flex tw-items-center tw-mb-7">
          <div class="tw-w-28 tw-truncate tw-mr-4">{{ $t('category') }}</div>
          <div class="tw-flex-1 tw-truncate">
            {{ merchandiseInfo.category }}
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-mb-7">
          <div class="tw-w-28 tw-truncate tw-mr-4">{{ $t('sn') }}</div>
          <div class="tw-flex-1 tw-truncate">{{ merchandiseInfo.sn }}</div>
        </div>
        <div class="tw-flex tw-items-center tw-mb-8">
          <div class="tw-w-28 tw-truncate tw-mr-4">{{ $t('product_name') }}</div>
          <div class="tw-flex-1 tw-truncate" :title="merchandiseInfo.name">
            {{ merchandiseInfo.name }}
          </div>
        </div>
      </div>
      <div ref="tableWrapper" class="tw-relative tw-h-2/3 2xl:tw-h-4/5">
        <ve-table
          ref="table"
          class="size-table"
          border-x
          border-y
          :row-style-option="{ hoverHighlight: false }"
          :style="'width: 100%'"
          :max-height="tableHeight"
          :fixed-footer="false"
          :fixed-header="false"
          :scroll-width="scrollWidth"
          :columns="columns"
          :tableData="tableData"
          :footer-data="footerData"
          :cell-style-option="cellStyleOption"
          :event-custom-option="eventCustomOption"
        />
        <div class="tw-flex tw-justify-end tw-mt-4">
          <div class="tw-flex tw-text-center">
            <div
              class="switch-btn"
              :class="{ active: !UnitConversion.useInch }"
              @click="UnitConversion.setUseInch(false)"
            >
              {{ $t('cm') }}
            </div>
            <div
              class="switch-btn"
              :class="{ active: UnitConversion.useInch }"
              @click="UnitConversion.setUseInch(true)"
            >
              {{ $t('inch') }}
            </div>
          </div>
        </div>
        <div
          ref="mask"
          class="upload-img-mask"
          :class="{ 'no-image-selected': !hasImageSelected }"
          :style="{
            width: `${tableWidth - tableTypeWidth}px`,
            height: currentEditSize ? '91px' : '85px',
            transform: `translateX(${scrollLeft}px)`,
          }"
          @click="openUploadImageDialog"
        >
          <img
            class="tw-opacity-0"
            :class="{ 'tw-opacity-100': !hasImageSelected }"
            src="../../../assets/home/measurement/ic-edit.svg"
            alt="edit"
          />
          <div class="tw-opacity-0" :class="{ 'tw-opacity-100': !hasImageSelected }">{{ $t('set_marker_image') }}</div>
        </div>
        <div ref="activeBox" class="active-box" :style="boxStyle" />
        <v-menu v-model="showMenu" offset-y absolute :position-x="menuX" :position-y="menuY">
          <action-list :actions="tableActions" @take-action="takeAction" />
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { getRotation } from '@/modules/utils';

import ActionList from '../explorer/ActionList';
import UnitConversion from '../../../modules/unit-conversion';

import moreIcon from '../../../assets/home/measurement/ic_more.svg';
import hideIcon from '../../../assets/home/measurement/ic-hide.svg';
import openIcon from '../../../assets/home/measurement/ic-open.svg';
import deleteIcon from '../../../assets/home/measurement/ic-delete.svg';
import renameIcon from '../../../assets/home/measurement/ic-rename.svg';
import emptyImageIcon from '../../../assets/home/measurement/empty-image-error.svg';

export default {
  name: 'MerchandisesTable',
  components: {
    ActionList,
  },
  props: {
    merchandiseInfo: Object,
    ready: Boolean,
    tab: Number,
  },
  data: vm => ({
    cellStyleOption: {
      bodyCellClass: ({ column, rowIndex }) => {
        const isLastColumn = vm.columns[vm.columns.length - 1].field === column.field;
        if (rowIndex === 0) {
          if (column.field === vm.currentEditSize) {
            return `active-sizes table-head tw-cursor-pointer ${isLastColumn ? 'last-column' : ''}`;
          }
          return 'table-head tw-cursor-pointer';
        }
        if (column.field === vm.currentEditSize) {
          return `active-sizes table-td tw-cursor-pointer ${isLastColumn ? 'last-column' : ''}`;
        }
        return 'table-td tw-cursor-pointer';
      },
      headerCellClass: ({ column }) => {
        const isLastColumn = vm.columns[vm.columns.length - 1].field === column.field;
        if (column.field === vm.currentEditSize) {
          return `active-sizes tw-h-[78px] tw-cursor-pointer ${isLastColumn ? 'last-column' : ''}`;
        }
        return 'tw-h-[78px] tw-cursor-pointer';
      },
      footerCellClass: ({ column, row }) => {
        const isLastColumn = vm.columns[vm.columns.length - 1].field === column.field;
        if (column.field === vm.currentEditSize) {
          if (row.item.id === vm.activeManualMeasurement.id) {
            return `active-sizes active-manual-measurement tw-cursor-pointer ${isLastColumn ? 'last-column' : ''}`;
          }
          return `active-sizes table-td tw-cursor-pointer ${isLastColumn ? 'last-column' : ''}`;
        }
        return 'table-td tw-cursor-pointer';
      },
    },
    eventCustomOption: {
      bodyCellEvents: ({ column }) => {
        return {
          mouseenter: () => {
            if (column !== 'type' && vm.hoverSizeName !== column) {
              vm.hoverSizeName = column.title; // eslint-disable-line
            }
          },
          mouseleave: () => {
            vm.hoverSizeName = ''; // eslint-disable-line
          },
        };
      },
      footerCellEvents: ({ column }) => {
        return {
          mouseenter: () => {
            if (column !== 'type' && vm.hoverSizeName !== column) {
              vm.hoverSizeName = column.title; // eslint-disable-line
            }
          },
          mouseleave: () => {
            vm.hoverSizeName = ''; // eslint-disable-line
          },
        };
      },
    },
    hoverSizeName: '',
    tableTypeWidth: 0,
    tableWidth: 0,
    menuX: 0,
    menuY: 0,
    actionMenuTarget: null,
    showMenu: false,
    tableActions: [
      {
        event: 'hide',
        icon: hideIcon,
        title: vm.$t('hide_column'),
        disable: false,
      },
      {
        event: 'delete',
        icon: deleteIcon,
        title: vm.$t('delete_column'),
        disable: false,
      },
      {
        event: 'rename',
        icon: renameIcon,
        title: vm.$t('rename'),
        disable: false,
      },
    ],
    hoverManualMeasurementData: {},
    UnitConversion,
    scrollLeft: 0,
    tableHeight: 0,
  }),
  computed: {
    ...mapGetters('labelingTool/measurementTool', [
      'currentEditSize',
      'sizesList',
      'categoriesMeasurements',
      'customizedMeasurements',
      'activeManualMeasurement',
      'hadActiveManualMeasurement',
      'measurementLandmarks',
      'originalMeasurementLandmarks',
    ]),
    columns() {
      const columns = [
        {
          field: 'type',
          key: 'type',
          title: this.$t('pictures'),
          align: 'center',
          fixed: 'left',
          width: Number(this.tableTypeWidth),
          renderBodyCell: ({ row, column, rowIndex }) => {
            const data = row[column.field];
            if (rowIndex === 0) {
              return <span>{data}</span>;
            }
            const visible = row.visible ? '' : 'tw-opacity-20';
            return (
              <div class="type">
                <span class={visible} title={data}>
                  {data}
                </span>
                <img
                  class="more-icon"
                  src={moreIcon}
                  onClick={$event => this.toggleMenu(data, $event, row.isCustomized, row.visible)}
                />
              </div>
            );
          },
          renderFooterCell: ({ row, column }) => {
            const data = row[column.field];
            const visible = row.visible ? '' : 'tw-opacity-20';
            let status = '';
            if (row.item.id === this.activeManualMeasurement.id || row.item.id === this.hoverManualMeasurementData.id) {
              status = 'active';
            }
            return (
              <div class="type">
                <div class={`icon-manual-status ${status} `} />
                <div class={`${visible} tw-w-[44px] 2xl:tw-w-[70px] tw-truncate`} title={data}>
                  {data}
                </div>
                <img
                  class="more-icon"
                  src={moreIcon}
                  onClick={$event => this.toggleMenu(data, $event, row.isCustomized, row.visible)}
                />
              </div>
            );
          },
        },
      ];
      const columnsWidth = this.sizesList.length <= 5 ? `${80 / this.sizesList.length}%` : '20%';
      this.sizes.forEach(item => {
        const columnItem = {
          field: item.name,
          key: item.name,
          title: item.name,
          align: 'center',
          width: columnsWidth,
          renderHeaderCell: () => {
            const url = item.image ? item.image.thumbnailUrl : '';
            if (url) {
              const style = {};
              const rotation = getRotation(item.image.rotation);
              if (rotation) {
                style.transform = `rotate(${rotation}deg)`;
              }
              return <img src={url} crossorigin="anonymous" height="78" class="tw-mt-[6px]" style={style} />;
            }
            if (this.hasImageSelected) {
              return <img src={emptyImageIcon} width="20" height="20" />;
            }
            return '';
          },
          renderBodyCell: ({ row, column, rowIndex }) => {
            const data = row[column.field];
            const visible = row.visible ? '' : 'tw-opacity-20';
            if (rowIndex === 0) {
              const className = this.currentEditSize === data ? 'active' : '';
              const hoverStyle = column.title === this.hoverSizeName ? 'tw-font-bold' : '';
              return (
                <div
                  class={`tw-absolute tw-w-full tw-h-full tw-top-0 tw-left-0 tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-uppercase ${className} ${hoverStyle}`}
                  onClick={e => {
                    e.stopPropagation();
                    this.setCurrentEditSize(data, false);
                  }}
                >
                  {data}
                </div>
              );
            }
            if (data === 0) {
              return (
                <span
                  class={`tw-block !tw-text-center tw-cursor-pointer ${visible}`}
                  onClick={e => {
                    e.stopPropagation();
                    this.setCurrentEditSize(column.title, false);
                  }}
                >
                  - -
                </span>
              );
            }
            if (!this.UnitConversion.useInch) {
              return (
                <span
                  class={`tw-cursor-pointer ${visible}`}
                  onClick={e => {
                    e.stopPropagation();
                    this.setCurrentEditSize(column.title, false);
                  }}
                >
                  <span class="tw-font-medium">{this.UnitConversion.switchUnits(data)}</span>
                  <span class="tw-font-light"> {this.$t('cm')}</span>
                </span>
              );
            }
            return (
              <span
                class={`tw-cursor-pointer ${visible}`}
                onClick={e => {
                  e.stopPropagation();
                  this.setCurrentEditSize(column.title, false);
                }}
              >
                <span class="tw-font-medium">{this.UnitConversion.switchUnits(data)}</span>
                <span class="tw-font-light"> {this.$t('inch')}</span>
              </span>
            );
          },
          renderFooterCell: ({ row, column }) => {
            const data = row[column.field];
            const visible = row.visible ? '' : 'tw-opacity-20';
            const isCurrent =
              row.item.id === this.activeManualMeasurement.id && column.field === this.currentEditSize
                ? 'current-measurement'
                : '';
            if (!data || data === 0) {
              if (!isCurrent) {
                const isDisable = row.item.sizes.find(el => el.name === column.field).hasImage ? '' : 'tw-opacity-20';
                return (
                  <span
                    class={`tw-block !tw-text-center ${visible} ${isDisable}`}
                    onClick={e => {
                      e.stopPropagation();
                      this.setManualMeasurementsMode(row.item, column.field);
                    }}
                  >
                    <img src={emptyImageIcon} width="20" height="20" class="tw-cursor-pointer" />
                  </span>
                );
              }

              return (
                <span
                  class={`tw-block !tw-text-center ${visible}`}
                  onClick={e => {
                    e.stopPropagation();
                    this.setManualMeasurementsMode(row.item, column.field);
                  }}
                >
                  - -
                </span>
              );
            }
            if (!this.UnitConversion.useInch) {
              return (
                <span
                  class={visible}
                  onClick={e => {
                    e.stopPropagation();
                    this.setManualMeasurementsMode(row.item, column.field);
                  }}
                >
                  <span class="tw-font-medium">{this.UnitConversion.switchUnits(data)}</span>
                  <span class="tw-font-light"> {this.$t('cm')}</span>
                </span>
              );
            }
            return (
              <span
                class={visible}
                onClick={e => {
                  e.stopPropagation();
                  this.setManualMeasurementsMode(row.item, column.field);
                }}
              >
                <span class="tw-font-medium">{this.UnitConversion.switchUnits(data)}</span>
                <span class="tw-font-light"> {this.$t('inch')}</span>
              </span>
            );
          },
        };
        columns.push(columnItem);
      });
      return columns;
    },
    hasImageSelected() {
      return this.sizes.some(item => item.image);
    },
    sizes() {
      return this.sizesList.map(item => ({
        id: item.id,
        name: item.name,
        image: item.image,
      }));
    },
    tableData() {
      const tableData = this.categoriesMeasurements.map(item => ({
        type: this.$t(`${item.definition}`),
        isCustomized: false,
      }));
      const sizeType = { type: this.$t('garment_measure.size') };
      this.sizesList.forEach(size => {
        this.$set(sizeType, size.name, size.name);
        if (size.measurements.length > 0) {
          const isReset = this.currentEditSize === size.name && this.measurementLandmarks.every(item => !item.labeled);
          size.measurements.forEach(item => {
            const index = tableData.findIndex(data => data.type === this.$t(`${item.definition}`));
            this.$set(tableData[index], size.name, isReset ? 0 : item.value);
            this.$set(tableData[index], 'visible', item.visible);
          });
        } else {
          this.categoriesMeasurements.forEach((item, index) => {
            this.$set(tableData[index], size.name, 0);
            if (typeof tableData[index].visible === 'boolean') return;
            this.$set(tableData[index], 'visible', true);
          });
        }
      });

      tableData.unshift(sizeType);
      return tableData;
    },
    footerData() {
      const footerData = [];
      if (this.customizedMeasurements.length > 0) {
        this.customizedMeasurements.forEach(item => {
          const data = {
            type: item.name,
            visible: item.visible,
            isCustomized: true,
            item: cloneDeep(item),
          };
          item.sizes.forEach((size, index) => {
            const isReset = size.landmarks.length === 0;
            this.$set(data, size.name, isReset ? 0 : size.value);
            const filterData = this.sizesList.find(element => element.name === size.name);
            this.$set(data.item.sizes[index], 'hasImage', !!filterData.image);
          });
          footerData.push(data);
        });
      }
      return footerData;
    },
    boxStyle() {
      let style = {};
      const width = (this.scrollWidth - this.tableTypeWidth) / this.sizes.length;
      const index = this.sizes.findIndex(data => data.name === this.currentEditSize);
      if (index >= 0) {
        style = {
          width: `${width}px`,
          transform: `translateX(-${this.scrollLeft}px)`,
        };
        if (index === 0) {
          const table = document.querySelector('#table');
          let scrollbarWidth = 0;
          if (table && table.scrollHeight > table.clientHeight) {
            scrollbarWidth = 17;
          }
          const multiple = this.sizes.length >= 5 ? 4 : this.sizes.length - 1;
          const boxWidth = width - this.scrollLeft;
          style.right = `${width * multiple + scrollbarWidth}px`;
          style.width = `${boxWidth}px`;
        } else {
          style.left = `${this.tableTypeWidth + width * index}px`;
        }
        if (this.sizes.length > 5 && index === this.sizes.length - 1) {
          style.width = `${this.scrollLeft}px`;
        }
      } else {
        style = { width: 0, left: 0, opacity: 0 };
      }
      return style;
    },
    scrollWidth() {
      if (this.sizesList.length <= 5) {
        return this.tableWidth;
      }
      const width = ((this.tableWidth - this.tableTypeWidth) / 5) * this.sizesList.length;
      return this.tableTypeWidth + width;
    },
  },
  watch: {
    async ready(nv) {
      if (nv) {
        this.getTableHeight();
        await this.setTableTypeWidth();
        this.editUnmarkedSize();
      } else {
        this.SET_CURRENT_EDIT_SIZE('');
      }
    },
  },
  mounted() {
    this.$refs.table.$el.firstChild.setAttribute('id', 'table');
    const table = document.querySelector('#table');
    window.addEventListener('resize', () => {
      if (this.tab !== 0) return;
      this.setTableTypeWidth();
      this.getTableHeight();
    });
    table.addEventListener('scroll', el => {
      this.scrollLeft = el.srcElement.scrollLeft;
      this.$forceUpdate();
    });

    this.$nextTick(() => {
      this.$refs.mask.parentNode.removeChild(this.$refs.mask);
      this.$refs.table.$el.children[0].appendChild(this.$refs.mask);
      this.$refs.activeBox.parentNode.removeChild(this.$refs.activeBox);
      this.$refs.table.$el.appendChild(this.$refs.activeBox);
    });
    this.$bus.$on('setHoverManualMeasurement', data => {
      this.hoverManualMeasurementData = data;
    });
  },
  beforeDestroy() {
    window.addEventListener('resize', () => {
      if (this.tab !== 0) return;
      this.setTableTypeWidth();
      this.getTableHeight();
    });
    const table = document.querySelector('#table');
    table.addEventListener('scroll', el => {
      this.scrollLeft = el.srcElement.scrollLeft;
      this.$forceUpdate();
    });
    this.$bus.$off('setHoverManualMeasurement', data => {
      this.hoverManualMeasurementData = data;
    });
  },
  methods: {
    ...mapMutations('labelingTool/measurementTool', [
      'SET_CURRENT_EDIT_SIZE',
      'SET_SIZES_LIST',
      'SET_SHOW_UPLOAD_IMAGE_DIALOG',
      'SET_CUSTOMIZED_MEASUREMENTS',
      'SET_ACTIVE_MANUAL_MEASUREMENTS',
      'SET_CATEGORIES_MEASUREMENTS',
    ]),
    getTableHeight() {
      this.tableHeight = this.$refs.tableWrapper.clientHeight;
    },
    setTableTypeWidth() {
      if (!this.ready) return false;
      return new Promise(resolve => {
        const table = document.querySelector('#table');
        if (!table) {
          this.setTableTypeWidth();
          return;
        }
        this.$nextTick(() => {
          setTimeout(() => {
            this.tableWidth = table.clientWidth;
            this.tableTypeWidth = this.tableWidth * 0.2;
            resolve();
          }, 1000);
        });
      });
    },
    toInch(value) {
      return Number(value) * 0.393700787;
    },
    toFixed(value) {
      return parseFloat(Number(value).toFixed(2));
    },
    setCurrentEditSize(size, isManual = false) {
      return new Promise(resolve => {
        const hadImage = !!this.sizesList.find(item => item.name === size).image;

        if (!hadImage) {
          this.openUploadImageDialog();
          return;
        }
        const result = new Promise(res => {
          this.$bus.$emit('cancel-measurement-mode', res);
        });
        result.then(reso => {
          if (isManual) {
            if (this.currentEditSize === size) {
              if (reso) {
                this.SET_CURRENT_EDIT_SIZE(size);
                resolve(reso);
              }
              return;
            }
            this.SET_CURRENT_EDIT_SIZE(size);
            resolve(reso);
            return;
          }
          if (!this.hadActiveManualMeasurement) {
            const isLabeling =
              this.measurementLandmarks.some(item => item.labeled) &&
              this.originalMeasurementLandmarks.every(item => !item.labeled);

            if (isLabeling) {
              if (reso) {
                this.SET_CURRENT_EDIT_SIZE(size);
              }
            } else {
              this.SET_CURRENT_EDIT_SIZE(size);
            }
            resolve(reso);
            return;
          }
          if (reso) {
            this.SET_CURRENT_EDIT_SIZE(size);
          }
        });
      });
    },
    toggleMenu(item, e, isCustomized, visible) {
      this.showMenu = false;
      setTimeout(() => {
        this.actionMenuTarget = { name: item, isCustomized };
        this.tableActions[0].icon = visible ? hideIcon : openIcon;
        this.tableActions[0].title = visible ? this.$t('hide_column') : this.$t('show_column');
        this.tableActions[1].disable = !isCustomized;
        this.tableActions[2].disable = !isCustomized;
        this.menuX = e.clientX;
        this.menuY = e.clientY;
        this.showMenu = true;
      }, 100);
    },
    takeAction(action) {
      switch (action.event) {
        case 'hide':
          this.hideLine();
          break;
        case 'delete':
          this.deleteLine();
          break;
        case 'rename':
          this.rename();
          break;
        default:
      }
    },
    async hideLine() {
      this.$loadingSpinner.open();

      if (!this.actionMenuTarget.isCustomized) {
        const requests = [];
        const cloneSizeList = cloneDeep(this.sizesList);
        const cloneCategoriesMeasurements = cloneDeep(this.categoriesMeasurements);
        let measurementVisible;
        cloneSizeList.forEach(size => {
          if (!size.measurements.length) return;
          const index = size.measurements.findIndex(item => item.key === this.actionMenuTarget.name);
          if (typeof measurementVisible !== 'boolean') {
            measurementVisible = !size.measurements[index].visible;
          }
          this.$set(size.measurements[index], 'visible', measurementVisible);
          this.$set(cloneCategoriesMeasurements[index], 'visible', measurementVisible);
          const request = this.$http.post(`/api/v1/merchandises/${this.merchandiseInfo.id}/sizes/${size.name}`, {
            measurements: size.measurements,
          });
          requests.push(request);
        });
        try {
          await Promise.all(requests);
          this.SET_SIZES_LIST(cloneSizeList);
          this.SET_CATEGORIES_MEASUREMENTS(cloneCategoriesMeasurements);
        } catch (e) {
          console.log(e.message);
          this.$toastMessage.info(e.message, 124, 'left');
        }
      } else {
        const cloneCustomizedMeasurements = cloneDeep(this.customizedMeasurements);
        const index = cloneCustomizedMeasurements.findIndex(item => item.name === this.actionMenuTarget.name);
        const visible = !cloneCustomizedMeasurements[index].visible;
        try {
          await this.$http.put(`/api/v1/customized_measurements/${cloneCustomizedMeasurements[index].id}`, { visible });
          this.$set(cloneCustomizedMeasurements[index], 'visible', visible);
          this.SET_CUSTOMIZED_MEASUREMENTS(cloneCustomizedMeasurements);
        } catch (e) {
          console.log(e.message);
          this.$toastMessage.info(e.message, 124, 'left');
        }
      }
      this.showMenu = false;
      this.$loadingSpinner.close();
    },
    async deleteLine() {
      const canDelete = await this.$confirm.open(`確定要將‘${this.actionMenuTarget.name}’刪除嗎？`, '刪除手工量測');
      if (canDelete) {
        const cloneCustomizedMeasurements = cloneDeep(this.customizedMeasurements);
        const index = cloneCustomizedMeasurements.findIndex(item => item.name === this.actionMenuTarget.name);
        try {
          this.$loadingSpinner.open();
          await this.$http.delete(`/api/v1/customized_measurements/${cloneCustomizedMeasurements[index].id}`);
          cloneCustomizedMeasurements.splice(index, 1);
          this.SET_CUSTOMIZED_MEASUREMENTS(cloneCustomizedMeasurements);
          this.SET_ACTIVE_MANUAL_MEASUREMENTS({});
        } catch (e) {
          console.log(e.message);
          this.$toastMessage.info(e.message, 124, 'left');
        } finally {
          this.showMenu = false;
          this.$loadingSpinner.close();
        }
      }
    },
    rename() {
      const cloneCustomizedMeasurements = cloneDeep(this.customizedMeasurements);
      const measurement = cloneCustomizedMeasurements.find(item => item.name === this.actionMenuTarget.name);
      const data = {
        name: measurement.name,
        definition: measurement.definition,
        id: measurement.id,
      };
      this.$bus.$emit('open-manual-measurement', true, data);
    },
    openUploadImageDialog() {
      const result = new Promise(res => {
        this.$bus.$emit('cancel-measurement-mode', res);
      });
      result.then(res => {
        const isLabeling =
          this.measurementLandmarks.some(item => item.labeled) &&
          this.originalMeasurementLandmarks.every(item => !item.labeled);
        if (isLabeling && !res) return;
        window.history.pushState({ dialog: 'select-image' }, 'popup-dialog');
        this.SET_SHOW_UPLOAD_IMAGE_DIALOG(true);
      });
    },
    editUnmarkedSize() {
      const unmarkedSize = this.sizesList.find(item => {
        return item.measurements.every(data => data.value <= 0);
      });
      if (unmarkedSize && unmarkedSize.name && unmarkedSize.image) {
        this.setCurrentEditSize(unmarkedSize.name);
      }
    },
    async setManualMeasurementsMode(data, size) {
      await this.setCurrentEditSize(size, true).then(() => {
        const measurement = this.customizedMeasurements.find(item => item.id === data.id);
        this.SET_ACTIVE_MANUAL_MEASUREMENTS(measurement);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-wrapper {
  border-bottom: 1px solid rgb(196, 196, 196);
  margin-bottom: 16px;
  @screen 2xl {
    margin-bottom: 30px;
  }
}

.switch-btn {
  @apply tw-rounded-full tw-cursor-pointer tw-text-black;
  width: 42px;
  height: 23px;
  line-height: 23px;
  transition: color 0.3s, background-color 0.3s;
  &:hover {
    background-color: #c1c1c1;
  }
  &.active {
    @apply tw-font-bold tw-text-white;
    background-color: #000000;
  }
}
.size-table {
  @apply tw-max-h-full tw-relative;
  ::v-deep {
    .ve-table-container {
      height: initial !important;
      z-index: 1;
      // -ms-overflow-style: none; /* for Internet Explorer, Edge */
      // scrollbar-width: none; /* for Firefox */

      // &::-webkit-scrollbar {
      //   display: none; /* for Chrome, Safari, and Opera */
      // }
      .ve-table-content {
        position: static;
      }
    }
    .ve-table-header-th {
      background-color: #fff !important;
      padding: 0 !important;
    }
    .table-head {
      @apply tw-relative;
    }
    .ve-table-header-tr {
      height: 85px !important;
    }
    * {
      border-color: #c4c4c4 !important;
    }
    .ve-table-footer-td {
      background-color: #fff !important;
    }
    .ve-table-header-th.active-sizes {
      z-index: auto !important;
      position: relative !important;
    }
    .active-sizes {
      position: relative;
      &.table-head {
        font-weight: bold;
      }
      &:not(.last-column):before {
        @apply tw-absolute tw-top-0 tw-h-full;
        content: '';
        width: 16px;
        right: -16px;
        box-shadow: inset 7px 0 9px -6px rgba(0, 0, 0, 0.41);
        z-index: 5;
      }
      &:after {
        @apply tw-absolute tw-top-0 tw-h-full;
        content: '';
        width: 16px;
        left: -16px;
        box-shadow: inset -7px 0 9px -6px rgba(0, 0, 0, 0.41);
        z-index: 5;
      }
    }
    .ve-table-container-left-scrolling {
      .active-sizes {
        &:after {
          z-index: 0;
        }
      }
    }
    .ve-table-footer {
      .ve-table-footer-tr:first-child .ve-table-footer-td {
        position: relative;
        > span,
        > div {
          &:before {
            @apply tw-absolute tw-top-[2px] tw-left-0 tw-w-full tw-h-px;
            content: '';
            background-color: #c4c4c4;
            z-index: 3;
          }
        }
      }
      .ve-table-footer-tr {
        &:hover {
          .ve-table-footer-td {
            background-color: #f5f7fa !important;
          }
        }
        .ve-table-footer-td {
          &:hover {
            position: relative;
            span:after {
              @apply tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full;
              content: '';
              border: 3px solid #96dee5;
              z-index: 3;
            }
          }
        }
      }
    }
    .ve-table-fixed-left {
      text-align: left !important;
      padding-left: 30px !important;
      padding-right: 10px !important;
      &:hover {
        .more-icon {
          @apply tw-opacity-100;
        }
      }
    }

    $spanPaddingLR: calc((100% - 70px) / 2);
    .active-manual-measurement {
      padding-left: 0 !important;
      padding-right: 0 !important;
      > span {
        display: block;
        text-align: left;
        height: 20px;
        padding-left: $spanPaddingLR !important;
        padding-right: $spanPaddingLR !important;

        &:after {
          @apply tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full;
          content: '';
          border: 3px solid #2dbdcb !important;
          z-index: 3;
        }
      }
    }
    .table-td:not(.ve-table-fixed-left) {
      padding-left: 0 !important;
      padding-right: 0 !important;

      > span {
        display: block;
        text-align: left;
        height: 20px;
        padding-left: $spanPaddingLR !important;
        padding-right: $spanPaddingLR !important;
      }
    }
  }
}

.active-box {
  position: absolute;
  top: 0;
  height: 100%;
  box-shadow: 0 0px 8px 0px rgba(0, 0, 0, 0.62), -8px 0 8px 8px rgba(255, 255, 255, 0),
    8px 0 8px 8px rgba(255, 255, 255, 0);
}

.upload-img-mask {
  @apply tw-absolute tw-top-0 tw-right-0 tw-h-[91px];
  @apply tw-flex tw-flex-col tw-justify-center tw-items-center;
  color: #2dbdcb;
  z-index: 3;
  cursor: pointer;
  transition: background-color 0.2s;
  &.no-image-selected {
    border: 1px solid #2dbdcb !important;
    background-color: #fff;
  }
  &:hover {
    border: 1px solid #2dbdcb !important;
    background-color: rgba(0, 0, 0, 0.8);
    > img,
    > div {
      @apply tw-opacity-100;
    }
  }
}
.current-measurement:after {
  content: '';
  @apply tw-absolute tw-top-[3px] tw-left-0 tw-w-full;
  border: 1px solid #2dbdcb;
  height: calc(100% - 3px);
}
.more-icon {
  @apply tw-absolute tw-opacity-0 tw-cursor-pointer;
  @apply tw-transition-opacity;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.icon-manual-status {
  @apply tw-absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 4px;
  width: 10px;
  height: 15px;
  background: url('../../../assets/home/measurement/manual-status-n.svg') 0 0 no-repeat;
  &.active {
    background-image: url('../../../assets/home/measurement/ic-manual-point.svg');
  }
}
</style>
