import { render, staticRenderFns } from "./ToggleList.vue?vue&type=template&id=7bb20645&scoped=true&"
import script from "./ToggleList.vue?vue&type=script&lang=js&"
export * from "./ToggleList.vue?vue&type=script&lang=js&"
import style0 from "./ToggleList.vue?vue&type=style&index=0&id=7bb20645&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bb20645",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VList,VListTile,VListTileTitle})
