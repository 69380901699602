import Vue from 'vue';
import VueRouter from 'vue-router';
import GarmentMeasure from '../views/GarmentMeasure';
import Login from '../views/Login';
import MlLabel from '../views/MlLabel';
import CheckBox from '../components/common/CheckBox';

import { CheckHasLicenses } from '../modules/MenuLicenses';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/garment_measure',
    name: 'garment_measure',
    component: GarmentMeasure,
    beforeEnter: (to, from, next) => {
      const path = CheckHasLicenses(to);
      if (path.path === to.path) {
        next();
      } else {
        next(path);
      }
    },
  },
  {
    path: '/ml_label',
    name: 'ml_label',
    component: MlLabel,
    beforeEnter: (to, from, next) => {
      const path = CheckHasLicenses(to);
      if (path.path === to.path) {
        next();
      } else {
        next(path);
      }
    },
  },
  {
    path: '/checkbox',
    name: 'checkbox',
    component: CheckBox,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
