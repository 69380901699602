<template>
  <picture class="picture">
    <source srcset="../../assets/ic-processing.webp" type="image/webp" />
    <img class="loading-image" src="../../assets/ic-processing.gif" alt="loading" />
  </picture>
</template>

<script>
export default {
  name: 'LoadingSpinner',
};
</script>

<style lang="scss" scoped>
.picture {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 132px;
  width: 100%;
}
</style>
