<template>
  <v-dialog :value="showDialog" persistent max-width="480" content-class="tw-bg-white tw-relative">
    <v-container class="root tw-relative tw-w-full tw-h-full tw-p-0 tw-bg-white tw-overflow-hidden" fluid>
      <!-- HEAD -->
      <v-layout justify-center class="dialog-head tw-relative tw-w-full tw-bg-black tw-text-white">
        <slot name="header-title"></slot>
      </v-layout>
      <!-- Body -->
      <div class="content tw-flex tw-justify-center tw-text-center tw-mt-12 tw-mb-7">
        <slot name="content" />
      </div>
      <slot />
      <!-- Edit actions -->
      <action-buttons
        sticky
        no-shadow
        btn-dark-mode
        btn-width="110"
        :disable-confirm="disableConfirm"
        :confirm-title="confirmTitle"
        @cancel="$emit('cancel')"
        @confirm="$emit('confirm')"
      >
        <slot slot="centerBtn" name="centerBtn"></slot>
      </action-buttons>
    </v-container>
  </v-dialog>
</template>

<script>
import ActionButtons from './ActionButtons';

export default {
  name: 'PopupDialog',
  components: {
    ActionButtons,
  },
  props: {
    showDialog: Boolean,
    confirmTitle: String,
    disableConfirm: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.root {
  color: #4a4a4a;
}
.dialog-head {
  height: 36px;
  line-height: 36px;
  font-size: 16px;
}
.content {
  font-size: 12px;
}
</style>
