const longPress = {
  bind: function bind(el, binding) {
    let pressTimer = null;
    const handler = e => {
      binding.value(e);
    };
    const start = e => {
      if (e.type === 'click' && e.button !== 0) {
        return;
      }

      handler();
      pressTimer = setTimeout(() => {
        pressTimer = setInterval(() => {
          handler();
        }, 100);
      }, 300);
    };

    const cancel = () => {
      if (pressTimer !== null) {
        clearInterval(pressTimer);
        pressTimer = null;
      }
    };

    el.addEventListener('mousedown', start);
    el.addEventListener('touchstart', start);
    el.addEventListener('click', cancel);
    el.addEventListener('mouseout', cancel);
    el.addEventListener('mouseup', cancel);
    el.addEventListener('touchend', cancel);
    el.addEventListener('touchcancel', cancel);
  },
};

export default longPress;
