var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app',{attrs:{"page-title":'ML Label'}},[_c('v-container',{staticClass:"home--bg",attrs:{"fluid":""}},[_c('upload',{attrs:{"items":_vm.items}}),_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',[_c('explorer',{attrs:{"items":_vm.items,"total-items":_vm.totalItems,"total-pages":_vm.totalPages,"current-page":_vm.currentPage,"paths":_vm.currentPath,"tags":_vm.tags,"actions":_vm.actions,"page-sizes":_vm.pageSizes,"current-page-size":_vm.pageSize,"sort":_vm.sortingOptions,"is-searching":_vm.isSearching,"filter":_vm.filter,"is-requesting":_vm.isRequesting,"can-select":false,"can-import":true,"can-select-all":false,"can-search":true,"select-all":_vm.selectAll,"fix-view-mode":'list',"columns":_vm.columns,"no-data":_vm.noData,"put-action-menu-to-end":""},on:{"editView":_vm.openCUprojectDialog,"select":_vm.selectItem,"delete":_vm.deleteData,"start-searching":_vm.startSearching,"change-search-value":_vm.changeSearchValue,"cancel-search":_vm.cancelSearch,"change-sorting-options":_vm.changeSortingOptions,"change-page-size":_vm.changePageSize,"get-data":_vm.getData,"click-dir":_vm.traverse,"go-back":_vm.goBack,"toggle-all":_vm.handleToggleAll},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var mode = ref.mode;
var clean = ref.clean;
var select = ref.select;
var toggle = ref.toggle;
var canSelect = ref.canSelect;
return _vm._l((items),function(item){return _c('labeling-item',{key:item.id,attrs:{"view-type":mode,"item":item,"clean":clean,"can-select":canSelect},on:{"click":_vm.openCUBBoxDialog,"select":select,"menu":toggle}})})}}])},[_c('div',{staticClass:"import-icon tw-mr-5",attrs:{"slot":"top-button"},on:{"click":function($event){return _vm.openCUprojectDialog(false)}},slot:"top-button"})])],1)],1),_c('project-dialog',{ref:"projectDialog",attrs:{"showDialog":_vm.dialog.checkDialog,"singleProductDialog":_vm.dialog.singleProductDialog},on:{"closeDialog":_vm.closeDialog,"CUproject":_vm.CUproject,"openCUBBoxDialog":_vm.openCUBBoxDialog}}),_c('bbox-dialog',{ref:"boxDialog",attrs:{"showDialog":_vm.dialog.checkDialog,"singleProductDialog":_vm.dialog.singleBBoxDialog},on:{"openCUBBoxDialog":_vm.openCUBBoxDialog,"closeDialog":_vm.closeDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }