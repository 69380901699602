var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('info-dialog',{ref:"infoDialog",attrs:{"show-dialog":_vm.showDialog,"tabs":_vm.tabs,"cancel-title":_vm.$t('garment_measure.previous'),"confirm-title":_vm.$t('garment_measure.next'),"disable-cancel":_vm.disableCancel,"disable-confirm":_vm.disableConfirm},on:{"change-event":function($event){return _vm.$bus.$emit('cancel-measurement-mode', $event)},"close":function($event){return _vm.$emit('update:showDialog', false)},"cancel":_vm.prev,"save":_vm.next},scopedSlots:_vm._u([{key:"viewer-controller",fn:function(ref){
var tab = ref.tab;
return _c('measurement-viewer-controller',{attrs:{"ready":_vm.merchandiseFetched,"svg-html":_vm.category.svgHtml,"tab":tab,"merchandiseId":_vm.merchandiseId}})}},{key:"output-preview",fn:function(ref){
var tab = ref.tab;
return _c('edit-output-preview',{attrs:{"tab":tab,"merchandise-id":_vm.merchandiseId}})}},{key:"fisrt-item",fn:function(ref){
var tab = ref.tab;
return _c('merchandises-table',{attrs:{"tab":tab,"show-dialog":_vm.showDialog,"ready":_vm.merchandiseFetched,"merchandise-info":_vm.merchandiseInfo}})}},{key:"second-item",fn:function(ref){
var tab = ref.tab;
return _c('merchandises-preview',{attrs:{"tab":tab,"merchandise-info":_vm.merchandiseInfo,"merchandise-id":_vm.merchandiseId}})}}])},[_c('div',{staticClass:"tw-text-[24px] tw-font-black",attrs:{"slot":"header-title"},slot:"header-title"},[_vm._v(" "+_vm._s(_vm.currentItem.sn)+" ")]),_c('popup-dialog',{attrs:{"show-dialog":_vm.showManualMeasurement,"disable-confirm":_vm.disablePopupConfirm,"confirm-title":_vm.$t('complete')},on:{"cancel":function($event){return _vm.openManualMeasurement(false)},"confirm":_vm.complete}},[_c('span',{attrs:{"slot":"header-title"},slot:"header-title"},[_vm._v(_vm._s(_vm.renameId ? _vm.$t('garment_measure.rename_manual_measurements') : _vm.$t('garment_measure.add_manual_measurements')))]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('div',{staticClass:"tw-mr-3 tw-text-base"},[_vm._v("*")]),_c('v-text-field',{staticClass:"tw-w-[223px]",attrs:{"color":"black","label":_vm.$t('garment_measure.manual_measurement_name'),"required":""},model:{value:(_vm.manualMeasurement.name),callback:function ($$v) {_vm.$set(_vm.manualMeasurement, "name", $$v)},expression:"manualMeasurement.name"}})],1),_c('div',{staticClass:"tw-flex tw-items-center"},[_c('div',{staticClass:"tw-mr-3 tw-text-base"},[_vm._v("*")]),_c('v-text-field',{staticClass:"tw-w-[223px]",attrs:{"color":"black","label":_vm.$t('garment_measure.manual_measurement_definition'),"required":""},model:{value:(_vm.manualMeasurement.definition),callback:function ($$v) {_vm.$set(_vm.manualMeasurement, "definition", $$v)},expression:"manualMeasurement.definition"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }