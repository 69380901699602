<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-h-full tw-pt-6 2xl:tw-pt-16">
    <div class="tw-w-11/12 2xl:tw-w-9/12">
      <div class="info-wrapper">
        <div>{{ merchandiseInfo.name }}</div>
        <div class="export-icon" :class="{ allowed: canExport }" @click="exportMerchandise"></div>
      </div>
    </div>
    <div class="h-inherit tw-w-full tw-py-3 tw-overflow-y-auto">
      <div class="tw-mx-auto tw-my-0 tw-w-11/12 2xl:tw-w-9/12">
        <img v-if="snapshotUrl && !isCapturing && tab === 1" :src="snapshotUrl" class="snapshot-image" />
        <div v-else class="preview-dom">
          <div
            ref="exportImage"
            class="tw-w-[640px] tw-min-h-[265px] tw-px-[20px] tw-bg-white tw-flex tw-justify-between tw-items-center"
          >
            <div class="tw-w-[270px] tw-h-[240px]">
              <img v-if="merchandiseImage" class="tw-w-full" :src="merchandiseImage" alt="merchandise" />
              <div v-else class="default-image">
                <img src="../../../assets/home/measurement/default-snapshot-image.svg" width="134" height="134" />
                <div class="tw-mt-8 tw-opacity-50">{{ $t('garment_measure.set_product_screenshot') }}</div>
              </div>
            </div>
            <div class="tw-w-[300px] tw-py-[12px] tw-pl-[29px] tw-flex tw-flex-col tw-items-center">
              <div
                v-for="(item, index) in visibleMeasurements"
                :key="item.key"
                class="tw-w-full tw-flex tw-items-center tw-py-1"
              >
                <div class="measurement-index">{{ index + 1 }}</div>
                <div class="tw-w-[67px] tw-mr-2 tw-font-bold tw-break-all">{{ item | measurementName }}</div>
                <div class="tw-w-[174px] tw-break-all">{{ item | measurementDefinition }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="tableWrapper" class="tw-mx-auto tw-mt-8 tw-mb-0 tw-w-11/12 2xl:tw-w-9/12">
        <ve-table
          ref="table"
          class="size-table"
          border-x
          border-y
          style="width:100%"
          :row-style-option="{ hoverHighlight: false }"
          :scroll-width="scrollWidth"
          :columns="columns"
          :tableData="tableData"
          :cell-style-option="cellStyleOption"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import domToImage from 'dom-to-image';
import i18n from '@/i18n/i18n';
import UnitConversion from '../../../modules/unit-conversion';

export default {
  name: 'MerchandisesPreview',
  props: {
    merchandiseInfo: Object,
    tab: Number,
    merchandiseId: String,
  },
  filters: {
    measurementName(m) {
      return m.name || i18n.t(m.definition) || m.key;
    },
    measurementDefinition(m) {
      return (m.name ? m.definition : i18n.t(`${m.definition}_definition`)) || m.definition;
    },
  },
  data: () => ({
    cellStyleOption: {
      headerCellClass: () => {
        return 'tw-uppercase';
      },
    },
    tableTypeWidth: 0,
    tableWidth: 0,
    imageUrl: '',
    isCapturing: false,
    merchandiseImage: '',
    merchandiseShotUrl: '',
    UnitConversion,
  }),
  computed: {
    ...mapGetters('labelingTool/measurementTool', [
      'sizesList',
      'categoriesMeasurements',
      'currentEditSize',
      'customizedMeasurements',
    ]),
    visibleSizesList() {
      const cloneSizesList = cloneDeep(this.sizesList);
      const filterSizes = cloneSizesList.filter(item => {
        const hasManualMeasurement = this.customizedMeasurements.some(data => {
          const filterData = data.sizes.filter(size => size.name === item.name);
          return filterData[0].value > 0;
        });
        return (
          (item.measurements.length > 0 && item.measurements.some(measurement => measurement.value > 0)) ||
          hasManualMeasurement
        );
      });
      filterSizes.forEach(item => {
        const visibleMeasurements = item.measurements.filter(data => data.visible);
        this.$set(item, 'measurements', visibleMeasurements);
      });

      return filterSizes;
    },
    visibleMeasurements() {
      const filterMeasurements = this.categoriesMeasurements.filter(item => item.visible);
      const filterCustomizedMeasurements = this.customizedMeasurements.filter(
        item => item.visible && item.sizes.some(size => size.value > 0)
      );

      return [...filterMeasurements, ...filterCustomizedMeasurements];
    },
    columns() {
      const columns = [
        {
          field: 'type',
          key: 'type',
          title: this.$t('garment_measure.size'),
          align: 'center',
          fixed: 'left',
          width: Number(this.tableTypeWidth),
          renderBodyCell: ({ row, column, rowIndex }) => {
            const data = row[column.field];
            return (
              <div class="type">
                <div class="measurement-name" title={data}>
                  {data}
                </div>
                <div class="table-index">{rowIndex + 1}</div>
              </div>
            );
          },
        },
      ];
      const columnsWidth = this.visibleSizesList.length <= 5 ? `${80 / this.visibleSizesList.length}%` : '20%';

      this.visibleSizesList.forEach(item => {
        const columnItem = {
          field: item.name,
          key: item.name,
          title: item.name,
          align: 'center',
          width: columnsWidth,
          renderBodyCell: ({ row, column }) => {
            const data = row[column.field];
            if (!data > 0) {
              return <span>- -</span>;
            }
            if (!this.UnitConversion.useInch) {
              return (
                <span>
                  <span class="tw-font-medium">{this.UnitConversion.switchUnits(data)}</span>
                  <span class="tw-font-light"> {this.$t('cm')}</span>
                </span>
              );
            }
            return (
              <span>
                <span class="tw-font-medium">{this.UnitConversion.switchUnits(data)}</span>
                <span class="tw-font-light"> {this.$t('inch')}</span>
              </span>
            );
          },
        };
        columns.push(columnItem);
      });
      if (columns.length <= 1) {
        const columnItem = {
          field: '- -',
          key: '- -',
          title: '- -',
          align: 'center',
          width: '80%',
        };
        columns.push(columnItem);
      }

      return columns;
    },
    tableData() {
      const tableData = [];
      this.visibleSizesList.forEach(size => {
        size.measurements.forEach((item, index) => {
          const data = { type: this.$t(`${item.definition}`), [size.name]: item.value };
          tableData[index] = { ...tableData[index], ...data };
        });
      });
      this.customizedMeasurements.forEach(measurement => {
        if (!measurement.visible) return;
        const sizeValue = {};
        measurement.sizes.forEach(item => {
          if (item.value > 0) {
            sizeValue[item.name] = item.value;
          }
        });
        if (Object.keys(sizeValue).length > 0) {
          tableData.push({ type: measurement.name, ...sizeValue });
        }
      });

      if (tableData.length === 0) {
        this.visibleMeasurements.forEach((item, index) => {
          tableData[index] = { type: this.$t(`${item.definition}`), '- -': '- -' };
        });
      }
      return tableData;
    },
    snapshotUrl() {
      return this.imageUrl || this.merchandiseShotUrl;
    },
    scrollWidth() {
      if (this.visibleSizesList.length <= 5) {
        return this.tableWidth;
      }
      const width = ((this.tableWidth - this.tableTypeWidth) / 5) * this.visibleSizesList.length;
      return this.tableTypeWidth + width;
    },
    canExport() {
      return !!this.merchandiseImage || !!this.merchandiseShotUrl;
    },
  },
  watch: {
    async tab(nv) {
      this.imageUrl = '';
      this.merchandiseImage = '';
      this.merchandiseShotUrl = '';
      if (nv === 1) {
        if (!document.querySelector('#preview-table')) {
          this.$refs.table.$el.firstChild.setAttribute('id', 'preview-table');
        }
        await this.setTableTypeWidth();
        const getTargetSize = this.sizesList.find(size => size.isDefault);
        if (!getTargetSize) {
          this.getImageOfDom();
        } else {
          this.merchandiseShotUrl = getTargetSize.snapshotUrl || '';
        }
      }
    },
  },
  mounted() {
    this.$bus.$on('merchandise-info', this.setMerchandise);
    window.addEventListener('resize', () => {
      if (this.tab !== 1) return;
      this.setTableTypeWidth();
    });
  },
  beforeDestroy() {
    this.$bus.$off('merchandise-info', this.setMerchandise);
    window.addEventListener('resize', () => {
      if (this.tab !== 1) return;
      this.setTableTypeWidth();
    });
  },
  methods: {
    ...mapMutations('labelingTool/measurementTool', ['SET_SIZES_LIST']),
    getImageOfDom(toBlob = false) {
      return new Promise(resolve => {
        this.isCapturing = true;
        this.imageUrl = '';
        this.$nextTick(async () => {
          const node = this.$refs.exportImage;
          try {
            const dataUrl = await domToImage.toPng(node, {
              backgroundColor: 'white',
            });
            this.imageUrl = dataUrl;
            if (toBlob) {
              const blob = await domToImage.toBlob(node, {
                backgroundColor: 'white',
              });
              resolve({ blob, dataUrl });
            }
          } catch (e) {
            console.log(e.message);
          } finally {
            this.isCapturing = false;
          }
        });
      });
    },
    setTableTypeWidth() {
      return new Promise(resolve => {
        const table = document.querySelector('#preview-table');
        if (!table) {
          setTimeout(() => {
            this.setTableTypeWidth();
          }, 500);
          return;
        }
        this.$nextTick(() => {
          setTimeout(() => {
            this.tableWidth = table.clientWidth;
            this.tableTypeWidth = this.tableWidth * 0.2;
            resolve();
          }, 1000);
        });
      });
    },
    toInch(value) {
      return Number(value) * 0.393700787;
    },
    toFixed(value) {
      return parseFloat(Number(value).toFixed(2));
    },
    async setMerchandise(data) {
      const { zoom, size, dataUrl } = data;
      this.merchandiseImage = dataUrl;
      const { blob, dataUrl: png } = await this.getImageOfDom(true);
      const payload = { zoom, isDefault: true };
      const merchandisesSizesUrl = `/api/v1/merchandises/${this.merchandiseId}/sizes/${size}`;
      const merchandisesUrl = `/api/v1/merchandises/${this.merchandiseId}`;
      const formData = new FormData();
      console.log(blob, 'snapshot');
      formData.append('snapshot', blob);
      formData.append('size', size);
      try {
        await Promise.all([this.$http.post(merchandisesSizesUrl, payload), this.$http.post(merchandisesUrl, formData)]);
        const cloneSizesList = cloneDeep(this.sizesList);
        cloneSizesList.forEach(item => {
          if (item.name === size) {
            this.$set(item, 'isDefault', true);
            this.$set(item, 'snapshotUrl', png);
          } else {
            this.$set(item, 'isDefault', false);
            this.$set(item, 'snapshotUrl', '');
          }
        });
        this.SET_SIZES_LIST(cloneSizesList);
      } catch (e) {
        console.log(e.message);
        this.$toastMessage.info(e.message, 124, 'left');
      }
    },
    exportMerchandise() {
      if (this.merchandiseInfo && this.merchandiseInfo.id && this.canExport) {
        this.$bus.$emit('export-merchandise', this.merchandiseInfo);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.info-wrapper {
  @apply tw-flex tw-justify-between tw-items-center tw-pb-2;
  border-bottom: 1px solid rgb(196, 196, 196);
}
.export-icon {
  @apply tw-w-[32px] tw-h-[32px] tw-bg-contain tw-cursor-pointer;
  background-image: url('../../../assets/home/measurement/btn_download_n.svg');
  cursor: not-allowed;
  &.allowed {
    cursor: pointer;
    &:hover {
      background-image: url('../../../assets/home/measurement/btn_download_h.svg');
    }
    &:active {
      background-image: url('../../../assets/home/measurement/btn_download_p.svg');
    }
  }
}
.h-inherit {
  height: inherit;
}
.preview-dom {
  @apply tw-rounded-md;
  border: 1px solid rgb(196, 196, 196);
  color: rgba(64, 64, 64, 1);
  background-color: #fff;
}
.default-image {
  @apply tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center;
}
.snapshot-image {
  @apply tw-w-full tw-rounded-md;
  border: 1px solid rgb(196, 196, 196);
}
.size-table {
  ::v-deep {
    * {
      border-color: #c4c4c4 !important;
    }
    .ve-table-container {
      @apply tw-rounded-md;
    }
    .ve-table-fixed-left {
      text-align: left !important;
      padding-left: 30px !important;
      padding-right: 10px !important;
      @screen 2xl {
        padding-left: 50px !important;
      }
    }
    .ve-table-body-td:not(.ve-table-fixed-left) {
      padding-left: 0 !important;
      padding-right: 0 !important;

      $spanPaddingLR: calc((100% - 78px) / 2);
      > span {
        display: block;
        text-align: left;
        padding-left: $spanPaddingLR !important;
        padding-right: $spanPaddingLR !important;
      }
    }
  }
}
.type {
  @apply tw-relative;
}
.measurement-name {
  @apply tw-truncate;
  max-width: 100%;
}
.table-index {
  @apply tw-absolute tw-w-[17px] tw-h-[17px] tw-text-center tw-rounded-full;
  box-shadow: 0px 3px 5px 0px #00000040;
  left: -25%;
  top: 2px;
  line-height: 17px;
}
.measurement-index {
  @apply tw-w-[17px] tw-h-[17px] tw-mr-3 tw-text-center tw-rounded-full;
  box-shadow: 0px 3px 5px 0px #00000040;
  line-height: 17px;
}
</style>
