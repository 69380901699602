var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"t-btn",class:{
    'box-shadow': _vm.shadow,
    't-primary': _vm.primary,
    disabled: _vm.disabled,
    blueOnHover: _vm.blueOnHover,
    round: _vm.round,
    'text-black': _vm.black,
    'text-white': _vm.white,
  },style:({ 'min-width': (_vm.minWidth + "px") }),attrs:{"type":"button"},on:{"click":function($event){!_vm.disabled && _vm.$emit('click', $event)}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }