const SET_CURRENT_ITEM = 'SET_CURRENT_ITEM';
const SET_MERCHANDISES_LIST = 'SET_MERCHANDISES_LIST';
const SET_BALANCE = 'SET_BALANCE';

const SET_CURRENT_EDIT_SIZE = 'SET_CURRENT_EDIT_SIZE';
const SET_SIZES_LIST = 'SET_SIZES_LIST';
const SET_ORIGINAL_MEASUREMENTS_LANDMARKS = 'SET_ORIGINAL_MEASUREMENTS_LANDMARKS';
const SET_CATEGORIES_MEASUREMENTS = 'SET_CATEGORIES_MEASUREMENTS';
const SET_MEASUREMENTS_LANDMARKS = 'SET_MEASUREMENTS_LANDMARKS';
const SET_SHOW_UPLOAD_IMAGE_DIALOG = 'SET_SHOW_UPLOAD_IMAGE_DIALOG';
const SET_AUXILIARY_LINES = 'SET_AUXILIARY_LINES';
const SET_CUSTOMIZED_MEASUREMENTS = 'SET_CUSTOMIZED_MEASUREMENTS';
const SET_ACTIVE_MANUAL_MEASUREMENTS = 'SET_ACTIVE_MANUAL_MEASUREMENTS';

const labelingTool = {
  namespaced: true,
  state: () => ({
    currentItem: {},
    merchandisesList: [],
    balance: {},
  }),
  mutations: {
    [SET_CURRENT_ITEM](state, data) {
      state.currentItem = { ...data };
    },
    [SET_MERCHANDISES_LIST](state, data) {
      state.merchandisesList = [...data];
    },
    [SET_BALANCE](state, data) {
      state.balance = { ...data };
    },
  },
  actions: {},
  getters: {
    currentItem: state => state.currentItem,
    merchandisesList: state => state.merchandisesList,
    balance: state => state.balance,
  },
  modules: {
    measurementTool: {
      namespaced: true,
      state: () => ({
        currentEditSize: '',
        sizesList: [],
        originalMeasurementLandmarks: [],
        categoriesMeasurements: [],
        measurementLandmarks: [],
        showUploadImageDialog: false,
        auxiliaryLines: [],
        customizedMeasurements: [],
        activeManualMeasurement: {},
      }),
      mutations: {
        [SET_CURRENT_EDIT_SIZE](state, data) {
          state.currentEditSize = data;
        },
        [SET_SIZES_LIST](state, data) {
          state.sizesList = [...data];
        },
        [SET_ORIGINAL_MEASUREMENTS_LANDMARKS](state, data) {
          state.originalMeasurementLandmarks = [...data];
        },
        [SET_CATEGORIES_MEASUREMENTS](state, data) {
          state.categoriesMeasurements = [...data];
        },
        [SET_MEASUREMENTS_LANDMARKS](state, data) {
          state.measurementLandmarks = [...data];
        },
        [SET_SHOW_UPLOAD_IMAGE_DIALOG](state, data) {
          state.showUploadImageDialog = data;
        },
        [SET_AUXILIARY_LINES](state, data) {
          state.auxiliaryLines = [...data];
        },
        [SET_CUSTOMIZED_MEASUREMENTS](state, data) {
          state.customizedMeasurements = [...data];
        },
        [SET_ACTIVE_MANUAL_MEASUREMENTS](state, data) {
          state.activeManualMeasurement = { ...data };
        },
      },
      actions: {},
      getters: {
        currentEditSize: state => state.currentEditSize,
        sizesList: state => state.sizesList,
        originalMeasurementLandmarks: state => state.originalMeasurementLandmarks,
        categoriesMeasurements: state => state.categoriesMeasurements,
        measurementLandmarks: state => state.measurementLandmarks,
        showUploadImageDialog: state => state.showUploadImageDialog,
        auxiliaryLines: state => state.auxiliaryLines,
        customizedMeasurements: state => state.customizedMeasurements,
        activeManualMeasurement: state => state.activeManualMeasurement,
        hadActiveManualMeasurement: state => !!Object.keys(state.activeManualMeasurement).length,
      },
    },
  },
};

export default labelingTool;
