<template>
  <v-dialog no-click-animation :value="showDialog" persistent max-width="500px">
    <v-container fluid class="content">
      <!-- Project Name -->
      <v-layout justify-center>
        <v-flex xs8 class="contentBg">
          <h2>{{ $t('project_name') }}</h2>
          <v-text-field v-model="name" required></v-text-field>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- Edit actions -->
    <action-buttons
      sticky
      hide-btns-on-save
      :cancel-title="$t('cancel')"
      :confirm-title="$t('confirm')"
      :disable-confirm="!name"
      @cancel="$emit('cancel')"
      @confirm="$emit('save', name)"
    />
  </v-dialog>
</template>

<script>
import ActionButtons from '@/components/common/ActionButtons';

export default {
  name: 'ProjectCreationDialog',
  props: {
    showDialog: {
      type: Boolean,
    },
  },
  components: {
    ActionButtons,
  },
  data: () => ({
    name: '',
  }),
  watch: {
    showDialog(nv) {
      if (nv) {
        this.name = '';
      }
    },
  },
};
</script>

<style scoped>
.content {
  background: white;
}
</style>
