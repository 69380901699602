<template>
  <div v-html="html" class="image-wrapper" />
</template>

<script>
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'CategorySampleImage',
  props: {
    html: String,
    landmarksTotal: Number,
  },
  data: () => ({
    activePoint: {},
    hoverPointData: {},
  }),
  computed: {
    ...mapGetters('labelingTool/measurementTool', ['measurementLandmarks']),
  },
  watch: {
    measurementLandmarks: {
      deep: true,
      immediate: true,
      handler(nv) {
        if (nv) {
          this.$nextTick(() => {
            const landmarks = cloneDeep(nv);
            landmarks.forEach(item => {
              if (item.labeled) {
                this.setElementToSvg(item, this.getLabeledElement);
              } else {
                this.setElementToSvg(item, this.getUnlabelElement);
              }
            });
            this.activePoint = landmarks.find(item => !item.labeled) || {};
          });
        }
      },
    },
    activePoint: {
      deep: true,
      handler(nv) {
        if (Object.keys(nv).length > 0) {
          const landmarks = cloneDeep(nv);
          this.setElementToSvg(landmarks, this.getEditElement);
        }
      },
    },
  },
  mounted() {
    const elementList = document.querySelectorAll('g');
    this.$emit('update:landmarks-total', elementList.length);
    this.bindIndexToElement(elementList);
    this.$bus.$on('landmark-hover', this.onHoverPoint);
  },
  beforeDestroy() {
    this.$bus.$off('landmark-hover', this.onHoverPoint);
  },
  methods: {
    bindIndexToElement(list) {
      list.forEach((el, index) => {
        el.setAttribute('id', `point-${index}`);
      });
    },
    onHoverPoint(data) {
      const nextLandmarks = cloneDeep(this.activePoint);
      if (Object.keys(data).length > 0) {
        if (Object.keys(nextLandmarks).length > 0) {
          this.setElementToSvg(nextLandmarks, this.getEditElement, false);
        }
        this.setElementToSvg(data, this.getHoverElement);
      } else {
        this.setElementToSvg(this.hoverPointData, this.getLabeledElement);
        if (Object.keys(nextLandmarks).length > 0) {
          this.setElementToSvg(nextLandmarks, this.getEditElement);
        }
      }
      this.hoverPointData = data;
    },
    setElementToSvg(mark, method, isFocus) {
      const point = document.querySelector(`#point-${mark.id}`);
      if (point) {
        const data = this.getElementPoision(point);
        const el = method(data, isFocus);
        point.innerHTML = el;
      }
    },
    getElementPoision(el) {
      const cx = el.children[0].attributes.cx.value;
      const cy = el.children[0].attributes.cy.value;
      let d;
      if (el.children[1].attributes.d) {
        d = el.children[1].attributes.d.value;
      } else {
        d = el.children[2].attributes.d.value;
      }
      return { cx, cy, d };
    },
    getUnlabelElement(data) {
      const { cx, cy, d } = data;
      return `
        <circle cx="${cx}" cy="${cy}" r="1" fill="white" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="${d}"
          stroke="#06F2FC"
          opacity="0.5"
          fill="#252736"
        />
      `;
    },
    getLabeledElement(data) {
      const { cx, cy, d } = data;
      return `
        <circle cx="${cx}" cy="${cy}" r="1" fill="white" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="${d}"
          fill="#06F2FC"
        />
      `;
    },
    getEditElement(data, isFocus = true) {
      const { cx, cy, d } = data;
      const activeCircle = isFocus ? `<circle cx="${cx}" cy="${cy}" r="10" fill="#06F2FC" fill-opacity="0.2"/>` : '';
      return `
        <circle cx="${cx}" cy="${cy}" r="1" fill="white" />
        ${activeCircle}
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="${d}"
          stroke="#06F2FC"
          fill="#252736"
          transform="matrix(1.5, 0, 0, 1.5, ${cx - 1.5 * cx}, ${cy - 1.5 * cy})"
        />
      `;
    },
    getHoverElement(data) {
      const { cx, cy, d } = data;
      return `
        <circle cx="${cx}" cy="${cy}" r="1" fill="white" />
        <circle cx="${cx}" cy="${cy}" r="10" fill="#06F2FC" fill-opacity="0.2"/>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="${d}"
          fill="#06F2FC"
        />
      `;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-wrapper {
  ::v-deep svg {
    @apply tw-w-full tw-h-full;
  }
}
</style>
