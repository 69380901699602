<template>
  <info-dialog
    ref="infoDialog"
    :show-dialog="showDialog"
    :tabs="tabs"
    :cancel-title="$t('garment_measure.previous')"
    :confirm-title="$t('garment_measure.next')"
    :disable-cancel="disableCancel"
    :disable-confirm="disableConfirm"
    @change-event="$bus.$emit('cancel-measurement-mode', $event)"
    @close="$emit('update:showDialog', false)"
    @cancel="prev"
    @save="next"
  >
    <div slot="header-title" class="tw-text-[24px] tw-font-black">
      {{ currentItem.sn }}
    </div>
    <measurement-viewer-controller
      :ready="merchandiseFetched"
      :svg-html="category.svgHtml"
      :tab="tab"
      :merchandiseId="merchandiseId"
      slot="viewer-controller"
      slot-scope="{ tab }"
    />
    <edit-output-preview :tab="tab" :merchandise-id="merchandiseId" slot="output-preview" slot-scope="{ tab }" />
    <merchandises-table
      slot="fisrt-item"
      slot-scope="{ tab }"
      :tab="tab"
      :show-dialog="showDialog"
      :ready="merchandiseFetched"
      :merchandise-info="merchandiseInfo"
    />
    <merchandises-preview
      slot="second-item"
      slot-scope="{ tab }"
      :tab="tab"
      :merchandise-info="merchandiseInfo"
      :merchandise-id="merchandiseId"
    />
    <popup-dialog
      :show-dialog="showManualMeasurement"
      :disable-confirm="disablePopupConfirm"
      :confirm-title="$t('complete')"
      @cancel="openManualMeasurement(false)"
      @confirm="complete"
    >
      <span slot="header-title">{{
        renameId ? $t('garment_measure.rename_manual_measurements') : $t('garment_measure.add_manual_measurements')
      }}</span>
      <div slot="content">
        <div class="tw-flex tw-items-center">
          <div class="tw-mr-3 tw-text-base">*</div>
          <v-text-field
            v-model="manualMeasurement.name"
            class="tw-w-[223px]"
            color="black"
            :label="$t('garment_measure.manual_measurement_name')"
            required
          />
        </div>
        <div class="tw-flex tw-items-center">
          <div class="tw-mr-3 tw-text-base">*</div>
          <v-text-field
            v-model="manualMeasurement.definition"
            class="tw-w-[223px]"
            color="black"
            :label="$t('garment_measure.manual_measurement_definition')"
            required
          />
        </div>
      </div>
    </popup-dialog>
  </info-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { wait } from '@/modules/utils';
import InfoDialog from '../../common/InfoDialog';
import MeasurementViewerController from './MeasurementViewerController';
import MerchandisesTable from './MerchandisesTable';
import MerchandisesPreview from './MerchandisesPreview';
import EditOutputPreview from './EditOutputPreview';
import PopupDialog from '../../common/PopupDialog';
import categories from './lib/categories';

import labelIcon from '../../../assets/home/btn_label_n.png';
import previewIcon from '../../../assets/home/btn_preview_n.png';

export default {
  name: 'MeasurementDialog',
  props: {
    showDialog: Boolean,
  },
  components: {
    InfoDialog,
    MeasurementViewerController,
    MerchandisesTable,
    MerchandisesPreview,
    EditOutputPreview,
    PopupDialog,
  },
  data: vm => ({
    tabs: [
      { name: vm.$t('editorial_markers'), imagePath: labelIcon },
      { name: vm.$t('output_preview'), imagePath: previewIcon },
    ],
    merchandiseInfo: {},
    merchandiseFetched: false,
    showManualMeasurement: false,
    manualMeasurement: {
      name: '',
      definition: '',
    },
    renameId: '',
  }),
  computed: {
    ...mapGetters('labelingTool', ['currentItem', 'merchandisesList']),
    ...mapGetters('labelingTool/measurementTool', [
      'showUploadImageDialog',
      'customizedMeasurements',
      'sizesList',
      'currentEditSize',
      'measurementLandmarks',
      'originalMeasurementLandmarks',
      'hadActiveManualMeasurement',
    ]),
    merchandiseId() {
      return (this.currentItem && this.currentItem.id) || '';
    },
    category() {
      return categories[this.merchandiseInfo.category] || {};
    },
    disableCancel() {
      return this.merchandiseIndex <= 0;
    },
    disableConfirm() {
      return this.merchandiseIndex + 1 >= this.merchandisesList.length;
    },
    merchandiseIndex() {
      if (this.merchandisesList.length > 0) {
        return this.merchandisesList.findIndex(item => item.id === this.currentItem.id);
      }
      return 0;
    },
    disablePopupConfirm() {
      return !this.manualMeasurement.name || !this.manualMeasurement.definition;
    },
  },
  mounted() {
    this.$bus.$on('open-manual-measurement', this.openManualMeasurement);
  },
  beforeDestroy() {
    this.$bus.$off('open-manual-measurement', this.openManualMeasurement);
  },
  methods: {
    ...mapMutations('labelingTool/measurementTool', [
      'SET_SIZES_LIST',
      'SET_CATEGORIES_MEASUREMENTS',
      'SET_AUXILIARY_LINES',
      'SET_CUSTOMIZED_MEASUREMENTS',
      'SET_CURRENT_EDIT_SIZE',
      'SET_ACTIVE_MANUAL_MEASUREMENTS',
      'SET_ORIGINAL_SIZES_LIST',
    ]),
    ...mapMutations('labelingTool', ['SET_CURRENT_ITEM']),
    async getData() {
      if (!this.merchandiseId) return;

      this.$loadingSpinner.open();
      this.merchandiseFetched = false;

      try {
        const { data } = await this.$http.get(`/api/v1/merchandises/${this.merchandiseId}`);
        const customizedData = await this.$http.get(
          `/api/v1/merchandises/${this.merchandiseId}/customized_measurements`
        );
        const { customizedMeasurements } = customizedData.data;
        this.merchandiseInfo = { ...data };
        customizedMeasurements.forEach(measurement => {
          if (!measurement.sizes) {
            const sizes = this.merchandiseInfo.sizes.map(size => ({
              name: size.name,
              value: 0,
              landmarks: [],
              hintPosition: {},
            }));
            this.$set(measurement, 'sizes', sizes);
          }
          if (measurement.sizes.length !== this.merchandiseInfo.sizes.length) {
            this.merchandiseInfo.sizes.forEach(size => {
              if (!measurement.sizes.find(item => item.name === size.name)) {
                this.$set(measurement, 'sizes', [
                  ...measurement.sizes,
                  { name: size.name, value: 0, landmarks: [], hintPosition: {} },
                ]);
              }
            });
          }
        });
        this.SET_CUSTOMIZED_MEASUREMENTS(customizedMeasurements);
        this.SET_SIZES_LIST(this.merchandiseInfo.sizes);
        const categoryMeasurements = this.category.measurements.map(item => {
          const visible = this.sizesList.every(size => {
            const filterData = size.measurements.filter(measurement => measurement.key === item.key);
            if (filterData.length > 0) {
              return !!filterData[0].visible;
            }
            return true;
          });
          return { ...item, visible };
        });
        this.SET_CATEGORIES_MEASUREMENTS(categoryMeasurements);
        this.SET_AUXILIARY_LINES(this.category.auxiliaryLines);
        const findUnlabelSize = this.sizesList.find(size => size.image && !size.measurements.length);
        const findLabelSize = this.sizesList.find(size => size.image && size.measurements.length);
        this.merchandiseFetched = true;
        if (findUnlabelSize) {
          this.SET_CURRENT_EDIT_SIZE(findUnlabelSize.name);
          this.$toastMessage.info(this.$t('garment_measure.image_posing_tips'), 124, 'left');
          return;
        }
        if (findLabelSize) {
          this.SET_CURRENT_EDIT_SIZE(findLabelSize.name);
        }
      } catch (e) {
        console.log(e.message);
        this.$toastMessage.info(e.message, 124, 'left');
      } finally {
        await wait(2);
        this.$loadingSpinner.close();
      }
    },
    next() {
      if (this.disableConfirm) return;
      const result = new Promise(res => {
        this.$bus.$emit('cancel-measurement-mode', res);
      });
      result.then(res => {
        let confirm = true;
        const isLabeling =
          this.measurementLandmarks.some(item => item.labeled) &&
          this.originalMeasurementLandmarks.every(item => !item.labeled);
        if (!this.hadActiveManualMeasurement && isLabeling) {
          confirm = res;
        }
        if (confirm) {
          this.SET_CURRENT_ITEM(this.merchandisesList[this.merchandiseIndex + 1]);
          this.$refs.infoDialog.tab = 0;
          this.merchandiseInfo = {};
          this.getData();
        }
      });
    },
    prev() {
      if (this.disableCancel) return;
      const result = new Promise(res => {
        this.$bus.$emit('cancel-measurement-mode', res);
      });
      result.then(res => {
        let confirm = true;
        const isLabeling =
          this.measurementLandmarks.some(item => item.labeled) &&
          this.originalMeasurementLandmarks.every(item => !item.labeled);
        if (!this.hadActiveManualMeasurement && isLabeling) {
          confirm = res;
        }
        if (confirm) {
          this.SET_CURRENT_ITEM(this.merchandisesList[this.merchandiseIndex - 1]);
          this.$refs.infoDialog.tab = 0;
          this.merchandiseInfo = {};
          this.getData();
        }
      });
    },
    openManualMeasurement(isOpen, data) {
      this.showManualMeasurement = isOpen;
      this.manualMeasurement.name = data ? data.name : '';
      this.manualMeasurement.definition = data ? data.definition : '';
      this.renameId = data ? data.id : '';
    },
    complete() {
      if (!this.renameId) {
        this.addManualMeasurement();
      } else {
        this.renameManualMeasurement();
      }
      this.manualMeasurement.name = '';
      this.manualMeasurement.definition = '';
      this.showManualMeasurement = false;
    },
    async renameManualMeasurement() {
      const cloneCustomizedMeasurements = cloneDeep(this.customizedMeasurements);
      const index = this.customizedMeasurements.findIndex(item => item.id === this.renameId);
      const measurement = cloneCustomizedMeasurements[index];
      measurement.name = this.manualMeasurement.name;
      measurement.definition = this.manualMeasurement.definition;
      try {
        await this.$http.put(`/api/v1/customized_measurements/${measurement.id}`, {
          name: measurement.name,
          definition: measurement.definition,
        });
        cloneCustomizedMeasurements[index] = measurement;
        this.SET_CUSTOMIZED_MEASUREMENTS(cloneCustomizedMeasurements);
      } catch (e) {
        console.log(e.message);
        this.$toastMessage.info(e.message, 124, 'left');
      }
    },
    async addManualMeasurement() {
      let payload = {
        name: this.manualMeasurement.name,
        definition: this.manualMeasurement.definition,
      };
      const cloneCustomizedMeasurements = cloneDeep(this.customizedMeasurements);
      try {
        const { data } = await this.$http.post(
          `/api/v1/merchandises/${this.merchandiseId}/customized_measurements`,
          payload
        );
        payload = {
          ...payload,
          id: data.id,
          visible: true,
          sizes: this.sizesList.map(size => ({ name: size.name, value: 0, landmarks: [], hintPosition: {} })),
        };

        this.SET_CUSTOMIZED_MEASUREMENTS([...cloneCustomizedMeasurements, payload]);
      } catch (e) {
        console.log(e.message);
        this.$toastMessage.info(e.message, 124, 'left');
      }
    },
  },
  watch: {
    showDialog(nv) {
      if (nv) {
        this.getData();
      } else {
        this.merchandiseFetched = false;
        this.SET_ACTIVE_MANUAL_MEASUREMENTS({});
        this.SET_CUSTOMIZED_MEASUREMENTS([]);
        this.SET_SIZES_LIST([]);
        this.SET_CURRENT_EDIT_SIZE([]);
        this.SET_CURRENT_EDIT_SIZE('');
        this.SET_CATEGORIES_MEASUREMENTS([]);
        this.SET_AUXILIARY_LINES([]);
      }
    },
    showUploadImageDialog(nv) {
      if (!nv) {
        this.getData();
        this.SET_ACTIVE_MANUAL_MEASUREMENTS({});
      }
    },
    async currentEditSize(nv) {
      if (nv && this.merchandiseFetched) {
        this.$loadingSpinner.open();
        await wait(2);
        this.$loadingSpinner.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
