<template>
  <div class="tw-relative tw-h-full tw-flex tw-justify-center tw-items-center">
    <div v-if="!currentEditSize" class="center tw-text-[24px]">
      {{ $t('viewer_text_1') }}
    </div>
    <measurement-viewer
      ref="measurementViewer"
      :image-url="sizeImage"
      :mode="focusBtn"
      :rotation="rotation"
      :show-unit-coordinates="showUnitCoordinates"
      :ready="ready"
      :is-editing.sync="isEditing"
    />
    <category-sample-image
      v-if="ready && !hadActiveManualMeasurement && Object.keys(currentSize).length > 0"
      class="guide-img"
      :html="svgHtml"
      :landmarks-total.sync="landmarksTotal"
    />
    <div
      v-if="Object.keys(currentSize).length > 0"
      class="tw-absolute tw-top-52 2xl:tw-top-72 tw-left-0 tw-pl-[24px] tw-w-28 tw-flex tw-flex-col"
    >
      <v-tooltip v-for="item in toolBtnList" :key="item.key" right class="tw-mb-4">
        <div
          slot="activator"
          class="tool-icon tw-cursor-pointer"
          :class="[`${item.key}-icon`, { focus: focusBtn === item.key }]"
          v-longpress="item.method"
        />
        <span>{{ item.name }}</span>
      </v-tooltip>
      <v-tooltip right>
        <div
          slot="activator"
          class="manualmeasurement-icon"
          :class="{ focus: hadActiveManualMeasurement }"
          @click="openManualMeasurement"
        />
        <span>{{ $t('garment_measure.manual_measurement') }}</span>
      </v-tooltip>
      <div class="tw-mb-4 tw-cursor-pointer">
        <v-tooltip v-if="!openRotateTool" right>
          <div slot="activator" class="rotate-icon" @click="openRotateTool = true" />
          <span>{{ $t('garment_measure.rotation') }}</span>
        </v-tooltip>
        <div v-else class="rotate-angle">
          <div class="arrow-left" @click="openRotateTool = false" />
          <div class="rotate-ninety-angle-icon" @click="rotateNinetyAngle" />
          <div class="rotate-input">
            <div class="rotation">{{ rotation }}</div>
            <div>
              <div class="top-triangles" @click="toolRotate(1)" />
              <div class="bottom-triangles" @click="toolRotate(-1)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="hadSelectImage"
      class="tw-absolute tw-top-10 tw-left-1/2 tw-transform tw--translate-x-1/2 tw-flex tw-flex-col tw-items-center"
    >
      <div v-show="currentEditSize" class="tw-mb-7 tw-text-black">
        {{
          !hadActiveManualMeasurement
            ? $t('garment_measure.measure_tips_text_1')
            : $t('garment_measure.measure_tips_text_2')
        }}
      </div>
      <v-select class="sizes-select" v-model="activeSize" :items="selectSizesList" outline hide-details single-line />
      <div
        v-if="activeManualMeasurement.name"
        class="tw-bg-[#272727] tw-text-white tw-flex tw-justify-center tw-items-center tw-rounded-lg tw-px-4 tw-py-3"
      >
        <img src="../../../assets/home/measurement/ic-manual-point.svg" width="10" height="15" />
        <div class="tw-ml-3">{{ activeManualMeasurement.name }}</div>
      </div>
    </div>
    <action-buttons
      v-if="currentEditSize"
      absolute
      :no-padding="true"
      :no-shadow="true"
      :no-background-color="true"
      :btn-width="80"
      :disable-cancel="!sizeImage"
      :disable-confirm="disableConfirm"
      :cancel-title="$t('clear')"
      :confirm-title="$t('complete')"
      @cancel="clear({ clearAll: false, skip: false })"
      @confirm="complete"
    />
    <div v-if="Object.keys(currentSize).length > 0" class="tw-flex tw-absolute tw-bottom-4 tw-right-6 tw-z-10">
      <img src="../../../assets/home/measurement/ic_scale.svg" class="tw-mr-4" />
      <v-switch v-model="showUnitCoordinates" hide-details :color="'#2dbdcb'" class="switch" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { getDistance, getPointLineDistance } from '@/modules/utils';
import CategorySampleImage from './CategorySampleImage';
import MeasurementViewer from './MeasurementViewer';
import ActionButtons from '../../common/ActionButtons';

const LandmarksName = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export default {
  name: 'MeasurementViewerController',
  components: {
    CategorySampleImage,
    MeasurementViewer,
    ActionButtons,
  },
  props: {
    tab: Number,
    ready: Boolean,
    svgHtml: String,
    merchandiseId: String,
  },
  data: vm => ({
    toolBtnList: [
      { key: 'select', name: vm.$t('select'), method: vm.toolSelect },
      { key: 'move', name: vm.$t('move'), method: vm.toolMove },
      { key: 'zoomIn', name: vm.$t('zoom_in'), method: vm.toolZoomIn },
      { key: 'zoomOut', name: vm.$t('zoom_out'), method: vm.toolZoomOut },
      { key: 'reset', name: vm.$t('reset'), method: vm.toolReset },
    ],
    focusBtn: 'select',
    landmarksTotal: 0,
    rotation: 0,
    openRotateTool: false,
    showUnitCoordinates: false,
    isEditing: false,
    activeSize: '',
  }),
  computed: {
    ...mapGetters('labelingTool/measurementTool', [
      'currentEditSize',
      'sizesList',
      'categoriesMeasurements',
      'measurementLandmarks',
      'customizedMeasurements',
      'activeManualMeasurement',
      'hadActiveManualMeasurement',
      'originalMeasurementLandmarks',
    ]),
    currentSize() {
      return this.sizesList.filter(size => size.name === this.currentEditSize)[0] || {};
    },
    sizeImage() {
      return this.currentSize && this.currentSize.image ? this.currentSize.image.url : '';
    },
    disableConfirm() {
      if (!this.measurementLandmarks.length) {
        return true;
      }
      if (this.hadActiveManualMeasurement) {
        return !this.isEditing;
      }
      return this.measurementLandmarks.some(item => !item.labeled) || !this.sizeImage || !this.isEditing;
    },
    selectSizesList() {
      return this.sizesList.filter(item => item.image).map(item => item.name);
    },
    hadSelectImage() {
      return this.sizesList.some(item => item.image);
    },
  },
  watch: {
    currentEditSize(nv) {
      if (nv) {
        this.activeSize = nv;
        this.$nextTick(() => {
          this.makeLandmarksItem(nv);
          if (this.currentSize.image) {
            this.rotation = this.currentSize.image.rotation || 0;
          }
          this.isEditing = false;
        });
      }
    },
    activeSize(nv) {
      if (nv === this.currentEditSize) return;

      this.$nextTick(() => {
        this.activeSize = this.currentEditSize;
      });
      const result = new Promise(resolve => {
        this.cancelMeasurementMode(resolve);
      });
      result.then(res => {
        const isLabeling =
          this.measurementLandmarks.some(item => item.labeled) &&
          this.originalMeasurementLandmarks.every(item => !item.labeled);
        if (isLabeling) {
          if (res) {
            this.SET_CURRENT_EDIT_SIZE(nv);
          } else {
            this.$nextTick(() => {
              this.activeSize = this.currentEditSize;
            });
          }
          return;
        }
        this.SET_CURRENT_EDIT_SIZE(nv);
      });
    },
    ready(nv) {
      if (!nv) {
        this.isEditing = false;
        this.showUnitCoordinates = false;
      }
    },
    hadActiveManualMeasurement(nv) {
      if (nv && this.originalMeasurementLandmarks.length > 0) {
        this.SET_MEASUREMENTS_LANDMARKS(this.originalMeasurementLandmarks);
      }
    },
  },
  mounted() {
    document.addEventListener('keyup', el => {
      if (el.key === 'Escape') {
        this.cancelMeasurementMode();
      }
    });
    this.$bus.$on('leave-manual-measurement-mode', this.leaveManualMeasurementMode);
    this.$bus.$on('cancel-measurement-mode', this.cancelMeasurementMode);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', el => {
      if (el.key === 'Escape') {
        this.cancelMeasurementMode();
      }
    });
    this.$bus.$off('leave-manual-measurement-mode', this.leaveManualMeasurementMode);
    this.$bus.$on('cancel-measurement-mode', this.cancelMeasurementMode);
  },
  methods: {
    ...mapMutations('labelingTool/measurementTool', [
      'SET_MEASUREMENTS_LANDMARKS',
      'SET_SIZES_LIST',
      'SET_CURRENT_EDIT_SIZE',
      'SET_CUSTOMIZED_MEASUREMENTS',
      'SET_ACTIVE_MANUAL_MEASUREMENTS',
      'SET_ORIGINAL_MEASUREMENTS_LANDMARKS',
    ]),
    makeLandmarksItem() {
      const landmarks = [];
      let x;
      let y;
      for (let i = 0; i < this.landmarksTotal; i += 1) {
        if (this.currentSize.landmarks.length > 0) {
          const currentLandmark = this.currentSize.landmarks[i];
          if (!currentLandmark) continue; // eslint-disable-line
          ({ x } = currentLandmark.position);
          ({ y } = currentLandmark.position);
        } else {
          x = 0;
          y = 0;
        }
        const isLabeled = x > 0 && y > 0;
        landmarks.push({
          id: i,
          name: this.currentSize.landmarks.length > 0 ? this.currentSize.landmarks[i].name : LandmarksName[i],
          labeled: isLabeled,
          position: { x, y },
        });
      }
      this.SET_MEASUREMENTS_LANDMARKS(landmarks);
      this.SET_ORIGINAL_MEASUREMENTS_LANDMARKS(landmarks);
    },
    toolSelect() {
      this.focusBtn = 'select';
    },
    toolMove() {
      this.focusBtn = 'move';
    },
    toolZoomIn() {
      this.$refs.measurementViewer.cameraZoomIn();
    },
    toolZoomOut() {
      this.$refs.measurementViewer.cameraZoomOut();
    },
    toolReset() {
      this.$refs.measurementViewer.cameraReset();
    },
    async rotateNinetyAngle() {
      const hadLabel = this.checkHadLabel();
      if (hadLabel) {
        const confirm = await this.$confirm.open(
          this.$t('garment_measure.measure_tips_text_3'),
          this.$t('garment_measure.measure_tips_text_4')
        );
        if (!confirm) return;
        this.clear({ clearAll: true, skip: true });
      }
      const quotient = Number((this.rotation / 90).toFixed());
      if (quotient < 3) {
        this.rotation = 90 * (quotient + 1);
      } else {
        this.rotation = 0;
      }
      this.isEditing = true;
    },
    toolRotate(num) {
      const quotient = Number((this.rotation / 90).toFixed());
      if (num > 0) {
        if (this.rotation - 90 * quotient >= 5) return;
        this.rotation += num;
      }
      if (num < 0) {
        if (this.rotation <= -5 || 90 * quotient - parseInt(Math.abs(this.rotation), 10) >= 5) return;
        this.rotation += num;
      }
      this.isEditing = true;
    },
    async complete() {
      const requests = [];
      const cloneSizeList = cloneDeep(this.sizesList);
      const index = cloneSizeList.findIndex(data => data.name === this.currentEditSize);
      const measurements = [];
      if (cloneSizeList[index].measurements.length > 0) {
        cloneSizeList[index].measurements.forEach(data => {
          const measurement = this.calculateDistance(data);
          measurements.push(measurement);
        });
      } else {
        this.categoriesMeasurements.forEach(data => {
          const measurement = this.calculateDistance(data);
          measurements.push(measurement);
        });
      }

      const parse = {
        rotation: this.rotation,
        landmarks: this.measurementLandmarks,
        measurements,
      };

      requests.push(this.$http.post(`/api/v1/merchandises/${this.merchandiseId}/sizes/${this.currentEditSize}`, parse));

      const cloneMeasurements = cloneDeep(this.customizedMeasurements);
      cloneMeasurements.forEach(item => {
        const idx = item.sizes.findIndex(size => size.name === this.currentEditSize);
        const { landmarks } = item.sizes[idx];
        const value = landmarks.reduce((acc, cur, curIndex, arr) => {
          if (curIndex === 0) return acc;
          const { x: x1, y: y1 } = arr[curIndex - 1];
          const { x: x2, y: y2 } = cur;
          const distanceX = Math.abs(x2 - x1);
          const distanceY = Math.abs(y2 - y1);
          const distance = this.mathRound(Math.sqrt(distanceX * distanceX + distanceY * distanceY));
          return Math.round((acc + distance) * 100) / 100;
        }, 0);
        const payload = { landmarks, value };
        const request = this.$http.put(`/api/v1/customized_measurements/${item.id}/${this.currentEditSize}`, payload);
        requests.push(request);
        this.$set(item.sizes[idx], 'value', value);
      });
      let toastMessage = '';
      try {
        this.$loadingSpinner.open();
        await Promise.all(requests);
        this.SET_CUSTOMIZED_MEASUREMENTS(cloneMeasurements);
        this.$set(cloneSizeList[index], 'measurements', measurements);
        this.$set(cloneSizeList[index].image, 'rotation', this.rotation);
        this.$set(cloneSizeList[index], 'landmarks', this.measurementLandmarks);
        this.SET_SIZES_LIST(cloneSizeList);
        toastMessage = this.$t('garment_measure.size_tagging_complete', { size: this.currentEditSize.toUpperCase() });
      } catch (e) {
        console.log(e.message);
        toastMessage = e.message;
      } finally {
        this.SET_ACTIVE_MANUAL_MEASUREMENTS({});
        this.editUnmarkedSize();
        this.$loadingSpinner.close();
        this.$toastMessage.info(toastMessage, 124, 'left');
        this.isEditing = false;
        Promise.resolve();
      }
    },
    async clear({ clearAll = false, skip = false, resetPoint = false }) {
      let confirm = false;
      const requests = [];
      if (!this.hadActiveManualMeasurement) {
        if (!skip) {
          confirm = await this.$confirm.open(
            this.$t('garment_measure.measure_tips_text_5'),
            this.$t('garment_measure.measure_tips_text_6')
          );
        } else {
          confirm = skip;
        }
        if (confirm) {
          const cloneMarks = cloneDeep(this.measurementLandmarks);
          const cloneSizesList = cloneDeep(this.sizesList);
          const index = cloneSizesList.findIndex(item => item.id === this.currentSize.id);
          const marks = cloneMarks.map(item => {
            return {
              ...item,
              labeled: false,
              position: { x: 0, y: 0 },
            };
          });
          this.$set(cloneSizesList[index], 'landmarks', marks);
          cloneSizesList[index].measurements.forEach((item, idx) => {
            this.$set(cloneSizesList[index].measurements[idx], 'value', 0);
          });
          this.SET_SIZES_LIST(cloneSizesList);
          this.makeLandmarksItem();
          requests.push(
            this.$http.post(`/api/v1/merchandises/${this.merchandiseId}/sizes/${this.currentEditSize}`, {
              measurements: cloneSizesList[index].measurements,
              landmarks: cloneSizesList[index].landmarks,
            })
          );
        }
        if (clearAll) {
          const cloneMeasurements = cloneDeep(this.customizedMeasurements);
          cloneMeasurements.forEach(item => {
            const index = item.sizes.findIndex(data => data.name === this.currentSize.name);
            this.$set(item.sizes[index], 'landmarks', []);
            this.$set(item.sizes[index], 'value', 0);
            requests.push(
              this.$http.put(`/api/v1/customized_measurements/${item.id}/${item.sizes[index].name}`, {
                landmarks: [],
                value: 0,
              })
            );
          });
          this.SET_CUSTOMIZED_MEASUREMENTS(cloneMeasurements);
          try {
            await Promise.all(requests);
          } catch (e) {
            console.log(e.message);
            this.$toastMessage.info(e.message, 124, 'left');
          }
        }
      } else {
        if (!skip) {
          confirm = await this.$confirm.open(
            this.$t('garment_measure.measure_tips_text_7'),
            this.$t('garment_measure.measure_tips_text_8')
          );
        } else {
          confirm = skip;
        }
        if (confirm) {
          const cloneMeasurements = cloneDeep(this.customizedMeasurements);
          const index = cloneMeasurements.findIndex(item => item.id === this.activeManualMeasurement.id);
          const sizeIndex = cloneMeasurements[index].sizes.findIndex(item => item.name === this.currentEditSize);
          if (!resetPoint) {
            cloneMeasurements[index].sizes[sizeIndex].landmarks = [];
            cloneMeasurements[index].sizes[sizeIndex].value = 0;
          } else {
            const originData = this.activeManualMeasurement.sizes[sizeIndex];
            this.$set(cloneMeasurements[index].sizes, sizeIndex, originData);
          }
          this.SET_CUSTOMIZED_MEASUREMENTS(cloneMeasurements);
          requests.push(
            this.$http.put(
              `/api/v1/customized_measurements/${cloneMeasurements[index].id}/${cloneMeasurements[index].sizes[sizeIndex].name}`,
              { landmarks: [], value: 0 }
            )
          );
          try {
            await Promise.all(requests);
          } catch (e) {
            console.log(e.message);
            this.$toastMessage.info(e.message, 124, 'left');
          }
        }
      }
    },
    calculateDistance(data) {
      const categoriesMeasurement = this.categoriesMeasurements.find(item => item.key === data.key);
      const definitions = categoriesMeasurement.distanceDefinition;
      let value = 0;

      if (Array.isArray(definitions)) {
        definitions.forEach(definition => {
          value += this.getDistance(definition);
        });
      } else {
        value = this.getDistance(definitions);
      }
      return { ...data, value: this.mathRound(value) };
    },
    getDistance(definition) {
      let value = 0;
      const { from, to, distance } = definition;
      if (Array.isArray(to) && distance === 'z') {
        const { x: pointX, y: pointY } = this.measurementLandmarks[from].position;
        const { x: x1, y: y1 } = this.measurementLandmarks[to[0]].position;
        const { x: x2, y: y2 } = this.measurementLandmarks[to[1]].position;
        return getPointLineDistance(pointX, pointY, x1, y1, x2, y2);
      }

      const { x: x1, y: y1 } = this.measurementLandmarks[from].position;
      const { x: x2, y: y2 } = this.measurementLandmarks[to].position;
      if (distance === 'y') {
        value = Math.abs(y2 - y1);
      }
      if (distance === 'x') {
        value = Math.abs(x2 - x1);
      }
      if (distance === 'z') {
        value = getDistance(x1, y1, x2, y2);
      }
      return value;
    },
    mathRound(value) {
      return Math.round(value + Number.EPSILON) / 10;
    },
    editUnmarkedSize() {
      const unmarkedSize = this.sizesList.find(item => {
        return (item.measurements.length <= 0 || item.measurements.every(data => data.value <= 0)) && item.image;
      });
      if (unmarkedSize && unmarkedSize.name) {
        this.SET_CURRENT_EDIT_SIZE(unmarkedSize.name);
      } else {
        this.makeLandmarksItem();
      }
    },
    async openManualMeasurement() {
      this.$bus.$emit('open-manual-measurement', true);
    },
    async leaveManualMeasurementMode(callback) {
      if (!this.hadActiveManualMeasurement) {
        if (typeof callback === 'function') {
          callback(true);
        }
        return;
      }
      if (this.disableConfirm) {
        this.SET_ACTIVE_MANUAL_MEASUREMENTS({});
        this.isEditing = false;
        if (typeof callback === 'function') {
          callback(true);
        }
        return;
      }
      const canSave = await this.$confirm.open(
        this.$t('garment_measure.measure_tips_text_9'),
        this.$t('garment_measure.measure_tips_text_10')
      );
      if (canSave) {
        await this.complete();
      } else {
        this.clear({ skip: true, resetPoint: true });
        this.SET_ACTIVE_MANUAL_MEASUREMENTS({});
      }
      this.isEditing = false;
      if (typeof callback === 'function') {
        callback(canSave);
      }
    },
    checkHadLabel() {
      const hadLabeling = this.customizedMeasurements.every(item => {
        const sizeMeasurements = item.sizes.filter(data => data.name === this.currentEditSize);
        return sizeMeasurements[0].landmarks.length > 0;
      });
      return (
        (this.measurementLandmarks.length && this.measurementLandmarks.every(item => !!item.labeled)) ||
        (this.customizedMeasurements.length && hadLabeling)
      );
    },
    async cancelMeasurementMode(callback) {
      if (this.hadActiveManualMeasurement) {
        this.leaveManualMeasurementMode(callback);
        return;
      }
      const isLabeling =
        this.measurementLandmarks.some(item => item.labeled) &&
        this.originalMeasurementLandmarks.every(item => !item.labeled);

      if (isLabeling) {
        const confirm = await this.$confirm.open(
          this.$t('garment_measure.measure_tips_text_11'),
          this.$t('garment_measure.measure_tips_text_12')
        );
        if (confirm) {
          this.clear({ skip: true });
          this.isEditing = false;
        }
        if (typeof callback === 'function') {
          callback(confirm);
        }
        return;
      }
      if (!this.disableConfirm) {
        const confirm = await this.$confirm.open(
          this.$t('garment_measure.measure_tips_text_13'),
          this.$t('garment_measure.measure_tips_text_14')
        );
        if (!confirm) {
          this.SET_MEASUREMENTS_LANDMARKS(this.originalMeasurementLandmarks);
        } else {
          await this.complete();
        }
        this.isEditing = false;
        if (typeof callback === 'function') {
          callback(confirm);
          return;
        }
      }
      if (typeof callback === 'function') {
        callback(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.guide-img {
  @apply tw-absolute tw-top-0 tw-left-0 tw-w-[160px] tw-h-[160px];
  @screen 2xl {
    @apply tw-w-[200px] tw-h-[200px];
  }
}
.center {
  @apply tw-absolute tw-top-1/2 tw-left-1/2;
  @apply tw-transform tw--translate-x-1/2 tw--translate-y-1/2;
}

$ICONWIDTH: 42px;
.tool-icon {
  width: $ICONWIDTH;
  height: $ICONWIDTH;
  transition: opacity 0.3s, filter 0.3s;
  background-size: contain;
}
.select-icon {
  background-image: url('../../../assets/home/measurement/select-n.svg');
  &:hover {
    background-image: url('../../../assets/home/measurement/select-h.svg');
  }
  &:active {
    background-image: url('../../../assets/home/measurement/select-p.svg');
  }
  &.focus {
    background-image: url('../../../assets/home/measurement/select-f.svg');
  }
}
.move-icon {
  background-image: url('../../../assets/home/measurement/move-n.svg');
  &:hover {
    background-image: url('../../../assets/home/measurement/move-h.svg');
  }
  &:active {
    background-image: url('../../../assets/home/measurement/move-p.svg');
  }
  &.focus {
    background-image: url('../../../assets/home/measurement/move-f.svg');
  }
}
.zoomIn-icon {
  background-image: url('../../../assets/home/measurement/zoomIn-n.svg');
  &:hover {
    background-image: url('../../../assets/home/measurement/zoomIn-h.svg');
  }
  &:active {
    background-image: url('../../../assets/home/measurement/zoomIn-p.svg');
  }
}
.zoomOut-icon {
  background-image: url('../../../assets/home/measurement/zoomOut-n.svg');
  &:hover {
    background-image: url('../../../assets/home/measurement/zoomOut-h.svg');
  }
  &:active {
    background-image: url('../../../assets/home/measurement/zoomOut-p.svg');
  }
}
.reset-icon {
  background-image: url('../../../assets/home/measurement/reset-n.svg');
  &:hover {
    background-image: url('../../../assets/home/measurement/reset-h.svg');
  }
  &:active {
    background-image: url('../../../assets/home/measurement/reset-p.svg');
  }
}

.arrow-left {
  width: 13px;
  height: 12px;
  padding-left: 7px;
  background: url('../../../assets/home/measurement/arrow-left.svg') right 0 / contain no-repeat;
  &:hover {
    background-image: url('../../../assets/home/measurement/arrow-left-h.svg');
  }
}

.manualmeasurement-icon {
  @apply tw-cursor-pointer tw-mb-4;
  width: $ICONWIDTH;
  height: $ICONWIDTH;
  background-image: url('../../../assets/home/measurement/btn_manualmeasurement_n.svg');
  &:hover {
    background-image: url('../../../assets/home/measurement/btn_manualmeasurement_h.svg');
  }
  &:active {
    background-image: url('../../../assets/home/measurement/btn_manualmeasurement_p.svg');
  }
  &.focus {
    background-image: url('../../../assets/home/measurement/btn_manualmeasurement_h.svg');
  }
}
.rotate-icon {
  width: $ICONWIDTH;
  height: $ICONWIDTH;
  transform: rotate3d(1, 1, 0, 180deg);
  background-size: contain;
  background-image: url('../../../assets/home/measurement/btn_rotate_n.svg');
  &:hover {
    transform: rotate3d(1, 0, 0, 0deg);
    background-image: url('../../../assets/home/measurement/btn_rotate_h.svg');
  }
  &:active {
    transform: rotate3d(1, 0, 0, 0deg);
    background-image: url('../../../assets/home/measurement/btn_rotate_p.svg');
  }
}
.rotate-angle {
  @apply tw-h-[42px] tw-w-[166px] tw-rounded-full;
  @apply tw-flex tw-items-center tw-pl-3;
  background-color: rgb(234, 234, 234);
}
.rotate-ninety-angle-icon {
  @apply tw-h-[18px] tw-w-[18px] tw-ml-7 tw-bg-contain tw-cursor-pointer;
  transform: rotate3d(1, 0, 0, 180deg);
  background-image: url('../../../assets/home/measurement/btn_rotate90_n.svg');
  &:hover {
    background-image: url('../../../assets/home/measurement/btn_rotate90_f.svg');
  }
}
.rotate-input {
  @apply tw-w-[60px] tw-h-[28px] tw-ml-6 tw-rounded-sm tw-flex tw-bg-white tw-text-black;
  border: 1px solid #c4c4c4;
}
.rotation {
  @apply tw-w-[34px] tw-leading-7 tw-text-center;
  border-right: 1px solid #c4c4c4;
}
.rotation:after {
  content: '\00b0';
}
.top-triangles {
  @apply tw-w-[24px] tw-h-[13px];
  background-position: center center;
  background-image: url('../../../assets/home/measurement/triangles-n.svg');
  &:hover {
    background-image: url('../../../assets/home/measurement/triangles-h.svg');
  }
  &:active {
    background-color: #2dbdcb;
    background-image: url('../../../assets/home/measurement/triangle-p.svg');
  }
}
.bottom-triangles {
  @apply tw-w-[24px] tw-h-[13px];
  background-position: center center;
  background-image: url('../../../assets/home/measurement/bottom-triangles-n.svg');
  &:hover {
    background-image: url('../../../assets/home/measurement/bottom-triangles-h.svg');
  }
  &:active {
    background-color: #2dbdcb;
    background-image: url('../../../assets/home/measurement/bottom-triangles-p.svg');
  }
}

.sizes-select {
  @apply tw-w-24;
  margin-bottom: 12px;
  text-transform: uppercase;
  ::v-deep {
    .v-input__slot {
      border-width: 1px !important;
      background-color: #fff !important;
      min-height: 24px;
    }
    .v-input__append-inner {
      margin-top: 4px;
    }
  }
}

.current-size {
  @apply tw-absolute tw-bottom-20 tw-left-1/2 tw-transform tw--translate-x-1/2;
  @apply tw-rounded-full tw-text-center tw-font-bold tw-text-black tw-uppercase;
  @apply tw-flex tw-justify-center tw-items-center;
  border: 2px solid #000;
  min-width: 31px;
  min-height: 31px;
  font-size: 16px;
}
.switch {
  @apply tw-mt-1 tw-pt-0;
}
</style>

<style lang="scss">
.v-menu__content {
  text-transform: uppercase;
}
</style>
