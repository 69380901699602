<template>
  <div class="side-menu">
    <div class="sidenav-upper-info">
      <div>
        <div class="md-subhead white-text sidenav-account-title">{{ $t('account') }}</div>
        <div id="mtm_user_email" class="md-subhead white-text sidenav-email">{{ account }}</div>
      </div>
      <button class="sidenav-logout-btn" @click="logout">
        <v-tooltip left>
          <span slot="activator" class="sidenav-icon sidenav-icon-logout"></span>
          <span>{{ $t('logout') }}</span>
        </v-tooltip>
      </button>
    </div>
    <v-layout column wrap justify-center align-center>
      <template v-for="(item, index) in menu">
        <a v-ripple class="menu-item fill-width sidenav-button md-button" :href="item.url" :key="`link-${index}`">
          <span class="sidenav-icon" :class="item.icon"></span>
          <span class="sidenav-text">{{ item.name }}</span>
        </a>
        <span class="sidenav-underline" :key="`underline-${index}`"></span>
      </template>
    </v-layout>
  </div>
</template>

<script>
import { MenuLicenses } from '../../modules/MenuLicenses';

export default {
  name: 'SideMenu',
  data: () => ({
    account: '',
    menu: [],
  }),

  created() {
    this.account = localStorage.getItem('account');
    this.menu = MenuLicenses();
  },
  methods: {
    logout() {
      localStorage.removeItem('id_token');
      localStorage.removeItem('licenses');
      localStorage.removeItem('account');
      this.$router.push({ path: '/login' });
    },
  },
};
</script>

<style lang="scss" scoped>
.side-menu {
  * {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
}
.sidenav-upper-info {
  height: 70px;
  max-height: 70px;
  position: relative;
  font-size: 20px;
  letter-spacing: 0.005em;
  box-sizing: border-box;
  font-weight: 400;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 8px 16px;
  margin: 0;
  background-color: #44cfdb;
}
.sidenav-account-title,
.sidenav-email {
  margin: 0 0 6px 0;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 24px;
}
.sidenav-email {
  margin: 0;
  font-weight: 500;
}
.sidenav-logout-btn {
  position: absolute;
  bottom: 6px;
  right: 6px;
}
.sidenav-icon {
  display: inline-block;
  vertical-align: middle;
  width: 34px;
  height: 34px;
  background-size: contain;
  background: no-repeat center;
}
.fill-width {
  width: 100%;
}
.sidenav-icon-logout {
  background-image: url('../../assets/ic-menu-exit-n.svg');
  &:hover {
    background-image: url('../../assets/ic-menu-exit-h.svg');
  }
  &:active {
    background-image: url('../../assets/ic-menu-exit-p.svg');
  }
}
.menu-item {
  color: #000;
}
.md-button {
  box-sizing: border-box;
  user-select: none;
  position: relative;
  outline: 0;
  border: 0;
  display: inline-block;
  align-items: center;
  padding: 0 6px;
  margin: 6px 8px;
  background: 0 0;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  font-size: 1em;
  min-width: 142px;
  min-height: 32px;
  border-radius: 0;
  height: 32px;
  line-height: 32px;
  letter-spacing: 0.01em;
}
.sidenav-button {
  text-align: left;
  height: 56px;
  font-size: 1em;
  margin: 0;
  padding: 11px 0 11px 20px;
  &:hover {
    background-color: #fff !important;
    color: #00bcd4 !important;
    .ml-tool {
      background-image: url('../../assets/ic_mltool_h.svg');
    }
    .garment-measure {
      background-image: url('../../assets/ic_garmentmeasure_h.svg');
    }
  }
}
.sidenav-text {
  padding-left: 16px;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 300;
}
.sidenav-underline {
  width: 276px;
  margin: 0 12px;
  border-bottom: 1px solid #d9d9d9;
  max-width: 100%;
  box-sizing: border-box;
}

.ml-tool {
  background-image: url('../../assets/ic_mltool_n.svg');
}
.garment-measure {
  background-image: url('../../assets/ic_garmentmeasure_n.svg');
}
</style>
