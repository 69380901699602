<template>
  <v-layout
    ref="root"
    class="btn-container"
    justify-center
    align-center
    :class="{
      absolute: absolute,
      dark: dark,
      'no-shadow': noShadow,
      sticky: sticky,
      'no-background-color': noBackgroundColor,
    }"
    :style="{ height: `${height}px` }"
  >
    <template v-if="!saving">
      <t-button
        v-if="cancellable && !(hideBtnsOnSave && saving)"
        :min-width="btnWidth"
        :disabled="saving || disableCancel"
        :black="btnDarkMode"
        class="mx-2"
        @click="cancel"
      >
        {{ cancelTitle }}
      </t-button>
      <slot name="centerBtn" />
      <t-button
        v-if="confirmable && !hideOnSave"
        :min-width="btnWidth"
        :disabled="saving || disableConfirm"
        class="mx-2"
        :white="btnDarkMode"
        :primary="!btnDarkMode"
        @click="confirm"
      >
        {{ confirmTitle }}
      </t-button>
    </template>
    <div v-else class="spinner-container">
      <v-progress-circular indeterminate color="cyan light-1" class="spinner" :size="24" />
    </div>
    <slot v-if="!hideOnSave"></slot>
  </v-layout>
</template>

<script>
import i18n from '../../i18n/i18n';

import TButton from './TButton';

export default {
  name: 'ActionButtons',
  props: {
    sticky: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    btnDarkMode: {
      type: Boolean,
      default: false,
    },
    disableCancel: {
      type: Boolean,
      default: false,
    },
    disableConfirm: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    noShadow: {
      type: Boolean,
      default: false,
    },
    btnWidth: {
      type: [Number, String],
      default: 142,
    },
    height: {
      type: Number,
      default: 60,
    },
    cancellable: {
      type: Boolean,
      default: true,
    },
    confirmable: {
      type: Boolean,
      default: true,
    },
    cancelTitle: {
      type: String,
      default: i18n.t('cancel'),
    },
    confirmTitle: {
      type: String,
      default: i18n.t('save'),
    },
    saving: {
      type: Boolean,
      default: false,
    },
    hideBtnsOnSave: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: true,
    },
    noBackgroundColor: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TButton,
  },
  computed: {
    hideOnSave() {
      return this.hideBtnsOnSave && this.saving;
    },
  },
  watch: {
    sticky(nv) {
      if (nv) {
        this.removePadding();
      }
    },
  },
  mounted() {
    if (this.noPadding || this.sticky) return;

    this.addPadding();
  },
  methods: {
    cancel() {
      if (!this.saving && !this.disableCancel) {
        this.$emit('cancel');
      }
    },
    confirm() {
      if (!this.saving && !this.disableConfirm) {
        this.$emit('confirm');
      }
    },
    addPadding() {
      const parent = this.$refs.root.parentNode;
      if (parent && parent.style) {
        parent.style.paddingBottom = `${this.height}px`;
      }
    },
    removePadding() {
      const parent = this.$refs.root.parentNode;
      if (parent && parent.style) {
        parent.style.paddingBottom = '0';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.32);
  background-color: white;
  z-index: 4;
  &.no-shadow {
    box-shadow: none;
  }
  &.absolute {
    position: absolute;
  }
  &.sticky {
    position: sticky;
  }
  &.dark {
    background-color: #333;
  }
  &.no-background-color {
    background: none;
  }
}
</style>
